import React, { useContext, useMemo, useRef } from 'react';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import { FormError } from '../types/ErrorResponse.type';
import { FieldErrors } from './FieldErrors';
import 'react-quill/dist/quill.snow.css';
import uploadService from '../../services/upload.service';
import { MeContext } from '../contexts/me.context';
import { Blog } from '../../types/Blog.type';
import { ImageResize } from 'quill-image-resize-module-ts';

Quill.register('modules/imageResize', ImageResize);

type Props = {
	name: string;
	label: string;
	value?: any;
	onChange: (val: string) => void;
	errors?: FormError;
	className?: string;
	disabled?: boolean;
	props?: any;
	onImageUpload?: () => void;
	patchValue: (val: Partial<Blog>) => void;
};

const WYSIWYG = ({
	name,
	label,
	value,
	onChange,
	errors,
	className,
	disabled,
	props = {},
	patchValue,
}: Props) => {
	const { me } = useContext(MeContext);
	const quillRef = useRef<any>(null);

	const modules = useMemo(() => {
		const handleImages = async (val: any) => {
			const currVal = quillRef.current?.props?.value;
			const input = document.createElement('input');
			input.setAttribute('type', 'file');
			input.setAttribute('accept', 'image/*');
			input.click();

			input.onchange = async () => {
				const file: any = input.files?.[0];
				const formData = new FormData();

				formData.append('image', file);

				const res = await uploadService.upload(
					me?.account.id,
					file,
					'image/jpeg',
					false,
					'blog'
				);
				patchValue({
					article: `${
						currVal || ''
					}<br /><p class="w-full flex justify-center"><img src="${res}" /></p>`,
				});
			};
		};
		return {
			toolbar: {
				container: [
					[{ header: [1, 2, 3, 4, 5, 6, false] }],
					['bold', 'italic', 'underline'],
					[{ list: 'ordered' }, { list: 'bullet' }],
					[{ align: [] }],
					['link', 'image'],
					['clean'],
					[{ color: [] }],
				],
				handlers: {
					image: handleImages,
				},
			},
			imageResize: {
				modules: ['Resize', 'DisplaySize'],
			},
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={className}>
			{label && (
				<div>
					<label
						htmlFor={name}
						className="block text-sm font-medium leading-5 text-gray-900  dark:text-darkGray-200 sm:mt-px sm:pt-2"
					>
						{label}
					</label>
				</div>
			)}
			<div
				className={`${disabled ? 'cursor-not-allowed' : ''}`}
				data-text-editor="name"
			>
				<ReactQuill
					id={name}
					theme="snow"
					value={value}
					onChange={onChange}
					className="mt-1 rounded-md shadow-sm"
					{...props}
					readOnly={disabled}
					bounds={`[data-text-editor="name"]`}
					modules={modules}
					ref={quillRef}
				/>
				<FieldErrors errors={errors} />
			</div>
		</div>
	);
};

export default WYSIWYG;
