import { PhotographIcon } from '@heroicons/react/outline';
// import { fromImage } from '../common/helpers/Imtool.index';
import React, { ChangeEvent, useContext, useRef } from 'react';
// import { v4 } from 'uuid';
// import { UploadStatus } from '../../pages/products/Product.type';
import uploadService from '../../services/upload.service';
import { MeContext } from '../contexts/me.context';

type Props = {
	label: string;
	name: string;
	value?: string;
	onChange: (val: string) => void;
	maxSize?: number;
	className?: string;
};

const BlogImageUploader: React.FC<Props> = ({
	label,
	name,
	value,
	onChange,
	maxSize = 800,
	className = '',
}) => {
	const { me } = useContext(MeContext);
	const input = useRef<HTMLInputElement>(null);

	const onChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files as any;
		if (!files?.length) {
			return;
		}
		try {
			const url = await uploadService.upload(
				me?.account.id,
				files[0],
				'image/jpeg',
				false,
				'blog'
			);
			onChange(url);
		} catch (err) {
			console.log(err);
		}
	};

	// const processImage = async (file: any) => {
	// 	const originalName = file.name;
	// 	const image = await fromImage(file);
	// 	let filename = v4();
	// 	filename = `${name}-${filename}`;
	// 	const filetype = originalName?.split('.')?.pop();
	// 	const resizedImage: any = await image
	// 		.type(file.type)
	// 		.quality(0.8)
	// 		.thumbnail(maxSize)
	// 		.toFile(`${filename}.${filetype}`);
	// 	Object.assign(resizedImage, {
	// 		preview: URL.createObjectURL(resizedImage),
	// 		uploadStatus: UploadStatus.new,
	// 		extension: filetype,
	// 		filename: filename,
	// 		url: `${process.env.REACT_APP_S3_URL}/blog/${filename}.${filetype}`,
	// 	});
	// 	return resizedImage;
	// };

	return (
		<div className={className}>
			<label
				htmlFor={name}
				className="block text-sm font-medium leading-5 text-gray-900  sm:mt-px sm:pt-2"
			>
				{label}
			</label>
			<div className="mt-1 flex items-center">
				<span className="h-12 w-12 rounded-md overflow-hidden bg-gray-200">
					{value ? (
						<img
							className="object-cover w-full h-full rounded-lg shadow-lg"
							src={value}
							alt={label}
						/>
					) : (
						<PhotographIcon className="h-full w-full text-gray-400" />
					)}
				</span>
				<div className="flex gap-2 ml-4">
					<button
						type="button"
						className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
						onClick={() => {
							input.current?.click();
						}}
					>
						Change
					</button>
					{value && (
						<button
							type="button"
							className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
							onClick={() => onChange('')}
						>
							Remove
						</button>
					)}
				</div>
				<input
					ref={input}
					onChange={onChangeFile}
					multiple={false}
					type="file"
					hidden
				/>
			</div>
		</div>
	);
};

export default BlogImageUploader;
