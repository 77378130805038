import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';

type Props = {
	pages: number[];
	currentPage: number;
	totalResults?: number;
	setCurrentPage: (val: number) => void;
};

const Pagination = ({
	pages,
	currentPage,
	totalResults,
	setCurrentPage,
}: Props) => {
	const [pageRangeOne, setPageRangeOne] = useState<any>();

	useEffect(() => {
		if (pages.length > 3) {
			if (currentPage > 2) {
				if (currentPage >= pages.length - 3) {
					setPageRangeOne(pages.slice(pages.length - 4, -1));
				} else {
					setPageRangeOne(pages.slice(currentPage - 1, currentPage + 2));
				}
			} else {
				setPageRangeOne(pages.slice(1, 3));
			}
		}
	}, [pages, currentPage]);

	const pageStepper = (page: number) => {
		if (page === currentPage) {
			return;
		}
		setCurrentPage(page);
	};

	return (
		<div className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
			<div className="flex-1 flex justify-between sm:hidden">
				<div
					onClick={() => pageStepper(currentPage - 1)}
					className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
				>
					Previous
				</div>
				<div
					onClick={() => pageStepper(currentPage + 1)}
					className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
				>
					Next
				</div>
			</div>
			<div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
				<div>
					<p className="text-sm text-gray-700">
						Showing{' '}
						<span className="font-medium">
							{totalResults === 0 ? '0' : currentPage === 1 ? '1' : (currentPage - 1) * 10 + 1}
						</span>{' '}
						to{' '}
						<span className="font-medium">
							{totalResults && totalResults > currentPage * 10
								? currentPage * 10
								: totalResults}
						</span>{' '}
						of <span className="font-medium">{totalResults}</span> results
					</p>
				</div>
				<div>
					<nav
						className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
						aria-label="Pagination"
					>
						<div
							onClick={() => {
								if (currentPage > 1) {
									pageStepper(currentPage - 1);
								}
							}}
							className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500"
						>
							<span className="sr-only">Previous</span>
							<ChevronLeftIcon
								className={[
									'h-5 w-5',
									currentPage > 1
										? 'cursor-pointer hover:bg-gray-50'
										: 'cursor-not-allowed',
								].join(' ')}
								aria-hidden="true"
							/>
						</div>
						{pages.length > 3 ? (
							<>
								<div
									onClick={() => pageStepper(1)}
									aria-current="page"
									className={[
										'relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer',
										currentPage === 1
											? 'z-10 bg-primary-50 border-primary-500 text-primary-600'
											: 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
									].join(' ')}
								>
									1
								</div>
								{pageRangeOne?.[0] > 2 && (
									<div
										aria-current="page"
										className="relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white border-gray-300 text-gray-500"
									>
										...
									</div>
								)}
								{pageRangeOne?.map((page: any, i: number) => (
									<div
										key={page}
										onClick={() => pageStepper(page)}
										aria-current="page"
										className={[
											'relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer',
											page === currentPage
												? 'z-10 bg-primary-50 border-primary-500 text-primary-600'
												: 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
										].join(' ')}
									>
										{page}
									</div>
								))}
								{currentPage < pages.length - 3 && (
									<div
										aria-current="page"
										className="relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white border-gray-300 text-gray-500 "
									>
										...
									</div>
								)}
								<div
									onClick={() => pageStepper(pages.length)}
									aria-current="page"
									className={[
										'relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer',
										currentPage === pages.length
											? 'z-10 bg-primary-50 border-primary-500 text-primary-600'
											: 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
									].join(' ')}
								>
									{pages.length}
								</div>
							</>
						) : (
							pages.map((page, i) => (
								<div
									key={page}
									onClick={() => pageStepper(page)}
									aria-current="page"
									className={[
										'relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer',
										page === currentPage
											? 'z-10 bg-primary-50 border-primary-500 text-primary-600'
											: 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
									].join(' ')}
								>
									{page}
								</div>
							))
						)}

						<div
							onClick={() => {
								if (currentPage < pages.length) {
									pageStepper(currentPage + 1);
								}
							}}
							className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500"
						>
							<span className="sr-only">Next</span>
							<ChevronRightIcon
								className={[
									'h-5 w-5',
									currentPage < pages.length
										? 'cursor-pointer hover:bg-gray-50'
										: 'cursor-not-allowed',
								].join(' ')}
								aria-hidden="true"
							/>
						</div>
					</nav>
				</div>
			</div>
		</div>
	);
};

export default Pagination;
