import { Transition } from '@headlessui/react';
import React, { useRef, useState } from 'react';
import { useOnClickOutside } from './hooks/useOnClickOutside';
import Loading from './Loading';
import { OpenClosedStates } from './OpenClosedStates';

export enum ButtonColors {
	primary = 'primary',
	red = 'red',
	plain = 'plain',
	transparent = 'transparent',
	plainWithBorder = 'plainWithBorder',
	plainIcon = 'plainIcon',
}

type ButtonClassValues = {
	always: string;
	disabled: string;
	enabled: string;
};

type ButtonClasses = {
	[key in ButtonColors]: ButtonClassValues;
};

const buttonClasses: ButtonClasses = {
	primary: {
		always:
			'border border-transparent text-sm leading-5 font-medium text-white',
		disabled: 'text-gray-500 bg-gray-200 cursor-not-allowed',
		enabled:
			'text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:border-primary-700 focus:ring-primary active:bg-primary-700',
	},
	red: {
		always:
			'border border-transparent text-sm leading-5 font-medium text-white',
		disabled: 'text-gray-500 bg-gray-200 cursor-not-allowed',
		enabled:
			'text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:ring-red active:bg-red-700',
	},
	plain: {
		always:
			'inline-flex items-center px-4 py-2 text-sm leading-5 font-medium text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out',
		disabled: 'cursor-not-allowed',
		enabled: '',
	},
	transparent: {
		always:
			'inline-flex items-center px-4 py-2 text-sm leading-5 font-medium text-gray-700 bg-transparent hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out',
		disabled: 'cursor-not-allowed',
		enabled: '',
	},
	plainWithBorder: {
		always:
			'border border-gray-300 inline-flex items-center px-4 py-2 text-sm leading-5 font-medium text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out',
		disabled: 'cursor-not-allowed',
		enabled: '',
	},
	plainIcon: {
		always:
			'inline-flex items-center text-sm leading-5 font-medium text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out',
		disabled: 'cursor-not-allowed',
		enabled: '',
	},
};

type Props = {
	text?: string;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	disabled?: boolean;
	loading?: boolean;
	fullWidth?: boolean;
	color?: ButtonColors;
	icon?: JSX.Element;
	className?: string;
	type?: 'button' | 'submit' | 'reset';
	extraOptions?: { text: string; onClick: () => void }[];
	tight?: boolean;
};

const Button: React.FC<Props> = ({
	text,
	onClick,
	disabled,
	loading,
	fullWidth,
	color = ButtonColors.primary,
	icon,
	className,
	type = 'button',
	extraOptions,
	tight = false,
}) => {
	const buttonClass = buttonClasses[color];
	const [extraOptionsState, setExtraOptionsState] = useState(
		OpenClosedStates.Closed
	);
	const ref = useRef<HTMLDivElement>(null);
	useOnClickOutside(ref, () => setExtraOptionsState(OpenClosedStates.Closed));

	const toggleSaveDropdown = () => {
		setExtraOptionsState((v) =>
			v === OpenClosedStates.Open
				? OpenClosedStates.Closed
				: OpenClosedStates.Open
		);
	};

	return (
		<>
			{extraOptions?.length && !onClick ? (
				<div ref={ref}>
					<span
						className={[
							'-ml-px relative block',
							fullWidth ? 'w-full' : '',
						].join(' ')}
					>
						<button
							type={type}
							onClick={toggleSaveDropdown}
							className={[
								'inline-flex justify-center rounded-md',
								buttonClass.always,
								disabled ? buttonClass.disabled : buttonClass.enabled,
								fullWidth ? 'w-full' : '',
								tight ? 'py-1 pl-2 pr-1' : 'py-2 pl-4 pr-2',
								className,
							].join(' ')}
							disabled={disabled || loading}
						>
							{loading && <Loading className="-ml-1 mr-3" />}
							{text}
							<svg
								className="h-5 w-5 ml-1"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fillRule="evenodd"
									d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
									clipRule="evenodd"
								/>
							</svg>
						</button>

						<Transition
							show={extraOptionsState === OpenClosedStates.Open}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
							as={React.Fragment}
						>
							<div className="origin-top-right absolute bottom-12 right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
								<div
									className="py-1"
									role="menu"
									aria-orientation="vertical"
									aria-labelledby="option-menu"
								>
									{extraOptions.map((opt, i) => (
										<div
											key={i}
											className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
											role="menuitem"
											onClick={() => {
												opt.onClick();
												toggleSaveDropdown();
											}}
										>
											{opt.text}
										</div>
									))}
								</div>
							</div>
						</Transition>
					</span>
				</div>
			) : (
				<span
					className={[
						'relative z-0 inline-flex rounded-md',
						fullWidth ? 'w-full' : '',
					].join(' ')}
				>
					<button
						type={type}
						onClick={onClick}
						className={[
							'inline-flex justify-center',
							extraOptions?.length ? '' : 'rounded-md',
							buttonClass.always,
							disabled ? buttonClass.disabled : buttonClass.enabled,
							fullWidth ? 'w-full' : '',
							tight ? 'py-1 px-2' : 'py-2 px-4',
							className,
						].join(' ')}
						disabled={disabled || loading}
					>
						{loading ? (
							<Loading className="-ml-1 mr-3" size={6} />
						) : icon ? (
							<svg
								className={[
									'h-5 w-5',
									text ? '-ml-1 mr-2' : '',
									color === ButtonColors.primary
										? 'text-white'
										: 'text-gray-500',
								].join(' ')}
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								{icon}
							</svg>
						) : (
							<></>
						)}
						{text}
					</button>
				</span>
			)}
		</>
	);
};

export default Button;
