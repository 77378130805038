import { useContext } from 'react';
import {
	QueryFunctionContext,
	useInfiniteQuery,
	useQueryClient,
} from 'react-query';
import { MeContext } from '../../../common/contexts/me.context';
import { LIST_LIMIT } from '../../../constants';
import {
	updateAdRequests,
	getAdRequests,
	invoiceRequestedAd,
} from '../../../services/ad.service';
import { AdRequestForm } from '../AdRequest';

const getAdRequestsQuery = (accountId?: number) => {
	return async (context: QueryFunctionContext) => {
		return getAdRequests({
			skip: context.pageParam || 0,
			limit: 1000,
			accountId: accountId,
		});
	};
};

const useRequestedAds = () => {
	const queryClient = useQueryClient();
	const { me } = useContext(MeContext);
	const {
		data,
		isFetching,
		refetch,
		remove,
		isFetched,
		hasNextPage,
		isFetchingNextPage,
		fetchNextPage,
	} = useInfiniteQuery<{
		data: any[];
		metaData: { skip: number; totalRecords: number };
	}>(['requestedAds'], getAdRequestsQuery(me?.account?.id), {
		getNextPageParam: (lastGroup) => lastGroup?.metaData?.skip || undefined,
		refetchOnWindowFocus: false,
	});

	const update = async (updatedFields: Partial<AdRequestForm>) => {
		const res = await updateAdRequests(
			updatedFields,
			updatedFields.id,
			me?.account?.id
		);
		const newPages = data?.pages?.map((page) => {
			const newPage = page.data.map((row: any) => {
				if (row.id === res?.request?.id) {
					return res.request;
				}
				return row;
			});
			return { data: newPage, metaData: page.metaData };
		});
		queryClient.setQueryData('requestedAds', {
			...data,
			pages: newPages,
		});
	};

	const invoice = async (requestId?: number) => {
		if (!requestId) {
			return;
		}
		const res = await invoiceRequestedAd(requestId);

		const newPages = data?.pages?.map((page) => {
			const newPage = page.data.map((row) => {
				if (row.id === res.id) {
					return res;
				}
				return row;
			});
			return { data: newPage, metaDate: page.metaData };
		});
		queryClient.setQueryData('requestedAds', {
			...data,
			pages: newPages,
		});
	};

	return {
		isFetching,
		data,
		refetch,
		remove,
		isFetched,
		isFetchingNextPage,
		hasNextPage,
		invoice,
		fetchNextPage,
		update,
	};
};

export default useRequestedAds;
