import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { MeContext } from '../../../common/contexts/me.context';
import Header from '../../../common/Header';
import LoadingPage from '../../../common/LoadingPage';
import useProduct from '../hooks/useProduct';
import ProductGeneral from './ProductGeneral';

const ProductContainer = () => {
	const { me } = useContext(MeContext);
	const { productId } = useParams<{ productId: string }>();

	const {
		isFetching,
		data,
		save,
		del,
		mark,
		clone,
		isDeleting,
		isMarking,
		isCloning,
	} = useProduct(productId);

	return isFetching ? (
		<LoadingPage />
	) : (
		<>
			<Routes>
				<Route
					path="*"
					element={
						<>
							<Header
								title={data?.id ? data.title : 'New product'}
								rightSide={
									data?.sponsoredListing?.active ? (
										<div className="">
											<span className="flex flex-col">
												<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary-400 text-white">
													Sponsored listing ends{' '}
													{dayjs(data?.sponsoredListing?.endDate).format(
														'MM/DD/YYYY'
													)}
												</span>
											</span>
										</div>
									) : (
										<></>
									)
								}
							/>
							<div className="p-6">
								<ProductGeneral
									defaultValues={{
										...data,
										warranty:
											productId === 'new'
												? me?.account?.warranty
												: data?.warranty,
									}}
									save={save}
									del={(sold = false) => del(sold)}
									mark={mark}
									clone={clone}
									isDeleting={isDeleting}
									isMarking={isMarking}
									isCloning={isCloning}
								/>
							</div>
						</>
					}
				/>
			</Routes>
		</>
	);
};

export default ProductContainer;
