import React, { useContext, useState } from 'react';
import Button, { ButtonColors } from '../../common/Button';
import { MeContext } from '../../common/contexts/me.context';
import { OpenClosedStates } from '../../common/OpenClosedStates';
import RequestManufacturerAccessModal from './RequestManufacturerAccessModal';

type Props = {
	disabled?: boolean;
};

const RequestManufacturerAccess: React.FC<Props> = ({ disabled }) => {
	const [modalState, setModalState] = useState(OpenClosedStates.Closed);
	const { me } = useContext(MeContext);

	return (
		<>
			<Button
				text={
					me?.account?.pendingManufacturer
						? 'Pending manufacturer access'
						: 'Request manufacturer access'
				}
				onClick={() => setModalState(OpenClosedStates.Open)}
				color={ButtonColors.plainWithBorder}
				disabled={disabled || me?.account?.pendingManufacturer}
			/>
			<RequestManufacturerAccessModal
				state={modalState}
				setState={setModalState}
			/>
		</>
	);
};

export default RequestManufacturerAccess;
