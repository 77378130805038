import { useContext, useEffect, useState } from 'react';
import Button from '../../common/Button';
import { Me, MeContext } from '../../common/contexts/me.context';
import Header from '../../common/Header';
import ManufacturerColors from '../manufacturers/detail/ManufacturerColors';
import { Colors } from '../manufacturers/detail/ManufacturerGeneral';
import AccountSettingsTabs from './AccountSettingsTabs';

type Props = {
	defaultValues?: any;
	save: (account: Partial<any>) => Promise<Me>;
	id?: number;
	manufacturer?: number;
	isSaving?: boolean;
};

const AccountSettingsColors = ({
	defaultValues,
	save,
	id,
	manufacturer,
	isSaving,
}: Props) => {
	const { me } = useContext(MeContext);
	const [colors, setColors] = useState<Colors[]>();
	const [colorsToDelete, setColorsToDelete] = useState<number[]>([]);

	useEffect(() => {
		if (defaultValues) {
			setColors(defaultValues);
		}
	}, [defaultValues]);

	const onSubmit = async () => {
		await save({ id, colors, colorsToDelete, manufacturer });
	};

	return (
		<div>
			<Header title="Color palette">
				<AccountSettingsTabs
					manufacturer={me.account.type === 'Manufacturer'}
					plan={me?.account?.subscription?.plan}
				/>
			</Header>
			<div className="px-6 pt-6 bg-gray-50 h-full">
				<div className="grid grid-cols-1 sm:grid-cols-6 gap-4 w-full">
					{!colors?.length ? (
						<div className="col-span-6">
							<button
								onClick={() =>
									setColors([{ primaryColor: '', specificColor: '' }])
								}
								className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400"
							>
								<svg
									className="mx-auto h-10 w-10 text-gray-400"
									xmlns="http://www.w3.org/2000/svg"
									stroke="currentColor"
									fill="none"
									viewBox="0 0 48 48"
									aria-hidden="true"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
									/>
								</svg>
								<span className="mt-2 block text-sm font-medium text-gray-900">
									Add a new custom color
								</span>
							</button>
						</div>
					) : (
						<ManufacturerColors
							colors={colors}
							setColors={setColors}
							setColorsToDelete={setColorsToDelete}
						/>
					)}
					<Button
						onClick={onSubmit}
						text="Save"
						className="col-span-1 sm:col-span-2 sm:col-start-4"
						loading={isSaving}
						disabled={isSaving}
					/>
				</div>
			</div>
		</div>
	);
};

export default AccountSettingsColors;
