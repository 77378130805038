import { useContext, useEffect, useState } from 'react';
import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import { MeContext } from '../../../common/contexts/me.context';
import { useDebounce } from '../../../common/hooks/useDebounce';
import blogService from '../../../services/blog.service';
import { Blog } from '../../../types/Blog.type';

const getBlogQuery = (accountId?: number, skip?: number) => {
	return async (context: QueryFunctionContext) => {
		if (typeof accountId !== 'number') {
			return;
		}
		const search = context.queryKey[1] as string;
		return blogService.list({
			skip: skip,
			limit: 10,
			search,
		});
	};
};

const useBlogList = () => {
	const { me } = useContext(MeContext);
	const [skip, setSkip] = useState<number>(0);
	const [queryKey, setQueryKey] = useState<[string, string | undefined]>([
		'blogs',
		undefined,
	]);
	const [search, setSearch] = useState<string>();
	const {
		status,
		data,
		error,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		refetch,
	} = useInfiniteQuery<{
		data: Blog[];
		metaData: { skip: number; totalRecords: number };
	}>(queryKey, getBlogQuery(me.account.id, skip), {
		getNextPageParam: (lastGroup) => lastGroup?.metaData?.skip || undefined,
		refetchOnWindowFocus: false,
	});
	const debouncedSearch = useDebounce(search);

	useEffect(() => {
		refetch();
	}, [refetch, skip]);

	useEffect(() => {
		setQueryKey(['blogs', debouncedSearch]);
	}, [debouncedSearch]);

	const onSearch = (val: string) => {
		setSearch(val);
	};

	return {
		status,
		data,
		error,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		refetch,
		setSkip,
		onSearch,
	};
};

export default useBlogList;
