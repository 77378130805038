import { CleaveOptions } from 'cleave.js/options';
import Cleave from 'cleave.js/react';
import React, { ChangeEvent } from 'react';
import { FormError } from '../types/ErrorResponse.type';
import { ErrorIcon } from './ErrorIcon';
import { FieldErrors } from './FieldErrors';

type Props = {
	name: string;
	options: CleaveOptions;
	label: string;
	errors?: FormError;
	onChange: (val: string) => void;
	value?: string;
	className?: string;
	placeholder?: string;
};

export const InputMasked: React.FC<Props> = ({
	label,
	name,
	onChange,
	value,
	errors,
	options,
	className = '',
	placeholder = '',
}) => {
	const onChangeCleave = (e: ChangeEvent<HTMLInputElement>) => {
		const val = e.target.value;
		onChange(val);
	};

	return (
		<div className={className}>
			<div>
				<label
					htmlFor={name}
					className="block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2"
				>
					{label}
				</label>
			</div>
			<div>
				<div className="relative rounded-md shadow-sm">
					<Cleave
						options={options}
						className={[
							'block w-full border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-150 ease-in-out sm:text-sm sm:leading-5',
							errors
								? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red'
								: '',
						].join(' ')}
						onChange={onChangeCleave}
						value={value}
						placeholder={placeholder}
					/>
					{errors && <ErrorIcon />}
				</div>
				<FieldErrors errors={errors} />
			</div>
		</div>
	);
};
