type Props = {
	steps: any[];
	setSteps: (val: any) => void;
};

const SignUpSteps = ({ steps, setSteps }: Props) => {
	const stepOneComplete = steps[0].status === 'complete';

	return (
		<div className="flex flex-row justify-between w-full">
			<div className="w-full flex flex-col items-start">
				<nav aria-label="Progress" className="w-full">
					<ol className="divide-y divide-gray-900 border-gray-900 block md:flex md:divide-y-0 md:h-10 border-2">
						{steps.map((step, stepIdx) => (
							<li
								key={step.name}
								className="relative md:flex md:flex-1 w-full cursor-pointer"
								onClick={() =>
									setSteps((prev: any) => {
										return prev.map((step: any, i: number) => {
											if (stepIdx === 2) {
												return { ...step };
											} else if (i === stepIdx) {
												return { ...step, status: 'current' };
											} else {
												if (i === stepIdx - 1) {
													return { ...step, status: 'complete' };
												} else {
													return { ...step, status: '' };
												}
											}
										});
									})
								}
							>
								{stepIdx === 0 ? (
									step.status === 'current' ? (
										<div
											className="flex items-center px-6 py-4 text-xs font-bold w-full bg-primary-500"
											aria-current="step"
										>
											<span className="text-xs font-bold text-gray-900">
												{step.name}
											</span>
										</div>
									) : (
										<div className="group flex w-full items-center bg-primary-500">
											<span className="flex items-center px-6 py-4 text-xs font-bold">
												<span className="text-xs font-bold text-gray-900">
													{step.name}
												</span>
											</span>
										</div>
									)
								) : stepIdx < steps.length - 1 ? (
									step.status === 'current' ? (
										<div
											className="flex items-center px-6 py-4 text-xs font-bold bg-primary-500 w-full"
											aria-current="step"
										>
											<span className="text-xs font-bold text-gray-900">
												{step.name}
											</span>
										</div>
									) : step.status === 'complete' ? (
										<div className="group flex w-full items-center bg-primary-500">
											<span className="flex items-center px-6 py-4 text-xs font-bold">
												<span className="text-xs font-bold text-gray-900">
													{step.name}
												</span>
											</span>
										</div>
									) : (
										<div className="group flex items-center bg-white w-full">
											<span className="flex items-center px-6 py-4 text-xs font-bold">
												<span className="text-xs font-bold text-gray-400 group-hover:text-gray-900">
													{step.name}
												</span>
											</span>
										</div>
									)
								) : step.status === 'current' ? (
									<div className="group flex w-full items-center bg-primary-500">
										<span className="flex items-center px-6 py-4 text-xs font-bold">
											<span className="text-xs font-bold text-gray-900">
												{step.name}
											</span>
										</span>
									</div>
								) : (
									<div className="group flex items-center bg-white w-full">
										<span className="flex items-center px-6 py-4 text-xs font-bold">
											<span className="text-xs font-bold text-gray-400 group-hover:text-gray-900">
												{step.name}
											</span>
										</span>
									</div>
								)}
								{stepIdx === 0 && stepOneComplete ? (
									<>
										<div
											className="absolute top-0 right-0 hidden h-full w-5 md:block bg-primary-500"
											aria-hidden="true"
										>
											<svg
												className="h-full w-full text-gray-900"
												viewBox="0 0 22 80"
												fill={step.status === 'current' ? '#E2B429' : 'none'}
												preserveAspectRatio="none"
											>
												<path
													d="M0 -2L20 40L0 82"
													vectorEffect="non-scaling-stroke"
													stroke="currentcolor"
													strokeLinejoin="round"
												/>
											</svg>
										</div>
									</>
								) : null}
								{stepIdx === 1 || (stepIdx === 0 && !stepOneComplete) ? (
									<>
										<div
											className="absolute top-0 right-0 hidden h-full w-5 md:block bg-white"
											aria-hidden="true"
										>
											<svg
												className="h-full w-full text-gray-900"
												viewBox="0 0 22 80"
												fill={step.status === 'current' ? '#E2B429' : 'none'}
												preserveAspectRatio="none"
											>
												<path
													d="M0 -2L20 40L0 82"
													vectorEffect="non-scaling-stroke"
													stroke="currentcolor"
													strokeLinejoin="round"
												/>
											</svg>
										</div>
									</>
								) : null}
							</li>
						))}
					</ol>
				</nav>
			</div>
			<div className="hidden md:block md:w-64" />
		</div>
	);
};

export default SignUpSteps;
