import { useState } from 'react';
import { useContext } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { MeContext } from '../../../common/contexts/me.context';
import { ErrorResponse } from '../../../common/types/ErrorResponse.type';
import * as manufacturerService from '../../../services/manufacturer.service';
import { Manufacturer } from '../Manufacturer.type';

const getManufacturerQuery = (manufacturerId: number | 'new') => {
	return async (context: QueryFunctionContext) => {
		if (manufacturerId === 'new') {
			return {} as Manufacturer;
		}
		return manufacturerService.getManufacturer({ manufacturerId });
	};
};

const useManufacturer = (id?: string) => {
	const { me } = useContext(MeContext);
	const queryClient = useQueryClient();
	const { data, isFetching, refetch, remove, isFetched } = useQuery<
		Manufacturer,
		ErrorResponse
	>(
		['manufacturer', id],
		getManufacturerQuery(id === 'new' ? id : Number(id)),
		{
			refetchOnWindowFocus: false,
			retry: false,
		}
	);
	const [isApproving, setIsApproving] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const navigate = useNavigate();

	const save = async (val: Partial<Manufacturer>) => {
		const res = await manufacturerService.saveManufacturer({
			manufacturer: val,
		});
		queryClient.setQueryData(['manufacturer', id], res);
		return res;
	};

	const accept = async (pendingAccountId: number) => {
		if (!id || !pendingAccountId || typeof me.account.id !== 'number') {
			return;
		}
		await manufacturerService.acceptManufacturer(
			me.account.id,
			id,
			pendingAccountId
		);
		queryClient.setQueryData(['manufacturer', id], {
			...data,
			account: pendingAccountId,
			pending: [],
		});
		return data;
	};

	const reject = async (pendingAccountId: number) => {
		if (!id || !pendingAccountId || typeof me.account.id !== 'number') {
			return;
		}
		await manufacturerService.rejectManufacturer(
			me.account.id,
			id,
			pendingAccountId
		);
		queryClient.setQueryData(['manufacturer', id], {
			...data,
			pendingClaims: data?.pendingClaims?.filter(
				(item) => item.id !== pendingAccountId
			),
		});
		return data;
	};

	const approve = async () => {
		if (typeof me.account.id !== 'number') {
			return;
		}
		setIsApproving(true);
		try {
			await manufacturerService.approveManufacturer(me.account.id, id);
			queryClient.setQueryData(['manufacturer', id], {
				...data,
				pendingManufacturer: false,
			});
			await setIsApproving(false);
		} catch (err) {
			setIsApproving(false);
		}
	};

	const del = async () => {
		if (typeof me.account.id !== 'number') {
			return;
		}
		setIsDeleting(true);
		try {
			await manufacturerService.deleteManufacturer(me.account.id, id);
			setIsDeleting(false);
			navigate('/manufacturers');
		} catch (err) {
			setIsDeleting(false);
		}
	};

	return {
		isFetching,
		data,
		save,
		refetch,
		remove,
		isFetched,
		accept,
		reject,
		approve,
		isApproving,
		del,
		isDeleting,
	};
};

export default useManufacturer;
