import React from 'react';
import { Transition } from '@headlessui/react';

type Props = {
	show: boolean;
	opacity?: number;
};

const Overlay: React.FC<Props> = ({ show, opacity = 75 }) => {
	return (
		<>
			<Transition
				show={show}
				enter="ease-in-out duration-500"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="ease-in-out duration-500"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
				as={React.Fragment}
			>
				<div
					className={`absolute inset-0 bg-gray-500 bg-opacity-${opacity} transition-opacity`}
				/>
			</Transition>
		</>
	);
};

export default Overlay;
