import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonColors } from '../../common/Button';
import { MeContext } from '../../common/contexts/me.context';
import { ToastContext, ToastTypes } from '../../common/contexts/toast.context';
import { Input } from '../../common/form/Input';
import { Phone } from '../../common/form/Phone';
import { Select } from '../../common/form/Select';
import Header from '../../common/Header';
import { useForm } from '../../common/hooks/useForm';
import ManufacturerAutocomplete from '../../common/ManufacturerAutocomplete';
import SingleImageUploader from '../../common/SingleImageUploader';
import { STATES } from '../../constants';
import AccountSettingsTabs from './AccountSettingsTabs';
import RequestManufacturerAccess from './RequestManufacturerAccess';
import copy from 'copy-to-clipboard';
import { Textarea } from '../../common/form/Textarea';
import { Checkbox } from '../../common/form/Checkbox';

const validation = {
	name: {
		required: {
			message: 'Company name is required.',
		},
	},
	phone: {
		required: {
			message: 'Phone is required.',
		},
		pattern: {
			value: new RegExp(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/),
			message: 'Provide a valid phone number',
		},
	},
	email: {
		required: {
			message: 'Email is required.',
		},
	},
	address: {
		required: {
			message: 'Address is required.',
		},
	},
	city: {
		required: {
			message: 'City is required.',
		},
	},
	state: {
		required: {
			message: 'State is required.',
		},
	},
	zip: {
		required: {
			message: 'Zip is required.',
		},
	},
	path: {
		required: {
			message: 'URL part is required.',
		},
		minLength: {
			value: 4,
			message: 'URL part must be at least 4 characters.',
		},
		maxLength: {
			value: 512,
			message: 'URL part must be at most 512 characters.',
		},
		pattern: {
			value: /^[a-z0-9-_]+$/,
			message:
				'Path can only contain lowercase characters, numbers, hyphens & underscores.',
		},
	},
	freeDeliveryRange: {
		function: {
			value: (val: Partial<any>) => {
				if (val.freeDeliveryRange) {
					if (val.freeDeliveryRange < 0) {
						return 'Please select a delivery range of at least 0 miles.';
					}
					if (val.freeDeliveryRange > 24902)
						return 'Please select a delivery range of no more than the circumference of earth. Let us know if you plan on offering free delivery to other planets and we will do our best to accomodate you.';
				}
				return false;
			},
		},
	},
};

type Props = {
	defaultValues?: Partial<any>;
	save: (val: Partial<any>) => Promise<any | undefined>;
};

const AccountSettingsGeneral: React.FC<Props> = ({ defaultValues, save }) => {
	const { me, updateMe } = useContext(MeContext);
	const { createToast } = useContext(ToastContext);
	const navigate = useNavigate();
	const { value, isDirty, errors, onChange, registerSubmit, isSubmitting } =
		useForm<any>(defaultValues || {}, validation);
	const superAdminStatus = me.role === 'superAdmin';

	function onSuccess(val: any) {
		createToast({
			title: 'Success!',
			description: <>Successfully saved account settings!</>,
			type: ToastTypes.Success,
			duration: 5000,
		});
		updateMe('account', val?.account);
		if (!value.id) {
			navigate('/users');
		}
	}

	const onFail = (err: any) => {
		const description = (
			<>
				{err?.response?.data ||
					err?.response ||
					'Failed to save account settings!'}
			</>
		);
		createToast({
			title: 'Error!',
			description,
			type: ToastTypes.Fail,
			duration: 5000,
		});
		document
			.getElementById('errorHere')
			?.scrollIntoView({ behavior: 'smooth' });
	};

	const onSubmit = async (user: Partial<any>) => {
		return save(user);
	};

	return (
		<>
			<Header title="Account settings">
				<AccountSettingsTabs
					manufacturer={me?.account?.type === 'Manufacturer'}
					plan={me?.account?.subscription?.plan}
				/>
			</Header>
			<form onSubmit={registerSubmit(onSubmit, { onSuccess, onFail })}>
				<div className="px-6 pt-6 bg-gray-50 h-full">
					<div className="grid grid-cols-1 sm:grid-cols-6 gap-4">
						<div className="bg-gray-200 sm:rounded-lg col-span-6">
							<div className="px-4 py-5 sm:p-6">
								<h3 className="text-lg leading-6 font-medium text-gray-900">
									Share your listings
								</h3>
								<div className="mt-2 max-w-xl text-sm text-gray-500">
									<p>
										As a subscriber to ShedsForSale.com, you have access to your
										own personal listing page (URL part can be edited below).
									</p>
								</div>
								<div className="mt-5" id="errorHere">
									<button
										type="button"
										className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:text-sm"
										onClick={() =>
											copy(
												`${process.env.REACT_APP_CUSTOMER_URL}/dealers/${value.path}`
											)
										}
									>
										Copy URL
									</button>
								</div>
							</div>
						</div>
						<Input
							name="name"
							value={value.name}
							errors={errors.fieldErrors?.name}
							onChange={onChange('name')}
							className={superAdminStatus ? "col-span-3" :"col-span-6" }
							label="Company name *"
						/>
						{superAdminStatus && (
						<div className="mt-2 flex items-center">
							<Checkbox
								name="corporatePlan"
								className="col-span-3"
								label="Corporate Plan"
								value={value.corporatePlan}
								onChange={onChange('corporatePlan')}
								disabled={me?.account?.subscription.options?.corporatePlan}
								hint={
									me?.account?.subscription.options?.corporatePlan
										? "This account currently has an active Corporate plan. Please cancel before deactivating access."
										: "Activating the corporate plan allows admins to choose the SFS Corporate Plan as a subscription option on the billing page"
								}
								
							/>	
						</div>
						)}
						<Input
							name="website"
							value={value.website}
							errors={errors.fieldErrors?.website}
							onChange={onChange('website')}
							className="col-span-6"
							label="Website"
						/>
						<Phone
							name="phone"
							value={value.phone}
							errors={errors.fieldErrors?.phone}
							onChange={onChange('phone')}
							className="col-span-6 sm:col-span-3"
							label="Phone *"
						/>
						<Input
							label="Email *"
							type="email"
							value={value.email}
							onChange={onChange('email')}
							name="email"
							className="col-span-6 sm:col-span-3"
							errors={errors.fieldErrors?.email}
						/>
						<Input
							label="Address *"
							value={value.address}
							onChange={onChange('address')}
							name="address"
							className="col-span-6"
							errors={errors.fieldErrors?.address}
						/>
						<Input
							label="City *"
							value={value.city}
							onChange={onChange('city')}
							name="city"
							className="col-span-6 sm:col-span-3"
							errors={errors.fieldErrors?.city}
						/>
						<Select
							label="State *"
							value={value.state}
							onChange={onChange('state')}
							name="state"
							className="col-span-6 sm:col-span-2"
							errors={errors.fieldErrors?.state}
							options={STATES}
							limit={1}
						/>
						<Input
							label="Zip *"
							value={value.zip}
							onChange={onChange('zip')}
							name="zip"
							className="col-span-6 sm:col-span-1"
							errors={errors.fieldErrors?.zip}
						/>
						<Input
							name="path"
							className="col-span-6"
							label="URL part *"
							value={value.path}
							onChange={onChange('path')}
							errors={errors.fieldErrors?.path}
							hint={
								<>
									Your products will be listed at{' '}
									{value.path && (
										<a
											href={`${process.env.REACT_APP_CUSTOMER_URL}/dealers/${value.path}`}
											target="_blank"
											rel="noreferrer"
											className="text-primary-500 hover:text-primary-600"
										>
											{process.env.REACT_APP_CUSTOMER_URL}/dealers/{value.path}
										</a>
									)}
									.
								</>
							}
							onBlur={(val) =>
								onChange('path')(val?.toLowerCase()?.replace(/ /g, '-'))
							}
						/>
						<ManufacturerAutocomplete
							className="col-span-6 sm:col-span-3"
							value={value.manufacturers}
							onChange={onChange('manufacturers')}
							errors={errors.fieldErrors?.manufacturers}
							label="Manufacturers available"
						/>
						<Input
							name="freeDeliveryRange"
							className="col-span-6 sm:col-span-3"
							label="Free delivery range (in miles)"
							value={value.freeDeliveryRange}
							onChange={onChange('freeDeliveryRange')}
							errors={errors.fieldErrors?.freeDeliveryRange}
							type="number"
							min="0"
							max="24902"
						/>
						<Textarea
							name="deliveryInstructions"
							className="col-span-6"
							label="Delivery instructions"
							value={value.deliveryInstructions}
							onChange={onChange('deliveryInstructions')}
							errors={errors.fieldErrors?.deliveryInstructions}
						/>
						<Textarea
							name="warranty"
							className="col-span-6"
							label="Warranty"
							value={value.warranty}
							onChange={onChange('warranty')}
							errors={errors.fieldErrors?.warranty}
						/>
						<div className="col-span-6 flex gap-4">
							<SingleImageUploader
								className="col-span-6"
								label="Logo"
								name="logo"
								value={value.logo}
								onChange={onChange('logo')}
							/>
							<SingleImageUploader
								className="col-span-6"
								label="Profile Photo"
								name="profilePhoto"
								value={value.profilePhoto}
								onChange={onChange('profilePhoto')}
							/>
						</div>
						{me?.account?.subscription?.plan !== 'essentials' && (
							<SingleImageUploader
								className="col-span-6"
								label="Dealer page header (recommended size 2000px width x 600px height)"
								name="header"
								value={value.header}
								onChange={onChange('header')}
								maxSize={2200}
							/>
						)}
					</div>
					<div className="w-full flex gap-4 justify-end mt-8 pb-8">
						{/* {!me.account.manufacturer && <RequestManufacturerAccess />} */}
						<Button
							text="Save"
							type="submit"
							color={ButtonColors.primary}
							loading={isSubmitting}
							disabled={!isDirty}
						/>
					</div>
					{!me?.account?.manufacturer && (
						<div className="pb-8">
							<div className="bg-gray-200 sm:rounded-lg col-span-6">
								<div className="px-4 py-5 sm:p-6">
									<h3 className="text-lg leading-6 font-medium text-gray-900">
										Are you a manufacturer?
									</h3>
									<div className="mt-2 max-w-xl text-sm text-gray-500">
										<p>
											Click below to request to have your account converted to a
											manufacturer.
										</p>
									</div>
									<div className="mt-5">
										<RequestManufacturerAccess />
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</form>
		</>
	);
};

export default AccountSettingsGeneral;
