import { ChevronRightIcon } from '@heroicons/react/outline';
import React, { useMemo, useState, useEffect } from 'react';
import { v4 } from 'uuid';
import WarningAlert from '../../../common/alerts/WarningAlert';
import Button, { ButtonColors } from '../../../common/Button';
import { OpenClosedStates } from '../../../common/OpenClosedStates';
import AccountLocationModal from './AccountLocationModal';

type Props = {
	locations: any[];
	updateLocations: () => void;
	accountId: string;
	path?: string;
	hasApiAccess: boolean;
	accountLocations?: number;
	externalProvidersInfo?: {
		apiKey: string;
		customerShedSuiteId: number;
		customerShedAppUrl: string;
	};
};

const AccountLocations: React.FC<Props> = ({
	locations,
	accountId,
	path,
	hasApiAccess,
	accountLocations = 1,
	updateLocations,
	externalProvidersInfo,
}) => {
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [locationModalState, setLocationModalState] = useState(
		OpenClosedStates.Closed
	);

	const limitReached = useMemo(() => {
		if (locations.filter((loc) => !!loc.active).length >= accountLocations) {
			return true;
		} else {
			return false;
		}
	}, [accountLocations, locations]);

	const openLocation = (location: any) => {
		setSelectedLocation(location);
	};

	useEffect(() => {
		if (selectedLocation !== undefined || selectedLocation !== null) {
			setLocationModalState(OpenClosedStates.Open);
		}
	}, [selectedLocation]);
	return (
		<>
			<div className="px-6 pt-6 bg-gray-50 h-full">
				{limitReached && (
					<WarningAlert>
						Subscription limit met. This account can only have{' '}
						{accountLocations} active locations with its current subscription.{' '}
					</WarningAlert>
				)}
				<div className="bg-white shadow overflow-hidden sm:rounded-md mt-6">
					<ul className="divide-y divide-gray-200">
						{locations?.map((location) => (
							<li
								key={location?.id}
								onClick={openLocation?.bind(null, location)}
								className={[
									'block hover:bg-gray-50 cursor-pointer',
									location?.active ? 'text-gray-500' : 'text-gray-300',
								].join(' ')}
							>
								<div className="px-4 py-4 flex items-center sm:px-6">
									<div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
										<div className="truncate">
											<div className="flex text-sm">
												<p className="font-medium truncate">
													{location?.address}
												</p>
											</div>
											<div className="mt-2 flex">
												<div className="flex items-center text-sm">
													<p>
														{location?.city}, {location?.state} {location?.zip}
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="ml-5 flex-shrink-0">
										<ChevronRightIcon
											className="h-5 w-5 text-gray-400"
											aria-hidden="true"
										/>
									</div>
								</div>
							</li>
						))}
					</ul>
				</div>
				<div className="w-full flex justify-end mt-6">
					<Button
						text="Add location"
						onClick={() =>
							openLocation({
								path: v4(),
								contactFormMethodEmail: true,
								contactFormMethodText: false,
							})
						}
						color={ButtonColors.primary}
						className=""
						disabled={limitReached}
					/>
				</div>
			</div>
			{selectedLocation !== null && (
				<AccountLocationModal
					clearLocation={() => {
						setSelectedLocation(null);
					}}
					state={locationModalState}
					setState={setLocationModalState}
					location={selectedLocation}
					accountId={+accountId}
					hasApiAccess={hasApiAccess}
					updateLocations={updateLocations}
					path={path}
					externalProvidersInfo={externalProvidersInfo}
				/>
			)}
		</>
	);
};

export default AccountLocations;
