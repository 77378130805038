import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { v4 } from 'uuid';

type ToastTimeouts = {
	[key: string]: NodeJS.Timeout[];
};

export enum ToastTypes {
	Success,
	Fail,
}

export type IToast = {
	title: string;
	description: JSX.Element;
	type: ToastTypes;
	duration?: number;
	id?: string;
	open?: boolean;
};

export const ToastContext = React.createContext<{
	toasts: IToast[];
	createToast: (data: IToast) => void;
	removeToast: (id: string) => void;
	resetToastDuration: (id: string, duration: number) => void;
	removeToastTimeout: (id: string) => void;
}>({
	toasts: [],
	createToast: () => {},
	removeToast: () => {},
	resetToastDuration: () => {},
	removeToastTimeout: () => {},
});

type Props = {
	children?: ReactNode;
};

export const ToastProvider: React.FC<Props> = ({ children }) => {
	const [toasts, setToasts] = useState<IToast[]>([]);
	const [toastTimeouts, setToastTimeouts] = useState<ToastTimeouts>({});

	useEffect(() => {
		if (toasts.length && toasts.every((toast) => toast.open === false)) {
			setToasts([]);
		}
	}, [toasts]);

	const removeToast = useCallback((id: string) => {
		setToasts((prev) =>
			prev.map((item) =>
				item.id === id
					? {
							...item,
							open: false,
					  }
					: item
			)
		);
	}, []);

	const scheduleToastRemoval = useCallback(
		(id: string, duration: number) => {
			const timeout = setTimeout(() => {
				removeToast(id);
			}, duration);
			setToastTimeouts((prev) => ({
				...prev,
				[id]: [...(prev[id] || []), timeout],
			}));
		},
		[removeToast]
	);

	const createToast = (toast: IToast) => {
		const id = v4();
		setToasts((prev) => [...prev, { ...toast, id, open: true }]);
		if (toast.duration) {
			scheduleToastRemoval(id, toast.duration);
		}
	};

	const resetToastDuration = useCallback(
		(id: string, duration: number) => {
			scheduleToastRemoval(id, duration);
		},
		[scheduleToastRemoval]
	);

	const removeToastTimeout = (id: string) => {
		const timeouts = toastTimeouts[id];
		if (timeouts) {
			timeouts.forEach((timeout) => {
				clearTimeout(timeout);
			});
		}
		setToastTimeouts((prev) => {
			delete prev[id];
			return prev;
		});
	};

	return (
		<ToastContext.Provider
			value={{
				toasts,
				createToast,
				removeToast,
				resetToastDuration,
				removeToastTimeout,
			}}
		>
			{children}
		</ToastContext.Provider>
	);
};
