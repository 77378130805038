import { useContext, useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { MeContext } from '../../../common/contexts/me.context';
import { useDebounce } from '../../../common/hooks/useDebounce';
import * as productsService from '../../../services/product.service';

const PAGE_SIZE = 20;

const getProductsQuery = async (pageParam: number, accountId?: number, search?: string, showInactive?: number, sortBy?: string) => {
	if (typeof accountId !== 'number') {
		return;
	}

	const products = await productsService.list(accountId, {
		skip: (pageParam - 1) * PAGE_SIZE,
		limit: PAGE_SIZE,
		search,
		showInactive,
		sortBy,
	});

	return products;
};

const useProducts = () => {
	const { me } = useContext(MeContext);
	const [queryKey, setQueryKey] = useState<[string, string | undefined]>([
		'products',
		undefined,
	]);
	const [searchTerm, setSearchTerm] = useState<string>();
	const [showInactive, setShowInactive] = useState<number>(0)
	const [sortBy, setSortBy] = useState<string>('newest')

	const {
		data,
		error,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isFetchingNextPage,
		refetch,
		status,
	} = useInfiniteQuery(
		queryKey,
		({ pageParam = 1 }) => getProductsQuery(pageParam, me.account.id, searchTerm, showInactive, sortBy),
		{
			getNextPageParam: (lastPage, allPages) =>
				lastPage?.data.length < PAGE_SIZE ? undefined : allPages.length + 1,
			refetchOnWindowFocus: false,
		}
	)

	const debouncedSearchTerm = useDebounce(searchTerm);

	useEffect(() => {
		setQueryKey(['products', debouncedSearchTerm]);
	}, [debouncedSearchTerm]);

	useEffect(() => {
		refetch();
	}, [showInactive, sortBy])

	const onSearch = (val: string) => {
		setSearchTerm(val);
	};

	const onShowInactive = (val: boolean) => {
		setShowInactive(Number(val));
	};

	const onSortBy = (val: string) => {
		setSortBy(val);
	};

	return {
		status,
		data,
		error,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		refetch,
		onSearch,
		onShowInactive,
		onSortBy,
		showInactive,
		sortBy,
	};
}

export default useProducts;
