import { ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { Product } from '../pages/products/Product.type';
import noImage from '../images/no-image.jpg';
import { formatCurrency } from './helpers/format';

type Props = {
  clickAnalytics?: boolean
  product: Partial<Product>
}

const ProductListing: React.FC<Props> = ({ clickAnalytics, product }) => {
  return (
    <li key={product.id} className="cursor-pointer relative">
    {!!product.sold && (
      <div className="ribbon z-20">
        <span>sold</span>
      </div>
    )}
    {!!product.pinned && (
      <div className="ribbon pinned z-20">
        <span>pinned</span>
      </div>
    )}

    <Link to={`/products/${product.id}`}>
      <div className="space-y-4" data-cy="products">
        <div className="aspect-w-3 aspect-h-2 relative">
          <img
            className={`object-cover shadow-lg rounded-lg ${!product.active && 'opacity-30'}`}
            src={product.images?.[0].url || noImage}
            alt={product.title}
          />
        </div>

        <div className="space-y-1">
          <div className="text-lg leading-5 font-medium space-y-1">
            <h3>
              <span className={`${product.active && 'mr-1'}`}>
                {product.title}
              </span>
              {!product.active && (
                <span className="inline-flex items-center ml-2 px-1 py-0.5 rounded text-xs font-medium bg-red-500 text-white">
                  <XCircleIcon className="mr-1 h-3 w-3 text-white" />
                  Inactive
                </span>
              )}
            </h3>
            <span className="text-primary-600 flex gap-2">
              <p className={`${product.reducedPrice && 'line-through'}`}>
                {formatCurrency(product.price)}
              </p>
              {product.reducedPrice && (
                <p>{formatCurrency(product.reducedPrice)}</p>
              )}
            </span>
          </div>
          {clickAnalytics && (
            <div className="flex space-x-5 text-gray-400 text-sm">
              {product.views} views
            </div>
          )}
        </div>
      </div>
    </Link>
  </li>
  )
}

export default ProductListing