import * as ShedsAPI from '../common/helpers/ShedsAPI';
import { AdRequestForm } from '../pages/display-ads/AdRequest';
import { DisplayAdsForm } from '../pages/display-ads/DisplayAds';

export const getAdRequests = async (params: {
	skip: number;
	limit: number;
	accountId?: number;
}) => {
	return ShedsAPI.get('api', '/getAdRequests', {
		queryStringParameters: params,
	});
};

export const updateAdRequests = async (
	updatedFields: Partial<AdRequestForm>,
	adRequestId?: number,
	accountId?: number
) => {
	return ShedsAPI.put('api', `/${accountId}/updateAdRequests/${adRequestId}`, {
		body: updatedFields,
	});
};

export const requestAd = async (val: Partial<AdRequestForm>) => {
	return ShedsAPI.post('api', `/adRequest`, {
		body: val,
	});
};

export const invoiceRequestedAd = async (requestId: number) => {
	return ShedsAPI.post('api', `/invoiceRequestedAd/${requestId}`, {});
};

export const getRequestedAds = async (loc: string, accountId?: number) => {
	return ShedsAPI.get('api', `/${accountId}/getRequestedAds`, {
		queryStringParameters: { location: loc },
	});
};

export const saveDisplayAds = (
	ads: Partial<DisplayAdsForm>
): Promise<DisplayAdsForm> => {
	return ShedsAPI.put('api', `/display-ads`, {
		body: ads,
	});
};

export const getDisplayAds = (): Promise<DisplayAdsForm> => {
	return ShedsAPI.get('api', `/display-ads`, {});
};

export const trackClick = async (id: number) => {
	return ShedsAPI.post('api', `/trackAdClicks/${id}`, {});
};
