import { useContext, useState } from 'react';
import { MeContext } from '../../common/contexts/me.context';
import * as userService from '../../services/user.service';

const useAccountSettings = () => {
	const { me, updateMe } = useContext(MeContext);
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const save = async (account: Partial<any>) => {
		setIsSaving(true);

		const res = await userService.saveAccountSettings({
			account,
		});
		updateMe('account', res.account);
		setIsSaving(false);
		return res;
	};

	const saveLocation = (location: any) => {
		const isNew =
			me?.account?.locations?.findIndex((loc: any) => loc.id === location.id) <
			0;

		const locations = isNew
			? (me?.account?.locations || []).concat(location)
			: me.account.locations.map((loc: any) =>
					loc.id === location.id ? location : loc
			  );

		updateMe('account', { ...me.account, locations });
	};

	const removeLocation = (id: number) => {
		const newLocations = me?.account?.locations?.filter(
			(loc: any) => loc.id !== id
		);
		updateMe('account', { ...me.account, locations: newLocations });
	};

	return {
		data: me,
		save,
		saveLocation,
		removeLocation,
		isSaving,
	};
};

export default useAccountSettings;
