import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import React from 'react';
import Button, { ButtonColors } from '../../../common/Button';
import Modal, { ModalSizes } from '../../../common/Modal';
import { OpenClosedStates } from '../../../common/OpenClosedStates';

const alertRef: any = {
	apiAccess:
		'You are removing API access from your account. Any api keys you have generated will be revoked.',
	additionalLocations:
		'You are changing the number of additional locations allowed on your account. Any locations currently active above the new allowed amount on your plan will be set to inactive.',
	smsNotifications:
		'You are removing text message notifications from your account. All of your current locations will revert to receiving emails from contact form submissions.',
};

type Props = {
	specialAlerts?: string[];
	modalState: OpenClosedStates;
	submit: () => void;
	setModalState: (val: OpenClosedStates) => void;
	isSubmitting?: boolean;
	updatingFromLegacy?: boolean;
};

const UpdateSubscriptionModal = ({
	specialAlerts,
	modalState,
	submit,
	setModalState,
	isSubmitting,
	updatingFromLegacy,
}: Props) => {
	return (
		<Modal state={modalState} size={ModalSizes.lg} noScroll>
			<div className="w-full h-full flex flex-col gap-4 justify-between items-between p-4 text-sm">
				<XIcon
					className="w-5 h-5 self-start -ml-4 -mt-4 cursor-pointer"
					onClick={() => setModalState(OpenClosedStates.Closed)}
					data-cy="closeBtn"
				/>

				<div className="w-full flex flex-col gap-2 justify-center items-center bg-primary-500 p-4 rounded-md bg-opacity-50 text-sm">
					<ExclamationIcon className="w-7 h-7" />
					{updatingFromLegacy ? (
						<p>
							You are updating your subscription from a legacy (pro or elite)
							plan. We will update your subscription to remove this plan and add
							the options you chose below. Any unused time on your current
							legacy plan will be prorated.
						</p>
					) : (
						<p>
							You are updating your subscription options. The price for any
							current subscription options you are removing will be prorated and
							deducted from your next invoice. Any new subscription options you
							are adding will be charged on a prorated basis immediately.
						</p>
					)}

					{!!specialAlerts?.length && (
						<ul>
							{specialAlerts.map((alert) => (
								<li>{alertRef[alert]}</li>
							))}
						</ul>
					)}
				</div>

				<Button
					fullWidth
					color={ButtonColors.primary}
					text="Update membership"
					onClick={submit}
					loading={isSubmitting}
					disabled={isSubmitting}
				/>
			</div>
		</Modal>
	);
};

export default UpdateSubscriptionModal;
