import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as ShedsSLogo } from '../../images/sheds-s.svg';
import { MeContext } from '../contexts/me.context';
import User from '../user/User';
import { menuItems } from './menuItems';

const SideNav: React.FC = () => {
	const { me } = useContext(MeContext);
	const { pathname } = useLocation();

	return (
		<div className="hidden lg:flex lg:flex-shrink-0">
			<div className="flex flex-col w-20">
				<div className="flex flex-col h-0 flex-1 overflow-y-auto no-scrollbar bg-gray-800">
					<div className="flex-1 flex flex-col">
						<div className="flex-shrink-0 bg-primary-500 py-4 flex items-center justify-center">
							<ShedsSLogo
								className="h-8 w-auto text-white"
								fill="currentColor"
								stroke="currentColor"
							/>
						</div>
						<nav
							aria-label="Sidebar"
							className="py-6 flex flex-col items-center space-y-3"
						>
							{menuItems.map((item) => (
								<Link
									to={item.path}
									key={item.path}
									className={[
										(
											item.path === '/'
												? pathname === item.path
												: pathname.startsWith(item.path)
										)
											? 'bg-gray-900 text-white'
											: 'text-gray-400 hover:bg-gray-700',
										'flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg',
										(!item.restrict ||
											item.restrict?.includes(me.account?.type)) &&
										(!item.restrictRoles ||
											item.restrictRoles?.includes(me.role))
											? 'block'
											: 'hidden',
									].join(' ')}
								>
									<span className="sr-only" data-cy={`sidenav-${item.title}`}>
										{item.title}
									</span>
									<item.icon className="h-6 w-6" aria-hidden="true" />
								</Link>
							))}
						</nav>
					</div>
					<div className="flex-shrink-0 w-full flex pb-5">
						<User />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SideNav;
