import { Route, Routes } from 'react-router-dom';
import ProductContainer from './detail/ProductContainer';
import ProductList from './ProductList';

const Products = () => {
	return (
		<div>
			<Routes>
				<Route path="/" element={<ProductList />} />
				<Route path=":productId/*" element={<ProductContainer />} />
				<Route path="*" element={<span>No match for products</span>} />
			</Routes>
		</div>
	);
};

export default Products;
