import React, { ChangeEvent, forwardRef } from 'react';
import Loading from '../Loading';
import { FormError } from '../types/ErrorResponse.type';
import { ErrorIcon } from './ErrorIcon';
import { FieldErrors } from './FieldErrors';

type Props = {
	name: string;
	className?: string;
	icon?: JSX.Element;
	label?: string;
	value?: string | number;
	onChange?: (val: any) => void;
	onBlur?: (val: string) => void;
	errors?: FormError;
	disabled?: boolean;
	onFocus?: () => void;
	type?: string;
	hint?: string | JSX.Element;
	hintOnClick?: () => void;
	loading?: boolean;
	autoComplete?: string;
	inputClassName?: string;
	placeholder?: string;
	min?: string;
	max?: string;
	step?: string;
	clickIcon?: boolean;
	iconRight?: boolean;
};

export const Input = forwardRef(
	(
		{
			name,
			label,
			value,
			onChange,
			onBlur,
			errors,
			className = '',
			icon,
			disabled = false,
			onFocus,
			type = 'text',
			hint,
			hintOnClick,
			loading,
			autoComplete,
			inputClassName,
			placeholder,
			min,
			max,
			step,
			clickIcon,
			iconRight,
		}: Props,
		ref: React.ForwardedRef<HTMLInputElement | null>
	) => {
		const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
			let val: string | number = e.target.value;
			if (val && type === 'number') {
				val = +val;
			}
			onChange?.(val);
		};

		const onBlurValue = (e: ChangeEvent<HTMLInputElement>) => {
			const val = e.target.value;
			onBlur?.(val);
		};

		return (
			<div className={[className, disabled ? 'opacity-50' : ''].join(' ')}>
				{label && (
					<div>
						<label
							htmlFor={name}
							className="block text-sm font-medium leading-5 text-gray-900  sm:mt-px sm:pt-2"
						>
							{label}
						</label>
					</div>
				)}
				<div>
					<div className="relative">
						<div className="relative flex items-stretch flex-grow focus-within:z-10">
							{icon && (
								<div
									className={[
										`absolute inset-y-0 ${
											iconRight ? 'right-4' : 'left-0'
										} pl-3 flex items-center z-20`,
										clickIcon ? '' : 'pointer-events-none',
									].join(' ')}
								>
									{icon}
								</div>
							)}
							<input
								id={name}
								name={name}
								className={[
									inputClassName,
									'block w-full border-gray-300 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5 dark:bg-darkGray-400 dark:border-darkGray-500 dark:text-gray-100',
									errors
										? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red'
										: 'focus:ring-blue focus:border-blue-300',

									disabled ? ' cursor-not-allowed' : '',
									icon ? 'pl-10' : '',
								].join(' ')}
								value={value || ''}
								onChange={onChangeValue}
								onBlur={onBlurValue}
								onFocus={onFocus}
								type={type}
								disabled={disabled}
								ref={ref}
								autoComplete={autoComplete}
								placeholder={placeholder}
								min={min}
								max={max}
								step={step}
							/>
							{errors && <ErrorIcon />}
							{loading && (
								<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
									<Loading color="text-gray-400" size={5} />
								</div>
							)}
						</div>
					</div>
					<FieldErrors errors={errors} />
					{!!hint && (
						<p
							className={[
								'mt-2 text-sm',
								hintOnClick
									? 'text-primary-600 font-semibold hover:text-primary-500 cursor-pointer'
									: 'text-gray-500',
							].join(' ')}
							id={`${name}-hint`}
							onClick={hintOnClick}
						>
							{hint}
						</p>
					)}
				</div>
			</div>
		);
	}
);
