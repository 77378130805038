import React, { useContext } from 'react';
import { ToastContext } from '../contexts/toast.context';
import { Portal } from '../Portal';
import Toast from './Toast';

const Toasts: React.FC = () => {
	const { toasts, removeToast, resetToastDuration, removeToastTimeout } =
		useContext(ToastContext);

	return (
		<Portal>
			<div className="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:justify-end flex-col gap-2 z-50">
				{toasts.map((toast) => (
					<Toast
						key={toast.id}
						toast={toast}
						removeToast={removeToast}
						resetToastDuration={resetToastDuration}
						removeToastTimeout={removeToastTimeout}
					/>
				))}
			</div>
		</Portal>
	);
};

export default Toasts;
