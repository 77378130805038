import { useCallback, useState } from 'react';
import * as userService from '../../services/user.service';
import { Me } from '../contexts/me.context';
import { ValueOf } from '../types/ValueOf.type';

const useMe = () => {
	const [me, setMe] = useState<Me | undefined>();

	const getMe = useCallback(async () => {
		const res = await userService.me();
		setMe(res);
	}, []);

	const updateMe = (key: keyof Me, value: ValueOf<Me>) => {
		setMe((prev?: Me) =>
			prev
				? {
						...prev,
						[key]: value,
				  }
				: undefined
		);
	};

	const replaceMe = (updatedMe: Me) => {
		setMe(updatedMe);
	};

	return {
		me,
		updateMe,
		getMe,
		replaceMe,
	};
};

export default useMe;
