import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';

type Props = {
	className?: string;
	password?: string;
	errors?: any;
};

export const PasswordValidationMeter = ({
	className = '',
	password,
	errors,
}: Props) => {
	const [passwordValidationValue, setPasswordValidationValue] = useState({
		upperCase: false,
		lowerCase: false,
		specialChar: false,
		num: false,
		minLength: false,
	});

	useEffect(() => {
		if (!password?.length) {
			return setPasswordValidationValue({
				minLength: false,
				upperCase: false,
				lowerCase: false,
				num: false,
				specialChar: false,
			});
		}
		setPasswordValidationValue({
			minLength: password?.length >= 8,
			upperCase: new RegExp(/[A-Z]/).test(password),
			lowerCase: new RegExp(/[a-z]/).test(password),
			num: new RegExp(/[0-9]/).test(password),
			specialChar: new RegExp(/[!@#$%^&*<>/]/).test(password),
		});
	}, [password]);

	return (
		<div className={[className, 'flex flex-col pt-2'].join(' ')}>
			<span
				className={`flex items-center gap-1 text-sm ${
					passwordValidationValue.lowerCase
						? 'text-primary-500'
						: errors
						? 'text-red-500'
						: 'text-gray-500'
				}`}
			>
				{passwordValidationValue.lowerCase ? (
					<CheckIcon className="h-5 w-5" />
				) : (
					<XIcon className="h-5 w-5" />
				)}
				Lower case letter
			</span>
			<span
				className={`flex items-center gap-1 text-sm ${
					passwordValidationValue.upperCase
						? 'text-primary-500'
						: errors
						? 'text-red-500'
						: 'text-gray-500'
				}`}
			>
				{passwordValidationValue.upperCase ? (
					<CheckIcon className="h-5 w-5" />
				) : (
					<XIcon className="h-5 w-5" />
				)}
				Upper case letter
			</span>
			<span
				className={`flex items-center gap-1 text-sm ${
					passwordValidationValue.minLength
						? 'text-primary-500'
						: errors
						? 'text-red-500'
						: 'text-gray-500'
				}`}
			>
				{passwordValidationValue.minLength ? (
					<CheckIcon className="h-5 w-5" />
				) : (
					<XIcon className="h-5 w-5" />
				)}
				Minimum eight characters
			</span>
			<span
				className={`flex items-center gap-1 text-sm ${
					passwordValidationValue.specialChar
						? 'text-primary-500'
						: errors
						? 'text-red-500'
						: 'text-gray-500'
				}`}
			>
				{passwordValidationValue.specialChar ? (
					<CheckIcon className="h-5 w-5" />
				) : (
					<XIcon className="h-5 w-5" />
				)}
				{'Special character (!@#$%^&*<>/)'}
			</span>
			<span
				className={`flex items-center gap-1 text-sm ${
					passwordValidationValue.num
						? 'text-primary-500'
						: errors
						? 'text-red-500'
						: 'text-gray-500'
				}`}
			>
				{passwordValidationValue.num ? (
					<CheckIcon className="h-5 w-5" />
				) : (
					<XIcon className="h-5 w-5" />
				)}
				Number
			</span>
		</div>
	);
};
