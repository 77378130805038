import React, { useContext, useEffect, useState } from 'react';
import { MeContext } from './contexts/me.context';
import * as adService from '../services/ad.service';
import { OpenClosedStates } from './OpenClosedStates';
import AdvertiserContact from './AdvertiserContact';

const RequestedAdsDisplay = () => {
	const [requestedAds, setRequestedAds] = useState<any[]>([]);
	const { me } = useContext(MeContext);
	const [advertiserContactModalState, setAdvertiserContactModalState] =
		useState<OpenClosedStates>(OpenClosedStates.Closed);
	const [currentAdIndex, setCurrentAdIndex] = useState(0);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 1680);
	const [currRequest, setCurrRequest] = useState<any>();
	useEffect(() => {
		if (me?.account?.id) {
			let isDestroyed = false;

			const fetchAds = async () => {
				try {
					if (me?.account?.id) {
						const res = await adService.getRequestedAds(
							'primary',
							me?.account?.id
						);
						if (!isDestroyed && res?.data?.length) {
							setRequestedAds(res?.data);
							setCurrentAdIndex(0);
						}
					}
				} catch (err) {
					console.log(err);
				}
			};

			fetchAds();

			const fetchInterval = setInterval(fetchAds, 60000);

			return () => {
				isDestroyed = true;
				clearInterval(fetchInterval);
			};
		}
	}, [me?.account?.id]);

	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth < 1680);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	useEffect(() => {
		if (isMobile && requestedAds.length > 1) {
			const interval = setInterval(() => {
				setCurrentAdIndex((prevIndex) =>
					prevIndex < requestedAds.length - 1 ? prevIndex + 1 : 0
				);
			}, 10000);

			return () => clearInterval(interval);
		}
	}, [isMobile, requestedAds]);
	const trackClicks = async (id: number) => {
		return adService.trackClick(id);
	};

	return (
		<div style={{ paddingTop: '10px' }}>
			{!isMobile && (
				<div
					style={{
						display: 'inline-block',
						borderTop: '2px solid #ffd700',
						marginLeft: '5%',
						marginBottom: '30px',
					}}
				>
					<h1
						style={{
							paddingTop: '10px',
							fontSize: '24px',
							textAlign: 'left',
							fontWeight: '600',
						}}
					>
						SFS Advertising Partners
					</h1>
				</div>
			)}
			<div
				className="ad-container"
				style={{
					flexDirection: 'row',
					flexWrap: 'nowrap',
					display: 'flex',
					alignContent: 'center',
					justifyContent: 'space-evenly',
				}}
			>
				{me?.account?.id !== 0 &&
					(isMobile
						? requestedAds[currentAdIndex] && (
								<div key={requestedAds[currentAdIndex].id}>
									{requestedAds[currentAdIndex].adType === 'link' ? (
										<a
											href={
												!requestedAds[currentAdIndex]?.adLink?.startsWith(
													'http'
												)
													? `https://${requestedAds[currentAdIndex].adLink}`
													: requestedAds[currentAdIndex]?.adLink
											}
											target="_blank"
											rel="noreferrer"
											className="cursor-pointer h-full w-full flex justify-center items-center"
											onClick={() => {
												trackClicks(requestedAds[currentAdIndex].id);
											}}
										>
											<img
												src={requestedAds[currentAdIndex].image}
												alt="ad"
												className="ad object-fit ad-image"
											/>
										</a>
									) : (
										<div
											onClick={() => {
												setCurrRequest(requestedAds[currentAdIndex]);
												setAdvertiserContactModalState(OpenClosedStates.Open);
											}}
											className="cursor-pointer h-full w-full flex justify-center items-center"
										>
											<img
												src={requestedAds[currentAdIndex].image}
												alt="ad"
												className=" ad object-fit ad-image"
											/>
										</div>
									)}
									<div className="h-full relative flex flex-col w-full sm:w-80 xl:w-96  pl-6 pt-6 pb-6 pr-6 sm:pr-1"></div>
								</div>
						  )
						: requestedAds.map((ad, i) => (
								<React.Fragment key={ad.id + i}>
									<div>
										{ad.adType === 'link' ? (
											<a
												href={requestedAds[i].adLink}
												target="_blank"
												rel="noreferrer"
												className="  "
												onClick={() => {
													trackClicks(requestedAds[i].id);
												}}
											>
												<img
													src={ad.image}
													alt="ad"
													className="ad object-fit ad-image"
												/>
											</a>
										) : (
											<div
												onClick={() => {
													setCurrRequest(ad);
													setAdvertiserContactModalState(OpenClosedStates.Open);
												}}
												className="cursor-pointer "
											>
												<img
													src={ad.image}
													alt="ad"
													className="ad object-fit ad-image"
												/>
											</div>
										)}
									</div>
								</React.Fragment>
						  )))}
			</div>
			{!isMobile && (
				<div
					style={{
						marginBottom: '10px',
						marginLeft: '5%',
					}}
				>
				</div>
			)}
			<AdvertiserContact
				modalState={advertiserContactModalState}
				close={() => setAdvertiserContactModalState(OpenClosedStates.Closed)}
				advertiserInfo={currRequest}
			/>
		</div>
	);
};

export default RequestedAdsDisplay;
