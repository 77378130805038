import React, { useEffect, useState } from 'react';
import useManufacturers from '../pages/manufacturers/hooks/useManufacturers';
import { Manufacturer } from '../pages/manufacturers/Manufacturer.type';
import { Select } from './form/Select';
import ManufacturerAddModal from './ManufacturerAddModal';
import { OpenClosedStates } from './OpenClosedStates';
import { FormError } from './types/ErrorResponse.type';

type Props = {
	onChange: (val: number) => void;
	value?: number | number[];
	errors?: FormError;
	className?: string;
};

const ManufacturerList: React.FC<Props> = ({
	onChange,
	value,
	errors,
	className = 'col-span-6',
}) => {
	const {
		isFetchingNextPage,
		isFetching,
		fetchNextPage,
		hasNextPage,
		manufacturerOptions,
		onAddManufacturer,
	} = useManufacturers(
		true,
		value ? (Array.isArray(value) ? value : [value]).join(',') : undefined
	);
	const [modalState, setModalState] = useState<OpenClosedStates>(
		OpenClosedStates.Closed
	);

	useEffect(() => {
		console.log({ manufacturerOptions });
	}, [manufacturerOptions]);

	const onAdd = () => {
		setModalState(OpenClosedStates.Open);
	};

	const onSaveAdd = (val: Manufacturer) => {
		const id = val?.id as number;
		if (val?.id) {
			onAddManufacturer(val);
			onChange(id);
		}
	};

	return (
		<>
			<Select
				options={manufacturerOptions}
				valueProp="id"
				textProp="name"
				label="Manufacturer"
				value={value}
				onChange={onChange}
				name="manufacturer"
				className={className}
				errors={errors}
				limit={1}
				isFetchingNextPage={!!isFetchingNextPage}
				fetchNextPage={fetchNextPage}
				hasNextPage={hasNextPage}
				onAdd={onAdd}
			/>
			<ManufacturerAddModal
				isDataFetching={isFetching}
				state={modalState}
				setState={setModalState}
				save={onSaveAdd}
			/>
		</>
	);
};

export default ManufacturerList;
