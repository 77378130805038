import React, { ChangeEvent, forwardRef, MutableRefObject } from 'react';
import { FormError } from '../types/ErrorResponse.type';
import { ErrorIcon } from './ErrorIcon';
import { FieldErrors } from './FieldErrors';

type Props = {
	name: string;
	className: string;
	placeholder?: string;
	icon?: JSX.Element;
	label?: string;
	value?: string;
	onChange: (val: string) => void;
	errors?: FormError;
	disabled?: boolean;
	onFocus?: () => void;
	maxLen?: number;
};

export const Textarea = forwardRef(
	(
		{
			name,
			label,
			placeholder,
			value,
			onChange,
			errors,
			className,
			icon,
			disabled = false,
			onFocus,
			maxLen,
		}: Props,
		ref: ((instance: unknown) => void) | MutableRefObject<any> | null
	) => {
		const onChangeValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
			const val = e.target.value;
			onChange(val);
		};

		return (
			<div className={className}>
				{label && (
					<div>
						<label
							htmlFor={name}
							className="block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2"
						>
							{label}
						</label>
					</div>
				)}
				<div>
					<div className="relative rounded-md shadow-sm">
						<div className="relative flex items-stretch flex-grow focus-within:z-10">
							{icon && (
								<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
									{icon}
								</div>
							)}
							<textarea
								id={name}
								name={name}
								placeholder={placeholder}
								className={[
									'block w-full border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-150 ease-in-out sm:text-sm sm:leading-5',
									errors
										? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red'
										: '',
									icon ? 'pl-10' : '',
								].join(' ')}
								value={value || ''}
								onChange={onChangeValue}
								onFocus={onFocus}
								disabled={disabled}
								ref={ref}
								maxLength={maxLen}
							/>
							{errors && <ErrorIcon />}
						</div>
					</div>
					<FieldErrors errors={errors} />
				</div>
			</div>
		);
	}
);
