import { useContext } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { MeContext } from '../../../common/contexts/me.context';
import { ErrorResponse } from '../../../common/types/ErrorResponse.type';
import * as userService from '../../../services/user.service';
import { User } from '../User.type';

const getUserQuery = (accountId?: number, userId = '') => {
	return async (context: QueryFunctionContext) => {
		if (userId === 'new' || accountId === undefined || accountId === null) {
			return {} as User;
		}
		return userService.get({ accountId, userId });
	};
};

const useUser = (id?: string, accountId?: number) => {
	const { me } = useContext(MeContext);
	const queryClient = useQueryClient();
	const { data, isFetching, refetch, remove, isFetched } = useQuery<
		User,
		ErrorResponse
	>(['user', id], getUserQuery(accountId || me.account?.id, id), {
		refetchOnWindowFocus: false,
		retry: false,
	});

	const save = async (val: Partial<User>) => {
		if (me.account.id === undefined) {
			return;
		}
		const res = await userService.save({
			accountId: accountId || me.account?.id,
			user: val,
		});
		queryClient.setQueryData(['user', id], res);
		return res;
	};

	return {
		isFetching,
		data,
		save,
		refetch,
		remove,
		isFetched,
	};
};

export default useUser;
