import { XIcon } from '@heroicons/react/outline';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export type Props = {
	icon: JSX.Element;
	title: string;
	subtitle?: string;
	rejectLink?: string;
	rejectMessage?: string;
	acceptLink?: string;
	acceptMessage?: string;
	close: () => void;
};

const MobileSubscriptionAlert = ({
	icon,
	title,
	subtitle,
	rejectLink,
	rejectMessage,
	acceptLink,
	acceptMessage,
	close,
}: Props) => {
	const navigate = useNavigate();
	const closeAlertPage = (link?: string) => {
		if (link) {
			navigate(`${link}`);
			return close();
		}
		close();
	};

	return (
		<div className="h-screen w-screen bg-primary-500 text-white z-50 sm:hidden flex flex-col justify-center items-center">
			<div
				className="absolute right-0 top-0 flex gap-2"
				onClick={() => {
					closeAlertPage(rejectLink || '');
				}}
			>
				<p className="underline">{rejectMessage}</p>
				<XIcon className="w-7 h-7" />
			</div>
			<div className="flex flex-col gap-4 justify-center items-center w-full p-4 text-center">
				<p>{icon}</p>
				<p className="text-3xl font-bold">{title}</p>
				<p className="text-sm">{subtitle}</p>
				<span
					className="underline"
					onClick={() => closeAlertPage(acceptLink || '')}
				>
					{acceptMessage}
				</span>
			</div>
		</div>
	);
};

export default MobileSubscriptionAlert;
