import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { MeContext } from '../../common/contexts/me.context';
import AccountSettingsAPIKeys from './AccountSettingsAPIKeys';
import AccountSettingsColors from './AccountSettingsColors';
import AccountSettingsGeneral from './AccountSettingsGeneral';
import AccountSettingsLocations from './AccountSettingsLocations';
import useAccountSettings from './useAccountSettings';
import useAccount from '../accounts/hooks/useAccount';

const AccountSettings: React.FC = () => {
	const { me } = useContext(MeContext);
	const { data, save, saveLocation, removeLocation, isSaving } =
		useAccountSettings();
	const {
		isFetching,
		data: userData,
		save: saveAccount,
	} = useAccount(me?.account?.id as any);

	return (
		<Routes>
			<Route
				path="/"
				element={
					<AccountSettingsGeneral
						defaultValues={{
							...data?.account,
							manufacturers: data?.account?.manufacturers.map(
								(item) => item.value
							),
						}}
						save={save}
					/>
				}
			/>
			<Route
				path="locations/*"
				element={
					<AccountSettingsLocations
						locations={me?.account?.locations || []}
						saveLocation={saveLocation}
						removeLocation={removeLocation}
					/>
				}
			/>
			{me?.account?.manufacturer && (
				<Route
					path="colors"
					element={
						<AccountSettingsColors
							defaultValues={data?.account?.colors}
							save={save}
							id={data.account.id}
							manufacturer={data.account.manufacturer}
							isSaving={isSaving}
						/>
					}
				/>
			)}
			<Route
				path="api-keys"
				element={
					<AccountSettingsAPIKeys
						userData={userData}
						isFetchingUserData={isFetching}
						save={saveAccount}
					/>
				}
			/>
		</Routes>
	);
};

export default AccountSettings;
