import { AtSymbolIcon, CheckIcon, MailOpenIcon } from '@heroicons/react/outline';
import React, { useContext, useState } from 'react';
import * as dealerService from '../services/dealer.service';
import { ToastContext, ToastTypes } from './contexts/toast.context';
import { Input } from './form/Input';
import { formatPhone } from './helpers/format';
import Modal, { ModalSizes } from './Modal';
import { Phone } from './form/Phone';
import { Textarea } from './form/Textarea';
import { useForm } from './hooks/useForm';
import { OpenClosedStates } from './OpenClosedStates';
import * as adService from '../services/ad.service';

export type ContactForm = {
	name: string;
	phone: string;
	email: string;
	message: string;
};

enum STATES {
	NOT_SENT,
	SENT,
}

const validation: any = {
	name: { required: { message: 'Name is required.' } },
	// email: { required: { message: 'Email is required.' } },
	message: { required: { message: 'Message is required.' } },
	email: {
		function: {
			value: (value: Partial<ContactForm>) => {
				if (!value.phone && !value.email) {
					return 'Please provide at least one method for the dealer to contact you.';
				}
			},
		},
	},
	phone: {
		function: {
			value: (value: Partial<ContactForm>) => {
				if (!value.email && !value.phone) {
					return 'Please provide at least one method for the dealer to contact you.';
				}
			},
		},
		pattern: {
			value: new RegExp(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/),
			message: 'Provide a valid phone number',
		},
	},
};

type Props = {
	modalState: OpenClosedStates;
	close: any;
	advertiserInfo?: {
		phone?: string;
		adContactFormEmail: string;
		name?: string;
		id?: number;
	};
	// requestId?: number;
};

const AdvertiserContact: React.FC<Props> = ({
	modalState,
	close,
	advertiserInfo,
	// requestId,
}) => {
	const [state, setState] = useState(STATES.NOT_SENT);
	const { createToast } = useContext(ToastContext);

	const { value, onChange, registerSubmit, errors, reset } =
		useForm<ContactForm>({}, validation);

	const onSubmit = async (values: Partial<ContactForm>) => {
		if (advertiserInfo?.id) {
			await adService.trackClick(advertiserInfo.id);
		}
		return dealerService.contact(values, advertiserInfo?.adContactFormEmail);
	};

	const onSuccess = () => {
		setState(STATES.SENT);
	};

	const onFail = () => {
		createToast({
			type: ToastTypes.Fail,
			title: 'Failed to send',
			description: <>Your message to the dealer did not successfully send.</>,
		});
	};

	const onClose = () => {
		reset();
		close();
		setState(STATES.NOT_SENT);
	};

	return (
		<Modal state={modalState} size={ModalSizes.full} maxHeight={800}>
			{state === STATES.SENT ? (
				<>
					<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
						<CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
					</div>
					<div className="mt-3 text-center sm:mt-5">
						<h3 className="text-lg leading-6 font-medium text-gray-900">
							Successfully contacted dealer
						</h3>
						<div className="mt-2 text-left">
							<p className="text-sm text-gray-500">
								Thank you for contacting the dealer. You should receive a
								response soon regarding your inquiry.
							</p>
						</div>
					</div>

					<div className="mt-5 sm:mt-6">
						<button
							type="button"
							className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:col-start-1 sm:text-sm"
							onClick={onClose}
						>
							Close
						</button>
					</div>
				</>
			) : (
				<>
					<form onSubmit={registerSubmit(onSubmit, { onSuccess, onFail })}>
						<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-primary-100">
						<MailOpenIcon className='h-6 w-6 text-primary-600'
  									aria-hidden='true'/>
						</div>
						<div className="mt-3 text-center sm:mt-5">
							<h3 className="text-lg leading-6 font-medium text-gray-900">
								Contact {advertiserInfo?.name || 'advertiser'}
							</h3>

							<div className="p-2 mt-4 flex flex-col gap-1 items-center text-sm">
								<div className="flex gap-6">
									<div className="flex gap-1 items-center">
										<span className="text-primary-500">
											<a href={`tel:${advertiserInfo?.phone}`}>
												{formatPhone(advertiserInfo?.phone)}
											</a>
										</span>
									</div>
								</div>

								<div className="flex flex-row gap-2">
									<div className="flex gap-1 items-center">
										<AtSymbolIcon className="h-5 w-5" />
										<a
											className="text-primary-500"
											href={
												advertiserInfo?.adContactFormEmail
													? `mailto:${advertiserInfo?.adContactFormEmail}`
													: `mailto:${advertiserInfo?.adContactFormEmail}`
											}
											target="_blank"
											rel="noreferrer"
										>
											{advertiserInfo?.adContactFormEmail
												? advertiserInfo?.adContactFormEmail
												: advertiserInfo?.adContactFormEmail}
										</a>
									</div>
								</div>
							</div>
							<div className="mt-2 text-left">
								<Input
									label="Name *"
									name="name"
									value={value.name}
									onChange={onChange('name')}
									errors={errors.fieldErrors?.name}
								/>
								<Input
									// label={['Email', !textContact ? ' *' : ''].join('')}
									label="Email"
									type="email"
									name="email"
									value={value.email}
									onChange={onChange('email')}
									errors={errors.fieldErrors?.email}
								/>
								<div className="flex gap-2">
									<Phone
										// label={['Phone', textContact ? ' *' : ''].join('')}
										label="Phone"
										name="phone"
										value={value.phone}
										onChange={onChange('phone')}
										errors={errors.fieldErrors?.phone}
										className="w-1/2"
									/>
								</div>
								<Textarea
									label="Message *"
									name="message"
									value={value.message}
									onChange={onChange('message')}
									errors={errors.fieldErrors?.message}
									className=""
								/>
							</div>
						</div>

						<br />
						<div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
							<button
								type="submit"
								className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:col-start-2 sm:text-sm"
							>
								Send
							</button>
							<button
								type="button"
								className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:col-start-1 sm:text-sm"
								onClick={close}
							>
								Cancel
							</button>
						</div>
					</form>
				</>
			)}
		</Modal>
	);
};

export default AdvertiserContact;
