//ping
import { BellIcon, XIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router';
import Modal, { ModalSizes } from '../Modal';
import { OpenClosedStates } from '../OpenClosedStates';
import { SubAlert } from './MobileSubscriptionAlertPage';

type Props = {
	state: OpenClosedStates;
	close: () => void;
};

const DesktopSubsciptionAlert = ({
	rejectMessage,
	state,
	close,
}: Props & SubAlert) => {
	const navigate = useNavigate();

	const closeAlert = (link: string) => {
		if (link) {
			navigate(`${link}`);
			return close();
		}
	};

	return (
		<Modal state={state} size={ModalSizes['5xl']} noScroll>
			<div className=" relative top-0">
				<div
					className="absolute top-0 right-0 flex gap-1 items-center text-gray-400 cursor-pointer text-sm"
					onClick={() => close()}
				>
					<p>{rejectMessage}</p>
					<XIcon className="h-5 w-5" />
				</div>
				<div className="h-full w-full flex flex-col gap-3 items-center justify-center">
					<BellIcon className="h-6 w-6 text-primary-500" />
					<span className="font-bold text-xl text-primary-400">
						We're updating the way our subscriptions work
					</span>
					<span>
						We'll call you shortly to talk you through switching your plan over
					</span>
					<span>
						In the meantime, feel free to look over what the new options
						available look like!
					</span>
					<span>
						Click{' '}
						<span
							className="text-primary-400 underline cursor-pointer"
							onClick={() => closeAlert('billing')}
						>
							here
						</span>{' '}
						to see our new options
					</span>
				</div>
			</div>
		</Modal>
	);
};

export default DesktopSubsciptionAlert;
