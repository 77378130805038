import { useEffect, useState } from 'react';
import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import { useDebounce } from '../../../common/hooks/useDebounce';
import { LIST_LIMIT } from '../../../constants';
import * as accountsService from '../../../services/account.service';
import { Account } from '../Account.type';

const getAccountsQuery = () => {
	return async (context: QueryFunctionContext) => {
		const search = context.queryKey[1] as any;
		return accountsService.list({
			skip: context.pageParam,
			limit: LIST_LIMIT,
			search: JSON.stringify(search),
		});
	};
};

const useAccounts = () => {
	const [queryKey, setQueryKey] = useState<[string, string | undefined]>([
		'accounts',
		undefined,
	]);
	const {
		status,
		data,
		error,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		refetch,
	} = useInfiniteQuery<{
		data: Account[];
		metaData: { skip: number; totalRecords: number };
	}>(queryKey, getAccountsQuery(), {
		getNextPageParam: (lastGroup) => lastGroup?.metaData?.skip || undefined,
		refetchOnWindowFocus: false,
	});
	const [searchTerm, setSearchTerm] = useState<any>();
	const debouncedSearchTerm = useDebounce(searchTerm);

	useEffect(() => {
		setQueryKey(['accounts', debouncedSearchTerm]);
	}, [debouncedSearchTerm]);

	const onSearch = (val: any) => {
		setSearchTerm(val);
	};

	return {
		status,
		data,
		error,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		refetch,
		onSearch,
	};
};

export default useAccounts;
