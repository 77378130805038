import React from "react";
import Header from "../../common/Header";
import LoadingPage from "../../common/LoadingPage";
import useProfile from "./useProfile";
import ProfileForm from "./ProfileForm";
import ChangePassword from "./ChangePassword";

const Profile: React.FC = () => {
  const { isFetching, data, save } = useProfile();

  return isFetching ? (
    <LoadingPage />
  ) : (
    <>
      <Header title="Profile" rightSide={<ChangePassword />} />
      <div className="px-6 pt-6 bg-gray-50 h-full">
        <ProfileForm defaultValues={data} save={save} />
      </div>
    </>
  );
};

export default Profile;
