import React from 'react';
import { Route, Routes } from 'react-router-dom';
import UserContainer from './detail/UserContainer';
import UserList from './UserList';

const Users = () => {
	return (
		<Routes>
			<Route path="/" element={<UserList />} />
			<Route path=":userId/*" element={<UserContainer />} />
			<Route path="*" element={<span>no match</span>} />
		</Routes>
	);
};

export default Users;
