export interface DescriptionOption {
	id: number;
	description: (
		width: number,
		length: number,
		model: string,
		type: string,
		style: any,
		manufacturer: string,
		location: string
	) => string;
}
export const LIST_LIMIT = 30;
export const TABLE_PAGE_LIMIT = 10;

export const PLAN_LIMITS: {
	[key: string]: {
		products: number;
		images: number;
	};
} = {
	essentials: {
		products: 25,
		images: 3,
	},
	pro: {
		products: 100,
		images: 6,
	},
	elite: {
		products: 999999999,
		images: 10,
	},
};

export const ROLES = [
	{
		text: 'Admin',
		value: 2,
	},
	{
		text: 'User',
		value: 3,
	},
];

export const ACCOUNT_TYPES = [
	{
		text: 'Dealer',
		value: 'Dealer',
	},
	{
		text: 'Manufacturer',
		value: 'Manufacturer',
	},
];
// for use in searchable select states
export const STATES_SELECT = [
	{ label: 'Alabama', value: 'AL' },
	{ label: 'Alaska', value: 'AK' },
	{ label: 'Arizona', value: 'AZ' },
	{ label: 'Arkansas', value: 'AR' },
	{ label: 'California', value: 'CA' },
	{ label: 'Colorado', value: 'CO' },
	{ label: 'Connecticut', value: 'CT' },
	{ label: 'Delaware', value: 'DE' },
	{ label: 'District of Columbia', value: 'DC' },
	{ label: 'Florida', value: 'FL' },
	{ label: 'Georgia', value: 'GA' },
	{ label: 'Hawaii', value: 'HI' },
	{ label: 'Idaho', value: 'ID' },
	{ label: 'Illinois', value: 'IL' },
	{ label: 'Indiana', value: 'IN' },
	{ label: 'Iowa', value: 'IA' },
	{ label: 'Kansas', value: 'KS' },
	{ label: 'Kentucky', value: 'KY' },
	{ label: 'Louisiana', value: 'LA' },
	{ label: 'Maine', value: 'ME' },
	{ label: 'Maryland', value: 'MD' },
	{ label: 'Massachusetts', value: 'MA' },
	{ label: 'Michigan', value: 'MI' },
	{ label: 'Minnesota', value: 'MN' },
	{ label: 'Mississippi', value: 'MS' },
	{ label: 'Missouri', value: 'MO' },
	{ label: 'Montana', value: 'MT' },
	{ label: 'Nebraska', value: 'NE' },
	{ label: 'Nevada', value: 'NV' },
	{ label: 'New Hampshire', value: 'NH' },
	{ label: 'New Jersey', value: 'NJ' },
	{ label: 'New Mexico', value: 'NM' },
	{ label: 'New York', value: 'NY' },
	{ label: 'North Carolina', value: 'NC' },
	{ label: 'North Dakota', value: 'ND' },
	{ label: 'Ohio', value: 'OH' },
	{ label: 'Oklahoma', value: 'OK' },
	{ label: 'Oregon', value: 'OR' },
	{ label: 'Pennsylvania', value: 'PA' },
	{ label: 'Rhode Island', value: 'RI' },
	{ label: 'South Carolina', value: 'SC' },
	{ label: 'South Dakota', value: 'SD' },
	{ label: 'Tennessee', value: 'TN' },
	{ label: 'Texas', value: 'TX' },
	{ label: 'Utah', value: 'UT' },
	{ label: 'Vermont', value: 'VT' },
	{ label: 'Virginia', value: 'VA' },
	{ label: 'Washington', value: 'WA' },
	{ label: 'West Virginia', value: 'WV' },
	{ label: 'Wisconsin', value: 'WI' },
	{ label: 'Wyoming', value: 'WY' },
];

export const STATES = [
	{ text: 'Alabama', value: 'AL' },
	{ text: 'Alaska', value: 'AK' },
	{ text: 'Arizona', value: 'AZ' },
	{ text: 'Arkansas', value: 'AR' },
	{ text: 'California', value: 'CA' },
	{ text: 'Colorado', value: 'CO' },
	{ text: 'Connecticut', value: 'CT' },
	{ text: 'Delaware', value: 'DE' },
	{ text: 'District of Columbia', value: 'DC' },
	{ text: 'Florida', value: 'FL' },
	{ text: 'Georgia', value: 'GA' },
	{ text: 'Hawaii', value: 'HI' },
	{ text: 'Idaho', value: 'ID' },
	{ text: 'Illinois', value: 'IL' },
	{ text: 'Indiana', value: 'IN' },
	{ text: 'Iowa', value: 'IA' },
	{ text: 'Kansas', value: 'KS' },
	{ text: 'Kentucky', value: 'KY' },
	{ text: 'Louisiana', value: 'LA' },
	{ text: 'Maine', value: 'ME' },
	{ text: 'Maryland', value: 'MD' },
	{ text: 'Massachusetts', value: 'MA' },
	{ text: 'Michigan', value: 'MI' },
	{ text: 'Minnesota', value: 'MN' },
	{ text: 'Mississippi', value: 'MS' },
	{ text: 'Missouri', value: 'MO' },
	{ text: 'Montana', value: 'MT' },
	{ text: 'Nebraska', value: 'NE' },
	{ text: 'Nevada', value: 'NV' },
	{ text: 'New Hampshire', value: 'NH' },
	{ text: 'New Jersey', value: 'NJ' },
	{ text: 'New Mexico', value: 'NM' },
	{ text: 'New York', value: 'NY' },
	{ text: 'North Carolina', value: 'NC' },
	{ text: 'North Dakota', value: 'ND' },
	{ text: 'Ohio', value: 'OH' },
	{ text: 'Oklahoma', value: 'OK' },
	{ text: 'Oregon', value: 'OR' },
	{ text: 'Pennsylvania', value: 'PA' },
	{ text: 'Rhode Island', value: 'RI' },
	{ text: 'South Carolina', value: 'SC' },
	{ text: 'South Dakota', value: 'SD' },
	{ text: 'Tennessee', value: 'TN' },
	{ text: 'Texas', value: 'TX' },
	{ text: 'Utah', value: 'UT' },
	{ text: 'Vermont', value: 'VT' },
	{ text: 'Virginia', value: 'VA' },
	{ text: 'Washington', value: 'WA' },
	{ text: 'West Virginia', value: 'WV' },
	{ text: 'Wisconsin', value: 'WI' },
	{ text: 'Wyoming', value: 'WY' },
];

export const PRODUCT_TYPES = [
	{ text: 'In stock', value: 'new' },
	{ text: 'Pre-Owned', value: 'preowned' },
	{ text: 'Built to order', value: 'builttoorder' },
];

export const SIDING_TYPES = [
	{ text: 'Metal', value: 'metal' },
	{ text: 'Painted', value: 'painted' },
	{ text: 'Urethane', value: 'urethane' },
	{ text: 'T1-11', value: 't111' },
	{ text: 'Vinyl', value: 'vinyl' },
];

export const ROOFING_TYPES = [
	{ text: 'Metal', value: 'metal' },
	{ text: 'Shingle', value: 'shingle' },
	{ text: 'Tile', value: 'tile' },
];

export const COLORS_ROOF_SHINGLE = [
	{ text: 'Weatherwood', value: 'weatherwood' },
	{ text: 'Black', value: 'black' },
	{ text: 'Brown', value: 'brown' },
	{ text: 'Green', value: 'green' },
];

export const COLORS_PAINT = [
	{ text: 'Burgandy', value: 'burgandy' },
	{ text: 'Red', value: 'red' },
	{ text: 'Gray', value: 'gray' },
	{ text: 'Blue', value: 'blue' },
	{ text: 'Tan', value: 'tan' },
	{ text: 'White', value: 'white' },
	{ text: 'Brown', value: 'brown' },
	{ text: 'Green', value: 'green' },
	{ text: 'Black', value: 'black' },
	{ text: 'Orange', value: 'orange' },
	{ text: 'Purple', value: 'purple' },
	{ text: 'Yellow', value: 'yellow' }
];

export const COLORS_METAL = [
	{ text: 'Burgandy', value: 'burgandy' },
	{ text: 'Red', value: 'red' },
	{ text: 'Gray', value: 'gray' },
	{ text: 'Blue', value: 'blue' },
	{ text: 'Tan', value: 'tan' },
	{ text: 'White', value: 'white' },
	{ text: 'Brown', value: 'brown' },
	{ text: 'Green', value: 'green' },
	{ text: 'Black', value: 'black' },
	{ text: 'Orange', value: 'orange' },
	{ text: 'Gold', value: 'gold' },
];

export const COLORS_TILE = [...COLORS_METAL];

export const COLORS_MAIN: { [key: string]: { text: string; value: string }[] } =
	{
		metal: COLORS_METAL,
		painted: COLORS_PAINT,
		urethane: [
			{ text: 'Driftwood', value: 'driftwood' },
			{ text: 'Red', value: 'red' },
			{ text: 'Mahogany', value: 'mahogany' },
			{ text: 'Black', value: 'black' },
			{ text: 'Honey', value: 'honey' },
			{ text: 'Natural', value: 'natural' },
		],
		t111: [
			{ text: 'Burgandy', value: 'burgandy' },
			{ text: 'Red', value: 'red' },
			{ text: 'Gray', value: 'gray' },
			{ text: 'Blue', value: 'blue' },
			{ text: 'Tan', value: 'tan' },
			{ text: 'White', value: 'white' },
			{ text: 'Brown', value: 'brown' },
			{ text: 'Green', value: 'green' },
			{ text: 'Black', value: 'black' },
			{ text: 'Orange', value: 'orange' },
			{ text: 'Purple', value: 'purple' },
			{ text: 'Yellow', value: 'yellow' },
		],
		vinyl: [
			{ text: 'Tan', value: 'tan' },
			{ text: 'Clay', value: 'clay' },
			{ text: 'Blue', value: 'blue' },
			{ text: 'Gray', value: 'gray' },
			{ text: 'White', value: 'white' },
			{ text: 'Green', value: 'green' },
			{ text: 'Black', value: 'black' },
			{ text: 'Red', value: 'red' },
			{ text: 'Brown', value: 'brown' },
			{ text: 'Yellow', value: 'yellow' },
			{ text: 'Charcoal', value: 'charcoal' },
		],
	};

export const COLORS_ALL = [
	{ text: 'Black', value: 'black' },
	{ text: 'Blue', value: 'blue' },
	{ text: 'Brown', value: 'brown' },
	{ text: 'Burgandy', value: 'burgandy' },
	{ text: 'Charcoal', value: 'charcoal' },
	{ text: 'Clay', value: 'clay' },
	{ text: 'Driftwood', value: 'driftwood' },
	{ text: 'Gold', value: 'gold' },
	{ text: 'Gray', value: 'gray' },
	{ text: 'Green', value: 'green' },
	{ text: 'Honey', value: 'honey' },
	{ text: 'Mahogany', value: 'mahogany' },
	{ text: 'Natural', value: 'natural' },
	{ text: 'Orange', value: 'orange' },
	{ text: 'Purple', value: 'purple' },
	{ text: 'Red', value: 'red' },
	{ text: 'Tan', value: 'tan' },
	{ text: 'Weatherwood', value: 'weatherwood' },
	{ text: 'White', value: 'white' },
	{ text: 'Yellow', value: 'yellow' },
];

export const COLORS_ROOF: { [key: string]: { text: string; value: string }[] } =
	{
		shingle: COLORS_ROOF_SHINGLE,
		metal: COLORS_METAL,
		tile: COLORS_TILE,
	};

export const STYLES = [
	{ text: 'shed', value: 'shed' },
	{ text: 'garage', value: 'garage' },
	{ text: 'living', value: 'living' },
	{ text: 'animal structure', value: 'animal structure' },
	{ text: 'gazebo/pergola', value: 'gazebo' },
];

export const ROOF_STYLES = [
	{ text: 'A-Frame', value: 'aframe' },
	{ text: 'Gambrel', value: 'gambrel' },
	{ text: 'Lean-To', value: 'lean-to' },
	{ text: 'Lofted', value: 'lofted' },
	{ text: 'Other', value: 'other' },
];

function modelStyleCombo(model: string, styles: any): string {
	const modelLower = model.toLowerCase();

	// Check if model contains any of the style strings
	const containsStyle = styles.some((style: any) =>
		modelLower.includes(style.toLowerCase())
	);

	if (containsStyle) {
		return model;
	} else {
		// Join styles into a single string and append to model
		return `${model} ${styles.join(' ')}`;
	}
}

export const DESCRIPTION_OPTIONS: DescriptionOption[] = [
	// Professional + Trustworthy Tone
	{
		id: 1,
		description: (width, length, model, type, style, manufacturer, location) =>
			`Need more space? Get practical with our ${type} ${width}x${length} ${modelStyleCombo(
				model,
				style
			)}. From the well-regarded builders at ${manufacturer}, it's a trusty addition to your yard. Swing by our ${location} Sales Lot to check it out!`,
	},

	// Friendly + Personable Tone
	{
		id: 2,
		description: (
			width: number,
			length: number,
			model: string,
			type: string,
			style: string,
			manufacturer: string,
			location: string
		) => {
			const combo = modelStyleCombo(model, style);
			return `Howdy, friend! Have a gander at our ${type} ${width}x${length} ${combo}. Brought to you by the friendly faces at ${manufacturer}, and awaiting you at our lovely ${location} Sales Lot. It's a true neighborly addition to any property.`;
		},
	},

	// Casual + Conversational Tone
	{
		id: 3,
		description: (
			width: number,
			length: number,
			model: string,
			type: string,
			style: string,
			manufacturer: string,
			location: string
		) => {
			const combo = modelStyleCombo(model, style);
			return `Hello there! Check out our ${type} ${width}x${length} ${combo} from our trusted supplier, ${manufacturer}. It's a sturdy, professional-grade structure waiting to be picked up from our ${location} Shed Lot. It's just what your property needs!`;
		},
	},

	// Professional + Straightforward Tone
	{
		id: 4,
		description: (
			width: number,
			length: number,
			model: string,
			type: string,
			style: string,
			manufacturer: string,
			location: string
		) => {
			const combo = modelStyleCombo(model, style);
			return `Hey there! Seen our ${type} ${width}x${length} ${combo} yet? Made by the creative folks at ${manufacturer}, it's a real showstopper. And guess what? It's ready to be yours from our ${location} Sales Lot. Grab it while you can!`;
		},
	},
	// Positive + Enthusiastic Tone
	{
		id: 5,
		description: (
			width: number,
			length: number,
			model: string,
			type: string,
			style: string,
			manufacturer: string,
			location: string
		) => {
			const combo = modelStyleCombo(model, style);
			return `We present to you our ${type} ${width}x${length} ${combo}, crafted to perfection by ${manufacturer}. It's a mark of trust and quality, ready for pick-up from our ${location} Shed Lot. Your search for a reliable storage solution ends here.`;
		},
	},
	// Authoritative + Professional Tone
	{
		id: 6,
		description: (
			width: number,
			length: number,
			model: string,
			type: string,
			style: string,
			manufacturer: string,
			location: string
		) => {
			const combo = modelStyleCombo(model, style);
			return `Introducing our ${type} ${width}x${length} ${combo}, a perfect blend of design and utility. Made by ${manufacturer}, this shed is a great addition to your yard. Visit us at our ${location} Sales Lot to find out more.`;
		},
	},
	// Energetic + Exciting Tone
	{
		id: 7,
		description: (
			width: number,
			length: number,
			model: string,
			type: string,
			style: string,
			manufacturer: string,
			location: string
		) => {
			const combo = modelStyleCombo(model, style);
			return `Get ready for our sensational ${type} ${width}x${length} ${combo}! Manufactured by ${manufacturer}, this structure is a joy to behold. It's at our ${location} Shed Lot and can't wait to be part of your next big project!`;
		},
	},
	// Sophisticated + Stylish Tone
	{
		id: 8,
		description: (
			width: number,
			length: number,
			model: string,
			type: string,
			style: string,
			manufacturer: string,
			location: string
		) => {
			const combo = modelStyleCombo(model, style);
			return `Unveiling our ${type} ${width}x${length} ${combo} from ${manufacturer}, a masterpiece of professional craftsmanship. Visit us at our ${location} Sales Lot to witness the epitome of outdoor storage solutions.`;
		},
	},
	// Nostalgic + Vintage Tone
	{
		id: 9,
		description: (
			width: number,
			length: number,
			model: string,
			type: string,
			style: string,
			manufacturer: string,
			location: string
		) => {
			const combo = modelStyleCombo(model, style);
			return `Journey back in time with our ${type} ${width}x${length} ${combo}. It's a heartwarming creation from ${manufacturer}, ready for you at our ${location} Shed Lot. Bring a nostalgic touch to your property today.`;
		},
	},
	// Adventurous + Exciting Tone
	{
		id: 10,
		description: (
			width: number,
			length: number,
			model: string,
			type: string,
			style: string,
			manufacturer: string,
			location: string
		) => {
			const combo = modelStyleCombo(model, style);
			return `Set your sights on our ${type} ${width}x${length} ${combo}! This masterpiece from ${manufacturer} is waiting at our ${location} Sales Lot, ready to kick start your next big adventure.`;
		},
	},
	// Detailed / Focused Tone
	{
		id: 11,
		description: (
			width: number,
			length: number,
			model: string,
			type: string,
			style: string,
			manufacturer: string,
			location: string
		) => {
			const combo = modelStyleCombo(model, style);
			return `Dive into the details of our ${type} ${width}x${length} ${combo}. Manufactured by the experts at ${manufacturer}, this structure will exceed your expectations. Available at our ${location} Shed Lot, this is the structure that sets the standard in quality.`;
		},
	},
];
