import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
dayjs.extend(utc);

type Props = {
  createDate: string;
};

const NotificationDateTime: React.FC<Props> = ({ createDate }) => {
  const [datetime, setDatetime] = useState<string>();

  useEffect(() => {
    const localizedDate = dayjs.utc(createDate).local();
    const now = dayjs();

    if (localizedDate.isAfter(now.subtract(24, "hours"))) {
      setDatetime(localizedDate.fromNow());
    } else {
      setDatetime(localizedDate.format("M/D/YYYY @ hh:mma"));
    }
  }, [createDate]);

  return (
    <div className="text-left text-sm whitespace-nowrap text-gray-400">
      {datetime}
    </div>
  );
};

export default NotificationDateTime;
