import React from 'react';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import Header from '../../../common/Header';
import LoadingPage from '../../../common/LoadingPage';
import useDealer from '../hooks/useDealer';
import DealerGeneral from './DealerGeneral';
import DealerTabs from './DealerTabs';

const DealerContainer = () => {
	const { dealerId } = useParams();
	const { pathname } = useLocation();
	const { isFetching, data, save } = useDealer(dealerId);

	return isFetching ? (
		<LoadingPage />
	) : (
		<>
			<Header title={data?.id ? data.name : 'New dealer'}>
				<DealerTabs active={pathname} dealerId={dealerId as string} />
			</Header>
			<Routes>
				<Route
					path="/"
					element={
						<div className="px-6 pt-6 bg-gray-50 h-full">
							<DealerGeneral defaultValues={data} save={save} />
						</div>
					}
				/>
			</Routes>
		</>
	);
};

export default DealerContainer;
