import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SearchIcon } from '@heroicons/react/outline';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import Loading from '../Loading';
import { Input } from './Input';
import { useOnClickOutside } from '../hooks/useOnClickOutside';

export type SelectOption = {
	value: any;
	text: string;
};

export type SelectProps = {
	name: string;
	value?: any;
	onChange: (val: any) => void;
	options?: any[];
	isFetchingNextPage?: boolean;
	isFetching: boolean;
	fetchNextPage?: (options?: FetchNextPageOptions | undefined) => Promise<
		InfiniteQueryObserverResult<
			{
				data: any[];
				lastKey?: string;
				skip?: number;
			},
			unknown
		>
	>;
	hasNextPage?: boolean;
	onChangeSearch: (searchTerm: string) => void;
	searchTerm?: string;
};

export const Autocomplete: React.FC<SelectProps> = ({
	name,
	value,
	onChange,
	options = [],
	isFetchingNextPage,
	isFetching,
	fetchNextPage,
	hasNextPage,
	onChangeSearch,
	searchTerm,
}) => {
	const [open, setOpen] = useState(false);
	const searchRef = useRef<HTMLInputElement>(null);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (searchRef.current) {
			searchRef.current.focus();
		}
	}, [options]);

	useOnClickOutside(ref, () => setOpen(false));

	return (
		<div ref={ref}>
			<Listbox
				value={value}
				onChange={(val) => {
					onChange(val);
					onChangeSearch('');
					setOpen(false);
				}}
			>
				<div>
					<div className="relative">
						{open ? (
							<Input
								name="search"
								className={['w-full', open ? 'block' : 'hidden'].join(' ')}
								ref={searchRef}
								onChange={(val) => {
									onChangeSearch(val);
								}}
								value={searchTerm}
								icon={
									isFetching ? (
										<Loading
											className="h-5 w-5 text-gray-400"
											aria-hidden="true"
										/>
									) : (
										<></>
									)
								}
							/>
						) : (
							<div
								onClick={() => {
									setOpen(true);
									setTimeout(() => {
										searchRef.current?.focus();
									}, 0);
								}}
							>
								<Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
									<span className="block truncate">
										{value || <>Search by city or zip code</>}
									</span>
									<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
										<SearchIcon
											className="h-5 w-5 text-gray-400"
											aria-hidden="true"
										/>
									</span>
								</Listbox.Button>
							</div>
						)}

						<Transition
							show={open && !!options?.length}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options
								static
								className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
							>
								{(options || []).map((option, i: number) => {
									const isActive = value === option.zip;
									return (
										<Listbox.Option
											key={option.zip}
											className={({ active }) =>
												[
													isActive
														? 'text-white bg-primary-500'
														: 'text-gray-900',
													'select-none relative py-2 px-3 cursor-pointer',
												].join(' ')
											}
											value={option.zip}
										>
											{({ selected, active }) => (
												<>
													<span
														className={[
															isActive ? 'font-semibold' : 'font-normal',
															'truncate flex flex-row justify-between',
														].join(' ')}
													>
														<span className="font-bold">
															{option.city}, {option.state}
														</span>
														<span>{option.zip}</span>
													</span>

													{isActive ? (
														<span
															className={[
																'text-white',
																'absolute inset-y-0 right-0 flex items-center pr-4',
															].join(' ')}
														>
															<CheckIcon
																className="h-5 w-5"
																aria-hidden="true"
															/>
														</span>
													) : null}
												</>
											)}
										</Listbox.Option>
									);
								})}
							</Listbox.Options>
						</Transition>
					</div>
				</div>
			</Listbox>
		</div>
	);
};
