import { API, Auth } from 'aws-amplify';

export const get = async (apiName: any, path: any, init: any) => {
	try {
		const res = await API.get(apiName, path, init);
		return res;
	} catch (err: any) {
		if (err?.response?.status === 401) {
			Auth.signOut();
		}
		throw err;
	}
};

export const put = async (apiName: any, path: any, init: any) => {
	try {
		const res = await API.put(apiName, path, init);
		return res;
	} catch (err: any) {
		if (err?.response?.status === 401) {
			Auth.signOut();
		}
		throw err;
	}
};

export const post = async (apiName: any, path: any, init: any) => {
	try {
		const res = await API.post(apiName, path, init);
		return res;
	} catch (err: any) {
		if (err?.response?.status === 401) {
			Auth.signOut();
		}
		throw err;
	}
};

export const del = async (apiName: any, path: any, init: any) => {
	try {
		const res = await API.del(apiName, path, init);
		return res;
	} catch (err: any) {
		if (err?.response?.status === 401) {
			Auth.signOut();
		}
		throw err;
	}
};
