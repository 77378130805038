import dayjs from 'dayjs';
import React from 'react';
import useNotifications from './useNotifications';
import utc from 'dayjs/plugin/utc';
import NotificationDateTime from './NotificationDateTime';
import { Link } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import EndOfList from '../list/EndOfList';
import {
	LinkIcon,
	MailIcon,
	OfficeBuildingIcon,
} from '@heroicons/react/outline';
dayjs.extend(utc);

const icons: { [key: string]: JSX.Element } = {
	'office-building': <OfficeBuildingIcon className="h-5 w-5" />,
	mail: <MailIcon className="h-5 w-5" />,
};

const Notifications = () => {
	const {
		data,
		dismiss,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useNotifications();

	return (
		<div className="h-full relative flex flex-col w-full sm:w-80 xl:w-96 border-l border-gray-200 bg-gray-100 pl-6 pt-6 pb-6 pr-6 sm:pr-1">
			<Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
				<h2 className="text-sm font-semibold pb-6">Activity</h2>
				<div className="flow-root pr-2">
					<ul className="-mb-8">
						{data?.pages?.map((page, pageNumber) => (
							<React.Fragment key={`notifications-page-${pageNumber}`}>
								{page?.data?.map((notification, notificationNumber) => (
									<li
										key={`notification-${notification.id}`}
										className="hover-trigger"
										data-cy="activity"
									>
										<div className="relative pb-8">
											{pageNumber !== 0 && notificationNumber !== 0 && (
												<span
													className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
													aria-hidden="true"
												/>
											)}
											<div className="relative flex space-x-3">
												<div>
													<span
														className={[
															notification.color,
															'h-8 w-8 rounded-full flex items-center justify-center hide-on-hover-trigger-flex',
														].join(' ')}
													>
														<div
															className="h-5 w-5 text-white"
															aria-hidden="true"
														>
															{icons[notification.icon || 'office-building']}
														</div>
													</span>
													<span className="h-8 w-8 rounded-full flex items-center justify-center bg-gray-300 show-on-hover-trigger-flex">
														<div
															className="h-5 w-5 text-white"
															aria-hidden="true"
														>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																className="h-5 w-5 cursor-pointer"
																viewBox="0 0 20 20"
																fill="currentColor"
																onClick={() => dismiss(notification.id)}
															>
																<path
																	fillRule="evenodd"
																	d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
																	clipRule="evenodd"
																/>
															</svg>
														</div>
													</span>
												</div>
												<div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
													<div>
														<p className="text-sm text-gray-500">
															{notification.message}
														</p>
														<div className="flex">
															<NotificationDateTime
																createDate={notification.createDate}
															/>
															{notification.link && (
																<Link
																	to={notification.link}
																	className="inline-block ml-2 mt-0.5"
																>
																	<LinkIcon className="h-4 w-4 text-primary-500 hover:text-primary-400" />
																</Link>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</li>
								))}
							</React.Fragment>
						))}
					</ul>
				</div>

				<EndOfList
					isFetching={isFetching}
					isFetchingNextPage={!!isFetchingNextPage}
					fetchNextPage={fetchNextPage}
					hasNextPage={hasNextPage}
				/>
			</Scrollbars>
		</div>
	);
};

export default Notifications;
