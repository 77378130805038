import Select from 'react-select';
import { FormError } from '../types/ErrorResponse.type';
import { FieldErrors } from './FieldErrors';

type Props = {
	options: { label: string; value: string }[];
	onChange: (val: any) => void;
	errors?: FormError;
	value?: any;
	label: string;
	name: string;
	isMultiSelect: boolean;
};

const SearchableSelect = ({
	options,
	onChange,
	errors,
	value,
	label,
	name,
	isMultiSelect,
}: Props) => {
	const val = isMultiSelect
		? options?.filter((option) => value?.includes(option.value))
		: options?.find((option) => option?.value === value);

	return (
		<div className="col-span-2 w-full rounded-md">
			<span>
				<label
					htmlFor={name}
					className="block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2"
				>
					{label} *
				</label>
			</span>

			<Select
				options={options as any}
				isSearchable
				classNamePrefix="select-search"
				onChange={(selected) => {
					if (selected === null) {
						onChange(null);
						return;
					}

					if (isMultiSelect) {
						onChange((selected as any[]).map((item: any) => item.value));
					} else {
						onChange((selected as any).value);
					}
				}}
				value={val}
				name={name}
				isClearable
				styles={{
					control: (baseStyles, state) => ({
						...baseStyles,
						borderRadius: '6px',
						boxShadow: 'none',
						borderColor: state.isFocused ? '#A0C8F7' : '#D1D5DA',
						'&:hover': {
							borderColor: state.isFocused ? '#A0C8F7' : '#D1D5DA',
						},
					}),
				}}
				isMulti={isMultiSelect}
			/>
			<FieldErrors errors={errors} />
		</div>
	);
};

export default SearchableSelect;
