import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonColors } from '../../common/Button';
import { ToastContext, ToastTypes } from '../../common/contexts/toast.context';
import BlogImageUploader from '../../common/form/BlogImageUploader';
import { Input } from '../../common/form/Input';
import { InputMasked } from '../../common/form/InputMasked';
import { Textarea } from '../../common/form/Textarea';
import WYSIWYG from '../../common/form/WYSIWYG';
import Header from '../../common/Header';
import { useForm } from '../../common/hooks/useForm';
import LoadingPage from '../../common/LoadingPage';
import blogService from '../../services/blog.service';
import { Blog } from '../../types/Blog.type';
import useBlog from './hooks/useBlog';

const BlogDetail = () => {
	const { createToast } = useContext(ToastContext);
	const navigate = useNavigate();
	const { id } = useParams();

	const { data, isFetching } = useBlog(id as string);
	const {
		value,
		onChange,
		registerSubmit,
		patchValueClean,
		errors,
		patchValue,
		isDirty,
		isSubmitting,
	} = useForm<Partial<Blog>>({
		title: '',
		summary: '',
		article: '',
		createDate: '',
		heroImg: '',
		author: '',
		path: '',
		publishDate: '',
	});
	const [isDeleting, setIsDeleting] = useState<boolean>(false);

	useEffect(() => {
		if (data) {
			patchValueClean(data as any);
		}
	}, [data, patchValueClean]);

	const onSuccess = (val: Partial<Blog>) => {
		navigate(`/blog/${val.id}`);
		createToast({
			title: 'Success!',
			description: <>Successfully saved blog entry!</>,
			type: ToastTypes.Success,
			duration: 5000,
		});
	};

	const onFail = (err: any) => {
		createToast({
			title: 'Error!',
			description: <>Failed to save blog entry!</>,
			type: ToastTypes.Fail,
			duration: 5000,
		});
	};

	const onSubmit = async (val: Partial<Blog>) => {
		const res = await blogService.save(val);
		return res;
	};

	const deleteBlog = async () => {
		setIsDeleting(true);
		const res = await blogService.blogDelete(value.id);
		if (!res.error) {
			navigate('/blog?page=1', { state: { refetch: true } });
		} else {
			createToast({
				title: 'Error!',
				description: <>Failed to delete blog entry!</>,
				type: ToastTypes.Fail,
				duration: 5000,
			});
		}
		setIsDeleting(false);
	};

	return (
		<>
			<Header title={data ? { ...(data as any) }.title : 'New article'} />
			{isFetching ? (
				<LoadingPage />
			) : (
				<div className="grid grid-cols-1 sm:grid-cols-6 gap-4 p-4">
					<Input
						name="title"
						label="Title *"
						value={value.title}
						onChange={onChange('title')}
						errors={errors?.fieldErrors?.title}
						className="col-span-6"
					/>
					<Input
						name="author"
						label="Author *"
						value={value.author}
						onChange={onChange('author')}
						errors={errors?.fieldErrors?.author}
						className="col-span-6"
					/>
					<Input
						name="path"
						label="Path *"
						value={value.path}
						onChange={onChange('path')}
						errors={errors?.fieldErrors?.path}
						className="col-span-6"
					/>
					<InputMasked
						name="publishDate"
						value={value.publishDate}
						onChange={onChange('publishDate')}
						options={{
							date: true,
							delimiter: '-',
							datePattern: ['Y', 'm', 'd'],
						}}
						label="Publish date"
						placeholder="YYYY-MM-DD"
					/>
					<BlogImageUploader
						name="Hero image"
						label="heroImg"
						value={value.heroImg}
						onChange={(val) => onChange('heroImg')(val)}
						className="col-span-6"
					/>
					<Textarea
						name="summary"
						label="Summary (max 155 characters)"
						value={value.summary}
						onChange={onChange('summary')}
						errors={errors?.fieldErrors?.summary}
						className="col-span-6"
						maxLen={155}
					/>
					<WYSIWYG
						name="article"
						label="Article *"
						value={value.article}
						onChange={onChange('article')}
						errors={errors?.fieldErrors?.article}
						className="col-span-6"
						patchValue={patchValue}
					/>
					<Button
						text="Save"
						className="col-span-2"
						onClick={registerSubmit(onSubmit, { onSuccess, onFail })}
						loading={isSubmitting}
						disabled={!isDirty || isSubmitting}
					/>
					{value?.id && (
						<Button
							text="Delete"
							className="col-span-2"
							onClick={() => deleteBlog()}
							loading={isDeleting}
							disabled={isDeleting}
							color={ButtonColors.red}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default BlogDetail;
