import React from 'react';
import Loading from '../Loading';

type Props = {
	text?: string;
	className: string;
	onClick: () => void;
	loading?: boolean;
	disabled?: boolean;
};

const PillBox = ({ text, className, onClick, loading, disabled }: Props) => {
	return (
		<>
			<button
				type="button"
				className={[
					'inline-flex items-center justify-center gap-2 w-full h-full px-3 py-1.5 border border-transparent text-xs font-medium rounded-full text-white  focus:outline-none z-50',
					className,
				].join(' ')}
				onClick={(e) => {
					e.stopPropagation();
					onClick();
				}}
				disabled={disabled}
			>
				{text}
				{loading && <Loading size={4} />}
			</button>
		</>
	);
};

export default PillBox;
