import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import {
	QueryFunctionContext,
	useInfiniteQuery,
	useQueryClient,
} from 'react-query';
import { MeContext } from '../../../common/contexts/me.context';
import { useDebounce } from '../../../common/hooks/useDebounce';
import { LIST_LIMIT } from '../../../constants';
import * as contactsService from '../../../services/contact.service';
import { markContacted } from '../../../services/contact.service';
import { Contact } from '../../../types/Contact.type';

const getContactsQuery = (accountId?: number) => {
	return async (context: QueryFunctionContext) => {
		if (typeof accountId !== 'number') {
			return;
		}
		const search = context.queryKey[1] as string;
		return contactsService.list(accountId, {
			skip: context.pageParam,
			limit: LIST_LIMIT,
			search,
		});
	};
};

const useContacts = () => {
	const [queryKey, setQueryKey] = useState<[string, string | undefined]>([
		'contacts',
		undefined,
	]);
	const { me } = useContext(MeContext);
	const queryClient = useQueryClient();
	const {
		status,
		data,
		error,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		refetch,
	} = useInfiniteQuery<{
		data: Contact[];
		metaData: { skip: number; totalRecords: number };
	}>(queryKey, getContactsQuery(me.account.id), {
		getNextPageParam: (lastGroup) => lastGroup?.metaData?.skip || undefined,
		refetchOnWindowFocus: false,
	});

	const markAsContacted = async (contactId: any) => {
		const res = await markContacted(me.account.id, me?.id, contactId);
		const newPages = data?.pages?.map((page) => {
			const newPage = page.data.map((row) => {
				if (row.id === res.id) {
					return res;
				}
				return row;
			});
			return { data: newPage, metaDate: page.metaData };
		});
		queryClient.setQueryData('contacts', {
			...data,
			pages: newPages,
		});
	};

	const reOrder = (order: string) => {
		const newPages = data?.pages?.map((page) => {
			page.data?.sort((a: any, b: any) => {
				if (order === 'asc') {
					return Number(dayjs(a.createDate)) - Number(dayjs(b.createDate));
				} else {
					return Number(dayjs(b.createDate)) - Number(dayjs(a.createDate));
				}
			});
			return page;
		});
		newPages?.sort((a: any, b: any) => {
			if (order === 'asc') {
				return a - b;
			} else {
				return b - a;
			}
		});
		queryClient.setQueryData('contacts', {
			...data,
			pages: newPages,
		});
	};

	const [searchTerm, setSearchTerm] = useState<string>();
	const debouncedSearchTerm = useDebounce(searchTerm);

	useEffect(() => {
		setQueryKey(['contacts', debouncedSearchTerm]);
	}, [debouncedSearchTerm]);

	const filterContacted = (val: boolean) => {
		setSearchTerm(val ? 'contacted' : '');
	};

	return {
		status,
		data,
		error,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		refetch,
		markAsContacted,
		reOrder,
		filterContacted,
		searchTerm,
	};
};

export default useContacts;
