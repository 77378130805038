import { useContext, useEffect, useState } from 'react';
import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import { MeContext } from '../../../common/contexts/me.context';
import { useDebounce } from '../../../common/hooks/useDebounce';
import { LIST_LIMIT } from '../../../constants';
import * as dealersService from '../../../services/dealer.service';
import { Dealer } from '../Dealer.type';

const getDealersQuery = (accountId?: number) => {
	return async (context: QueryFunctionContext) => {
		if (typeof accountId !== 'number') {
			return;
		}
		const search = context.queryKey[1] as string;
		return dealersService.list(accountId, {
			skip: context.pageParam,
			limit: LIST_LIMIT,
			search,
		});
	};
};

const useDealers = () => {
	const { me } = useContext(MeContext);
	const [queryKey, setQueryKey] = useState<[string, string | undefined]>([
		'dealers',
		undefined,
	]);
	const {
		status,
		data,
		error,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		refetch,
	} = useInfiniteQuery<{
		data: Dealer[];
		metaData: { skip: number; totalRecords: number };
	}>(queryKey, getDealersQuery(me.account?.id), {
		getNextPageParam: (lastGroup) => lastGroup?.metaData?.skip || undefined,
		refetchOnWindowFocus: false,
	});
	const [searchTerm, setSearchTerm] = useState<string>();
	const debouncedSearchTerm = useDebounce(searchTerm);

	useEffect(() => {
		setQueryKey(['dealers', debouncedSearchTerm]);
	}, [debouncedSearchTerm]);

	const onSearch = (val: string) => {
		setSearchTerm(val);
	};

	return {
		status,
		data,
		error,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		refetch,
		onSearch,
	};
};

export default useDealers;
