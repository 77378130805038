import React, { useContext } from 'react';
import Button, { ButtonColors } from '../../common/Button';
import { MeContext } from '../../common/contexts/me.context';
import { ToastContext, ToastTypes } from '../../common/contexts/toast.context';
import { Select } from '../../common/form/Select';
import { useForm } from '../../common/hooks/useForm';
import Modal from '../../common/Modal';
import { OpenClosedStates } from '../../common/OpenClosedStates';
import { requestManufacturer } from '../../services/manufacturer.service';
import useManufacturers from '../manufacturers/hooks/useManufacturers';
import { Manufacturer } from '../manufacturers/Manufacturer.type';

type ManufacturerForm = {
	manufacturer: number | null;
};

const validation = {
	manufacturer: {
		required: {
			message: 'Manufacturer is required.',
		},
	},
};

type Props = {
	state: OpenClosedStates;
	setState: React.Dispatch<React.SetStateAction<OpenClosedStates>>;
};

const RequestManufacturerAccessModal: React.FC<Props> = ({
	state,
	setState,
}) => {
	const { me } = useContext(MeContext);
	const {
		data: manufacturers,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useManufacturers();
	const { createToast } = useContext(ToastContext);
	const { value, registerSubmit, isDirty, errors, onChange, isSubmitting } =
		useForm<ManufacturerForm>({ manufacturer: null }, validation);

	const onSuccess = () => {
		setState(OpenClosedStates.Closed);
		createToast({
			title: 'Success!',
			description: <>Successfully requested access!</>,
			type: ToastTypes.Success,
			duration: 5000,
		});
	};

	const onFail = () => {
		createToast({
			title: 'Error!',
			description: <>Failed to request access!</>,
			type: ToastTypes.Fail,
			duration: 5000,
		});
	};

	const onSubmit = async (val: Partial<ManufacturerForm>) => {
		if (!val.manufacturer || !me.account.id) {
			return;
		}
		return requestManufacturer(me.account.id, val.manufacturer);
	};

	return (
		<Modal state={state} minHeight={360}>
			<form>
				<div>
					<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 dark:bg-darkGray-500">
						<svg
							className="h-6 w-6 text-gray-600 dark:text-gray-400"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
							/>
						</svg>
					</div>
					<div className="mt-3 text-center sm:mt-5">
						<h3
							className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100"
							id="modal-headline"
						>
							Request manufacturer access
						</h3>
						<div className="mt-2">
							<p className="text-sm leading-5 text-gray-500 dark:text-gray-400">
								Become a verified manufacturer on Shedsforsale.com and gain
								access to your dealer network.
							</p>
						</div>
						<div className="text-left pt-6">
							<Select
								options={manufacturers?.pages.reduce(
									(arr: Manufacturer[], page) => {
										return arr.concat(page?.data);
									},
									[]
								)}
								valueProp="id"
								textProp="name"
								label="Manufacturer name *"
								value={value.manufacturer}
								onChange={onChange('manufacturer')}
								name="manufacturer"
								className="w-full"
								errors={errors.fieldErrors?.manufacturer}
								limit={1}
								isFetchingNextPage={!!isFetchingNextPage}
								fetchNextPage={fetchNextPage}
								hasNextPage={hasNextPage}
							/>
						</div>
					</div>
				</div>
				<div className="mt-6 grid grid-cols-2 gap-3 grid-flow-row-dense">
					<Button
						text="Close"
						onClick={() => setState(OpenClosedStates.Closed)}
						fullWidth
						color={ButtonColors.plain}
						className="border border-gray-300 dark:border-darkGray-500"
					/>
					<Button
						text="Request access"
						onClick={registerSubmit(onSubmit, { onSuccess, onFail })}
						fullWidth
						loading={isSubmitting}
						disabled={!isDirty || isSubmitting}
					/>
				</div>
			</form>
		</Modal>
	);
};

export default RequestManufacturerAccessModal;
