import { QueryFunctionContext, useQuery, useQueryClient } from "react-query";
import { ErrorResponse } from "../../../common/types/ErrorResponse.type";
import * as dealerService from "../../../services/dealer.service";
import { Dealer } from "../Dealer.type";

const getDealerQuery = (dealerId: number | "new") => {
  return async (context: QueryFunctionContext) => {
    if (dealerId === "new") {
      return {} as Dealer;
    }
    return dealerService.get({ dealerId });
  };
};

const useDealer = (id?: string) => {
  const queryClient = useQueryClient();
  const { data, isFetching, refetch, remove, isFetched } = useQuery<
    Dealer,
    ErrorResponse
  >(["dealer", id], getDealerQuery(id === "new" ? id : Number(id)), {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const save = async (val: Partial<Dealer>) => {
    const res = await dealerService.save({
      dealer: val,
    });
    queryClient.setQueryData(["dealer", id], res);
    return res;
  };

  return {
    isFetching,
    data,
    save,
    refetch,
    remove,
    isFetched,
  };
};

export default useDealer;
