import { StarIcon, XIcon } from '@heroicons/react/outline';
import React, { useContext, useState } from 'react';
import Button, { ButtonColors } from '../../../common/Button';
import Modal, { ModalSizes } from '../../../common/Modal';
import { OpenClosedStates } from '../../../common/OpenClosedStates';
import * as ShedsAPI from '../../../common/helpers/ShedsAPI';
import {
	ToastContext,
	ToastTypes,
} from '../../../common/contexts/toast.context';

const tiers = [
	{ name: '1 week', price: 10, value: 'week1' },
	{ name: '2 weeks', price: 15, value: 'week2' },
	{ name: '1 month', price: 20, value: 'month' },
];

type Props = {
	modalState: OpenClosedStates;
	setModalState: (arg: OpenClosedStates) => void;
	accountId?: number;
	productId?: number;
};

const SponsoredListingModal = ({
	modalState,
	setModalState,
	accountId,
	productId,
}: Props) => {
	const { createToast } = useContext(ToastContext);
	const [purchasing, setPurchasing] = useState<boolean>(false);

	const purchase = async (val: string) => {
		setPurchasing(true);
		let res;
		try {
			res = await ShedsAPI.post('api', `/${accountId}/sponsoredListing`, {
				body: { tier: val, productId },
			});
		} catch (err: any) {
			if (
				err.response.data.message === 'Product listing is already sponsored.'
			) {
				createToast({
					title: 'Error!',
					description: <>Product listing is already sponsored.!</>,
					type: ToastTypes.Fail,
					duration: 5000,
				});
			}
			console.log({ err });
		}
		setPurchasing(false);
		if (res) {
			window.location.href = res.url;
		}
	};

	return (
		<Modal state={modalState} size={ModalSizes['md']} noScroll>
			<div className="flex flex-col w-full items-center">
				<XIcon
					className="h-5 w-5 cursor-pointer text-gray-500 self-end"
					onClick={() => setModalState(OpenClosedStates.Closed)}
				/>
				<StarIcon className="h-7 w-7 text-primary-400" />
				<div className="text-gray-500">
					Purchase a sponsored listing for this item.
				</div>
				<div className="w-2/3 lg:w-full mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-3 lg:gap-5 lg:space-y-0">
					{tiers.map((tier) => (
						<div
							key={tier.name}
							className="col-span-3 sm:col-span-1 flex flex-col rounded-md shadow-lg"
						>
							<div className="px-6 py-8 bg-white sm:p-4 sm:pb-6 w-full">
								<div className=" w-full">
									<h3 className="px-2 py-1 rounded-full text-sm font-semibold uppercase flex justify-center bg-gray-100 text-primary-400">
										{tier.name}
									</h3>
								</div>
								<div className="mt-4 flex justify-center items-baseline text-4xl font-extrabold">
									${tier.price}
								</div>
							</div>
							<div className="flex flex-col items-center p-2">
								<Button
									text="Select"
									color={ButtonColors.primary}
									onClick={() => purchase(tier.value)}
									disabled={purchasing}
									loading={purchasing}
								/>

								<div className="rounded-md shadow"></div>
							</div>
						</div>
					))}
				</div>
			</div>
		</Modal>
	);
};

export default SponsoredListingModal;
