import { useContext } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { MeContext } from '../../../common/contexts/me.context';
import blogService from '../../../services/blog.service';
import { Blog } from '../../../types/Blog.type';

const getBlogQuery = (id: string, accountId?: number) => {
	return async (context: QueryFunctionContext) => {
		if (typeof accountId !== 'number') {
			return;
		}
		return blogService.findOne(id);
	};
};

const useBlog = (id: string) => {
	const { me } = useContext(MeContext);
	const { status, data, error, isFetching, refetch } = useQuery<{
		data: Blog;
	}>(['blog', id], getBlogQuery(id, me.account.id), {
		refetchOnWindowFocus: false,
	});

	return {
		status,
		data,
		error,
		isFetching,
		refetch,
	};
};

export default useBlog;
