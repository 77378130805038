import React, { ReactNode, useEffect, useState } from 'react';
import { Autocomplete } from './AutocompleteZip';
import useZipcodes from '../hooks/useZipcodes';

type Props = {
	value?: any;
	patchValue?: any;
	removeSelect?: any;
	resultLabel: string;
	children: ReactNode;
};

export const SelectZip = ({
	patchValue,
	resultLabel,
	children,
	value,
}: Props) => {
	const {
		data,
		isFetching,
		value: zipValue,
		onChange: onZipChange,
	} = useZipcodes();

	const [filteredData, setFilteredData] = useState(data?.pages?.[0]?.data);
	useEffect(() => {
		if (data?.pages?.[0]) {
			const existingZips = [
				...(value.targetingValue || []),
				...(value.excludezip || []),
			];

			let dataForFilter = data.pages[0] as any;
			const newData = dataForFilter.filter(
				(item: any) => !existingZips.includes(item.zip)
			) as any;

			setFilteredData(newData);
		}
	}, [value, data]);

	return (
		<div>
			<Autocomplete
				options={filteredData}
				onChange={patchValue}
				name="zip"
				isFetching={!!isFetching}
				hasNextPage={false}
				onChangeSearch={onZipChange('zip')}
				searchTerm={zipValue.zip}
			/>
			<label
				htmlFor="zipcodes"
				className="text-sm font-medium block leading-5 sm:mt-px sm:pt-2 text-gray-900"
			>
				{resultLabel}
			</label>
			<div
				className="border-gray-300 border-2 rounded-md sm:text-sm sm:leading-5 w-full bg-white mt-2 overflow-y-auto"
				style={{
					height: React.Children.count(children) > 0 ? '2.5rem' : 'auto',
				}}
				id="zipcodes"
			>
				{children}
			</div>
		</div>
	);
};
