import { Popover } from "@headlessui/react";
import React, { useState } from "react";
import { usePopper } from "react-popper";
import UserDropdown from "./UserDropdown";

const User = () => {
  let [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  let [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "right-end",
  });

  const closeDropdown = () => {
    referenceElement?.click();
  };

  return (
    <Popover className="w-full">
      <Popover.Button
        ref={setReferenceElement}
        className="block mx-auto h-10 w-10 focus:outline-none"
        data-cy="logout"
      >
        <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
          <svg
            className="h-full w-full text-gray-200 hover:text-gray-100 bg-gray-600 hover:bg-gray-500"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      </Popover.Button>
      <Popover.Panel
        className="z-10"
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <UserDropdown closeDropdown={closeDropdown} />
      </Popover.Panel>
    </Popover>
  );
};

export default User;
