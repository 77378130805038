import React, { ReactNode } from 'react';

type Props = {
	index: number;
	newItem?: boolean;
	children?: ReactNode;
};

const ListItem: React.FC<Props> = ({ children, index, newItem = false }) => {
	return (
		<li
			className={[
				'relative pl-4 pr-6 py-5 sm:py-6 sm:pl-6',
				index % 2
					? newItem
						? 'bg-primary-100 hover:bg-primary-300'
						: 'bg-gray-100 hover:bg-gray-200'
					: newItem
					? 'bg-primary-200 hover:bg-primary-300'
					: 'bg-gray-50 hover:bg-gray-200',
			].join(' ')}
		>
			{children}
		</li>
	);
};

export default ListItem;
