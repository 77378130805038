import React from 'react';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import EndOfList from '../list/EndOfList';

type Props = {
	open: boolean;
	options: any[];
	selected?: { [key: number]: any };
	toggleSelected: (i: number) => void;
	placeholder?: JSX.Element;
	valueProp?: string;
	textProp?: string;
	isFetchingNextPage?: boolean;
	fetchNextPage?: (options?: FetchNextPageOptions | undefined) => Promise<
		InfiniteQueryObserverResult<
			{
				data: any[];
				lastKey?: string;
				skip?: number;
			},
			unknown
		>
	>;
	hasNextPage?: boolean;
	endOfList?: boolean;
};

export const Options: React.FC<Props> = ({
	open,
	options,
	selected,
	toggleSelected,
	placeholder,
	valueProp = 'value',
	textProp = 'text',
	isFetchingNextPage,
	fetchNextPage,
	hasNextPage,
	endOfList = true,
}) => {
	return (
		<div
			className="mt-1 w-full rounded-md bg-white shadow-lg z-10"
			hidden={!open}
		>
			<ul
				tabIndex={1}
				role="listbox"
				aria-labelledby="listbox-label"
				aria-activedescendant="listbox-item-3"
				className="max-h-60 rounded-md py-1 text-base leading-6 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm sm:leading-5"
			>
				{placeholder && !options.length ? (
					<li className="cursor-default select-none relative py-2 text-white">
						{placeholder}
					</li>
				) : (
					options.map((option, i: number) => (
						<li
							key={option[valueProp]}
							id="listbox-option-0"
							role="option"
							aria-selected={(selected || [])[i]}
							className={[
								'cursor-default select-none relative py-2 pl-3 pr-9 text-gray-900 hover:bg-gray-100 flex gap-2',
								(selected || [])[i] ? 'text-bold' : '',
							].join(' ')}
							onClick={() => {
								toggleSelected(i);
							}}
						>
							{(selected || [])[i] ? (
								<span className="inset-y-0 flex items-center text-primary-500 hover:text-white">
									<svg
										className="h-5 w-5"
										viewBox="0 0 20 20"
										fill="currentColor"
									>
										<path
											fillRule="evenodd"
											d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
											clipRule="evenodd"
										/>
									</svg>
								</span>
							) : (
								<div className="inset-y-0 flex h-5 w-5" />
							)}
							<span
								className={[
									'block truncate',
									(selected || [])[i] ? 'font-semibold' : 'font-normal',
								].join(' ')}
							>
								{option[textProp]}
							</span>
						</li>
					))
				)}
				{endOfList && (
					<EndOfList
						isFetching={!!isFetchingNextPage}
						isFetchingNextPage={!!isFetchingNextPage}
						fetchNextPage={fetchNextPage}
						hasNextPage={hasNextPage}
						small
						align="start"
					/>
				)}
			</ul>
		</div>
	);
};
