import { ArrowsExpandIcon, TrashIcon } from '@heroicons/react/outline';
import React, { forwardRef } from 'react';
import ReactPlayer from 'react-player';
import { UploadStatus } from '../pages/products/Product.type';
import { ErrorIcon } from './form/ErrorIcon';
import Loading from './Loading';

type Props = {
	image: any;
	attributes?: any;
	listeners?: any;
	style?: any;
	ref?: any;
	isDragging?: boolean;
	onClickDelete?: (url: string) => void;
	extraIcons?: JSX.Element;
};
const ImageUploaderImage: React.FC<Props> = forwardRef(
	(
		{
			image,
			attributes,
			listeners,
			style,
			isDragging,
			onClickDelete,
			extraIcons,
		},
		ref
	) => {
		return (
			<div
				ref={ref}
				{...attributes}
				className="col-span-1"
				style={{ ...style, cursor: 'default' }}
			>
				<div className="space-y-4">
					<div className="aspect-w-3 aspect-h-2 object-cover">
						<div className="bg-white object-cover hover-trigger">
							{image.url.toLowerCase().includes('mov') ? (
								<ReactPlayer url={image.url} />
							) : (
								<img
									className={[
										'object-cover w-full h-full rounded-lg shadow-lg',
										image.uploadStatus === UploadStatus.new ||
										image.uploadStatus === UploadStatus.uploading
											? 'opacity-50'
											: image.uploadStatus === UploadStatus.failed
											? 'opacity-25'
											: '',
									].join(' ')}
									src={image.preview || image.url}
									alt=""
									draggable={false}
								/>
							)}

							{image.uploadStatus === UploadStatus.new ||
							image.uploadStatus === UploadStatus.uploading ? (
								<Loading className="absolute left-2 top-2" />
							) : (
								<>
									<ArrowsExpandIcon
										className={[
											'absolute left-2 top-2 w-5 h-5 text-white',
											isDragging
												? 'cursor-grabbing ring-2 ring-offset-2 ring-primary-500 shadow-xl'
												: 'cursor-grab shadow-sm',
										].join(' ')}
										{...listeners}
									/>
								</>
							)}
							{image.uploadStatus === UploadStatus.failed && (
								<ErrorIcon className="absolute left-2 top-2" />
							)}
							{extraIcons}
							<TrashIcon
								className="absolute right-2 top-2 w-5 h-5 text-red-500 cursor-pointer"
								onClick={onClickDelete?.bind(null, image.url)}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
);

export default ImageUploaderImage;
