import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

import useProducts from './hooks/useProducts';
import { Me, MeContext } from '../../common/contexts/me.context';
import { Product } from './Product.type';
import { PLAN_LIMITS } from '../../constants';

import Button, { ButtonColors } from '../../common/Button';
import { Checkbox } from '../../common/form/Checkbox';
import Header from '../../common/Header';
import Loading from '../../common/Loading';
import MobileSubscriptionAlert from '../../common/alerts/MobileSubscriptionAlert';
import ProductListing from '../../common/ProductListing';
import WarningAlert from '../../common/alerts/WarningAlert';
import { ToastContext, ToastTypes } from '../../common/contexts/toast.context';
import Modal, { ModalSizes } from '../../common/Modal';
import { OpenClosedStates } from '../../common/OpenClosedStates';
import * as accountService from '../../services/account.service';

const ProductList: React.FC = () => {
    const { me, updateMe } = useContext(MeContext);
    const { createToast } = useContext(ToastContext);
    const navigate = useNavigate();

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        showInactive,
        onSearch,
        onShowInactive,
        onSortBy,
        sortBy,
    } = useProducts();

    const [pages, setPages] = useState<number[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [dataPages, setDataPages] = useState<any>({});
    const [alertUp, setAlertUp] = useState<boolean>(false);
    const [modalState, setModalState] = useState<OpenClosedStates>(OpenClosedStates.Closed);
    const [modalContent, setModalContent] = useState<{ title: string; description: string; buttonText: string }>({
        title: '',
        description: '',
        buttonText: '',
    });

    useEffect(() => {
        if (data) {
            const temp = [];
            const totalPages = Math.ceil(data.pages?.[0].metaData?.totalRecords / 10);
            for (let i = 1; i <= totalPages; i++) {
                temp.push(i);
            }
            setPages(temp);
        }
    }, [currentPage, data, dataPages]);

    const limitReached = useMemo(() => {
        if (me?.account?.newPlan) {
            if (me?.account?.subscription?.options?.unlimitedListings) {
                return false;
            } else {
                return (data?.pages?.[0]?.metaData?.allRecords || 0) >= 5;
            }
        } else {
            return (
                (data?.pages?.[0]?.metaData?.allRecords || 0) >=
                PLAN_LIMITS[me?.account?.subscription?.plan].products
            );
        }
    }, [data, me]);

    useEffect(() => {
        const screen = window.screen;
        if (
            limitReached &&
            screen.width < 640 &&
            (data?.pages?.[0]?.metaData?.allRecords || 0) >= 5
        ) {
            setAlertUp(true);
        }
    }, [limitReached]);

    useEffect(() => {
        if (!me.account.setupComplete) {
            if ((data?.pages?.[0]?.data.length || 0) === 0) {
                setModalContent({
                    title: 'Start Adding Products',
                    description: 'You can start adding products now.',
                    buttonText: 'Start adding products',
                });
            } else {
                setModalContent({
                    title: 'Add Another Product',
                    description: 'Would you like to add another product?',
                    buttonText: 'Add product',
                });
            }
            setModalState(OpenClosedStates.Open);
        }
    }, [me.account.setupComplete, data]);

const handleUpdateAccount = async (accountUpdates: Partial<Me['account']>) => {
    try {
        await accountService.update({ account: { ...me.account, ...accountUpdates } });
        
        updateMe('account', { ...me.account, ...accountUpdates });
        
    } catch (error) {
        console.error('Failed to update account:', error);
    }
};

const handleAddProducts = () => {
    setModalState(OpenClosedStates.Closed);
    handleUpdateAccount({ setupComplete: true });
    checkLocationsAndNavigate();
};

const handleAddProductsLater = () => {
    setModalState(OpenClosedStates.Closed);
    handleUpdateAccount({ setupComplete: true });
    navigate('/account-settings');
};

    const checkLocationsAndNavigate = () => {
        if (!me?.account?.locations?.length) {
            createToast({
                type: ToastTypes.Fail,
                title: 'No Locations Found',
                duration: 5000,
                description: (
                    <>
                        You can only add a product after adding a location.{' '}
                        <a 
                            href="#"
                            className="text-blue-500 underline"
                            onClick={() => navigate('/account-settings/locations')}
                        >
                            Click here to add a location.
                        </a>
                    </>
                ),
            });
        } else {
            navigate('/products/new');
        }
    };

    const clickAnalytics = me?.account?.subscription?.options?.clickAnalytics;

    return alertUp ? (
        <MobileSubscriptionAlert
            title="Uh oh!"
            subtitle="Looks like you've reached your maximum amount of listings. Would you like to update your plan?"
            acceptLink="/billing"
            acceptMessage="I'd like to update my membership to include unlimited listings."
            rejectMessage="No thanks"
            close={() => setAlertUp(false)}
            icon={<ExclamationCircleIcon className="h-12 w-12 text-black" />}
        />
    ) : (
        <>
            <Header
                title="Products"
                className="pb-6"
                rightSide={
                    <>
                        <div className="mr-4">
                            <span className="sm:text-sm mr-2">Sort By:</span>
                            <select
                                className="form-select h-full py-0 pl-3 pr-6 border-transparent bg-transparent sm:text-sm sm:leading-5 w-28"
                                onChange={(e) => onSortBy(e.target.value)}
                                value={sortBy}
                            >
                                <option value="newest">Newest</option>
                                <option value="oldest">Oldest</option>
                            </select>
                        </div>
                        <Button
                            text="Add product"
                            disabled={limitReached}
                            onClick={checkLocationsAndNavigate}
                        />
                    </>
                }
            >
                <div className="w-full sm:w-1/2 pt-2">
                    <label htmlFor="search" className="sr-only">
                        Search
                    </label>
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <svg
                                className="h-5 w-5 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                        <input
                            id="search"
                            name="search"
                            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white shadow-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                            placeholder="Search"
                            type="search"
                            onChange={(e) => onSearch(e.target.value)}
                        />
                    </div>
                    <div className="mt-2 flex items-center">
                        <Checkbox label="Show inactive" value={Boolean(showInactive)} onChange={(val) => onShowInactive(val)} />
                    </div>
                </div>
            </Header>

            <div className="p-6">
                {limitReached && (
                    <WarningAlert>
                        Subscription limit met. You can only have 5 products with your
                        current subscription.&nbsp;
                        <Link
                            to="/billing"
                            className="font-medium underline text-yellow-700 hover:text-yellow-600"
                        >
                            Upgrade your account to add more products.
                        </Link>
                    </WarningAlert>
                )}
            </div>

            <div className="py-4">
                {isFetching && !isFetchingNextPage ? (
                    <Loading
                        color="text-primary-500 w-full mx-auto"
                        className="col-span-4"
                    />
                ) : (
                    <InfiniteScroll
                        next={fetchNextPage}
                        hasMore={!!hasNextPage}
                        loader={<Loading color="text-primary-500 w-full mx-auto" className="col-span-4" />}
                        dataLength={
                            data?.pages.reduce((total, page) => total + page.data.length, 0) || 0
                        }
                    >
                        {data?.pages?.[0].data.length
                            ? (
                                <ul className="px-6 space-y-12 sm:grid sm:grid-cols-2 md:grid-cols-3 gap-6 sm:space-y-0 xl:grid-cols-4 2xl:grid-cols-5 w-full">
                                    {data?.pages.map((page, i) =>
                                        page?.data.map((product: Partial<Product>) => (
                                            <ProductListing key={product.id} product={product} clickAnalytics={clickAnalytics} />
                                        ))
                                    )}
                                </ul>
                            ) : (
                                <p className="text-lg text-center">No products to show.</p>
                            )
                        }

                        <div className="px-4 pt-6 text-center">
                            {hasNextPage
                                ? (isFetchingNextPage
                                    ? (
                                        <Loading color="text-primary-500 w-full mx-auto" className="col-span-4" />
                                    ) : (
                                        <Button text="Load More" fullWidth onClick={(e) => fetchNextPage()} />
                                    )
                                ) : (data?.pages.length && data?.pages?.length > 1) && (
                                    <p className="text-lg">No more products.</p>
                                )
                            }
                        </div>
                    </InfiniteScroll>
                )}
            </div>

            <Modal
                state={modalState}
                size={ModalSizes.md}
                close={() => setModalState(OpenClosedStates.Closed)}
            >
                <div>
                    <h2 className="text-lg font-bold">{modalContent.title}</h2>
                    <p className="mt-2 text-gray-600">{modalContent.description}</p>
                    <div className="mt-4 flex justify-end space-x-4">
                        <Button
                            text={modalContent.buttonText}
                            onClick={handleAddProducts}
                        />
                        <Button
                            text="Add products later"
                            onClick={handleAddProductsLater}
                            color={ButtonColors.plain}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ProductList;
