import React from 'react';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import Header from '../../../common/Header';
import LoadingPage from '../../../common/LoadingPage';
import UserContainer from '../../users/detail/UserContainer';
import UserList from '../../users/UserList';
import useAccount from '../hooks/useAccount';
import AccountGeneral from './AccountGeneral';
import AccountLocations from './AccountLocations';
import AccountSettingsAPIKeys from '../../account-settings/AccountSettingsAPIKeys';
import AccountTabs from './AccountTabs';

const AccountContainer = () => {
	const { accountId } = useParams();
	const { pathname } = useLocation();
	const { isFetching, data, save, refetch } = useAccount(accountId);
	const hasApiAccess = data?.subscription?.options?.apiAccess;
	const updateLocations = () => {
		refetch();
	};

	const externalProvidersInfo = {
		apiKey: data?.apiKey,
		customerShedSuiteId: data?.customerShedSuiteId,
		customerShedAppUrl: data?.customerShedAppUrl,
	};

	const isNewAccount = pathname.includes('/accounts/new');

	return isFetching ? (
		<LoadingPage />
	) : (
		<>
			<Header title={data?.id ? data?.name : 'New account'}>
				{!isNewAccount && (
					<AccountTabs active={pathname} accountId={accountId as string} />
				)}
			</Header>
			<Routes>
				<Route
					path="/"
					element={<AccountGeneral defaultValues={data} save={save} />}
				/>
				<Route
					path="locations"
					element={
						<div className="px-6 pt-6 bg-gray-50 h-full">
							<AccountLocations
								locations={data?.locations}
								accountId={accountId as string}
								hasApiAccess={hasApiAccess}
								path={data?.path}
								accountLocations={
									(data?.subscription?.options?.additionalLocations || 0) + 1
								}
								externalProvidersInfo={externalProvidersInfo}
								updateLocations={updateLocations}
							/>
						</div>
					}
				/>
				<Route
					path="users"
					element={
						<UserList accountId={Number(accountId)} isFromAccount={true} />
					}
				/>
				<Route
					path="users/:userId"
					element={
						<UserContainer padding={false} accountId={Number(accountId)} />
					}
				/>
				<Route
					path="api-keys"
					element={
						<AccountSettingsAPIKeys
							userData={data}
							isFetchingUserData={isFetching}
							save={save}
						/>
					}
				/>
			</Routes>
		</>
	);
};

export default AccountContainer;
