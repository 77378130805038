import { Product } from '../pages/products/Product.type';
import * as ShedsAPI from '../common/helpers/ShedsAPI';

export const list = async (
	accountId: number,
	params: {
		search?: string;
		limit?: number;
		showInactive?: number;
		skip?: number;
		sortBy?: string;
	}
) => {
	return ShedsAPI.get('api', `/${accountId}/products`, {
		queryStringParameters: params,
	});
};

export const get = ({
	accountId,
	productId,
}: {
	accountId: number;
	productId: number;
}): Promise<Product> => {
	return ShedsAPI.get('api', `/${accountId}/products/${productId}`, {});
};

export const update = ({
	accountId,
	product,
}: {
	accountId: number;
	product: Partial<Product>;
}): Promise<Product> => {
	const { id, ...restOfProduct } = product;
	return ShedsAPI.put('api', `/${accountId}/products/${id}`, {
		body: restOfProduct,
	});
};

export const zipcodeList = async (params: any): Promise<any> => {
	const res = await ShedsAPI.get('api', `/zipcodes`, {
		queryStringParameters: params,
	});
	return res;
};

export const del = async (
	accountId: number,
	productId: string,
	sold: boolean
) => {
	return ShedsAPI.del('api', `/${accountId}/products/${productId}`, {
		body: { sold },
	});
};

export const mark = async (
	accountId: number,
	productId: string,
	value: boolean
) => {
	return ShedsAPI.put('api', `/${accountId}/products/${productId}/mark`, {
		body: { value },
	});
};

export const create = async ({
	accountId,
	product,
}: {
	accountId: number;
	product: Partial<Product>;
}): Promise<Product> => {
	const res = await ShedsAPI.post('api', `/${accountId}/products`, {
		body: product,
	});
	return res;
};

export const save = ({
	accountId,
	product,
}: {
	accountId: number;
	product: Partial<Product>;
}): Promise<Product> => {
	const params = { accountId, product };
	return product.id ? update(params) : create(params);
};

export const clone = ({
	accountId,
	productId,
}: {
	accountId: number;
	productId: number;
}): Promise<Product> => {
	return ShedsAPI.post('api', `/${accountId}/products/${productId}/clone`, {});
};
