import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { ErrorResponse } from '../../../common/types/ErrorResponse.type';
import * as accountService from '../../../services/account.service';
import { Account } from '../Account.type';

const getAccountQuery = (accountId: number | 'new') => {
	return async (context: QueryFunctionContext) => {
		if (accountId === 'new') {
			return {} as any;
		}
		return accountService.get({ accountId });
	};
};

const useAccount = (id?: string) => {
	const queryClient = useQueryClient();
	const { data, isFetching, refetch, remove, isFetched } = useQuery<
		any,
		ErrorResponse
	>(['account', id], getAccountQuery(id === 'new' ? id : Number(id)), {
		refetchOnWindowFocus: false,
		retry: false,
	});

	const save = async (val: Partial<Account>) => {
		const res = await accountService.save({
			account: val,
		});
		queryClient.setQueryData(['account', id], res);
		return res;
	};

	return {
		isFetching,
		data,
		save,
		refetch,
		remove,
		isFetched,
	};
};

export default useAccount;
