import { Blog } from '../types/Blog.type';
import * as ShedsAPI from '../common/helpers/ShedsAPI';

const blogService = () => {
	const list = async (params: {
		skip?: number;
		limit?: number;
		search?: string;
	}) => {
		return ShedsAPI.get('api', `/blog`, { queryStringParameters: params });
	};

	const findOne = async (id: string) => {
		return ShedsAPI.get('api', `/blog/${id}`, {});
	};

	const update = async (article: Partial<Blog>) => {
		const { id, ...restOfArticle } = article;
		return ShedsAPI.put('api', `/blog/${id}`, { body: restOfArticle });
	};

	const create = async (article: Partial<Blog>) => {
		const res = await ShedsAPI.post('api', `/blog`, { body: article });
		return res;
	};

	const save = async (article: Partial<Blog>) => {
		if (article.id) {
			return update(article);
		} else {
			return create(article);
		}
	};

	const blogDelete = async (id?: number) => {
		if (!id) {
			return;
		}
		return ShedsAPI.put('api', `/blog/delete/${id}`, {});
	};

	return {
		list,
		findOne,
		save,
		blogDelete,
	};
};

export default blogService();
