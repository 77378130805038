import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../common/Header';
import { formatPhone } from '../../common/helpers/format';
import List from '../../common/list/List';
import ListItem from '../../common/list/ListItem';
import { User } from './User.type';
import useUsers from './hooks/useUsers';
import Button, { ButtonColors } from '../../common/Button';
import Modal, { ModalSizes } from '../../common/Modal';
import { OpenClosedStates } from '../../common/OpenClosedStates';
import { MeContext } from '../../common/contexts/me.context';

type Props = {
    accountId?: number;
    isFromAccount?: boolean;
};

const UserList: React.FC<Props> = ({ accountId, isFromAccount }) => {
    const { me } = useContext(MeContext);
    const navigate = useNavigate();
    const [modalState, setModalState] = useState<OpenClosedStates>(OpenClosedStates.Closed);

    const {
        data,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        onSearch,
    } = useUsers(accountId);

    useEffect(() => {
        if (!me.account.setupComplete) {
            setModalState(OpenClosedStates.Open);
        }
    }, [me.account.setupComplete]);

    const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        onSearch(val);
    };

    const handleAddUsers = () => {
        setModalState(OpenClosedStates.Closed);
        navigate('users/new');
    };

    const handleSkipToProducts = () => {
        setModalState(OpenClosedStates.Closed);
        navigate('/products');
    };

    const renderUserAvatar = (avatar: string | undefined) => {
        if (avatar) {
            const src = avatar.includes('http') ? avatar : `${process.env.REACT_APP_S3_URL}/${avatar}`;
            return <img className="flex-shrink-0 rounded-full h-12 w-12" src={src} alt="avatar" />;
        }
        return (
            <svg
                className="flex-shrink-0 w-12 h-12 text-gray-400 group-hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="2 2 16 16"
                fill="currentColor"
            >
                <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                    clipRule="evenodd"
                />
            </svg>
        );
    };

    const renderUserStatus = (isActive: boolean) => {
        if (!isActive) {
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 ml-1 text-red-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                        clipRule="evenodd"
                    />
                </svg>
            );
        }
        return null;
    };

    return (
        <>
            <Header
                title="Users"
                className="pb-6"
                rightSide={
                    <Link to={isFromAccount ? 'new' : 'users/new'}>
                        <Button text="Add user" />
                    </Link>
                }
            >
                <div className="w-full sm:w-1/2 pt-2">
                    <label htmlFor="search" className="sr-only">
                        Search
                    </label>
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <svg
                                className="h-5 w-5 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                        <input
                            id="search"
                            name="search"
                            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white shadow-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                            placeholder="Search"
                            type="search"
                            onChange={onChangeSearch}
                        />
                    </div>
                </div>
            </Header>
            <Modal
                state={modalState}
                size={ModalSizes.md}
                close={handleSkipToProducts}
            >
                <div>
                    <h2 className="text-lg font-bold">Add User(s)</h2>
                    <p className="mt-2 text-gray-600">Would you like to add more users now?</p>
                    <div className="mt-4 flex justify-end space-x-4">
                        <Button onClick={handleAddUsers} text="Add user" />
                        <Button text="Skip to Products" onClick={handleSkipToProducts} color={ButtonColors.plain} />
                    </div>
                </div>
            </Modal>
            <List
                isFetching={isFetching}
                isFetchingNextPage={!!isFetchingNextPage}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
            >
                {data?.pages?.map((page, i) => (
                    <React.Fragment key={i}>
                        {page?.data?.map((user: User & { $new?: boolean }, index) => (
                            <Link to={`${user.id}`} key={user.id}>
                                <ListItem index={index} newItem={user.$new}>
                                    <div className="flex items-center justify-between space-x-4">
                                        <div className="min-w-0">
                                            <div className="relative group flex items-center space-x-4">
                                                {renderUserAvatar(user.avatar)}
                                                <div className="space-y-3">
                                                    <div className="flex items-center space-x-3">
                                                        <span className="block">
                                                            <h2 className="text-sm font-medium leading-4">
                                                                {user.lastName}, {user.firstName}
                                                                {renderUserStatus(user.active)}
                                                            </h2>
                                                        </span>
                                                    </div>
                                                    <div className="text-sm leading-4 text-gray-500 group-hover:text-gray-900 font-medium truncate">
                                                        {user.email}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sm:hidden">
                                            <svg
                                                className="h-5 w-5 text-gray-400"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </div>
                                        <div className="hidden sm:flex flex-col flex-shrink-0 items-end space-y-3">
                                            <p className="flex items-center space-x-4">
                                                <span className="relative text-sm leading-4 text-gray-500 hover:text-gray-900 font-medium">
                                                    {user.email}
                                                </span>
                                            </p>
                                            <p className="flex text-gray-500 text-sm leading-4 space-x-2">
                                                <span>{user.phone ? formatPhone(user.phone) : ''}</span>
                                            </p>
                                        </div>
                                    </div>
                                </ListItem>
                            </Link>
                        ))}
                    </React.Fragment>
                ))}
            </List>
        </>
    );
};

export default UserList;
