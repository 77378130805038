import React from 'react';
import { SubOptions } from '../../pages/accounts/Account.type';
import { Plan } from '../../pages/billing/Billing';
import { SelectOption } from '../form/Autocomplete';
import { ValueOf } from '../types/ValueOf.type';

export type Subscription = {
	plan: Plan;
	locations: number;
	paymentMethod: any;
	options?: SubOptions;
	currSubscriber?: boolean;
	bailedOptions?: Partial<SubOptions>;
	newPlan?: boolean;
};

export type Me = {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
	role: string;
	account: {
		id?: number;
		address?: string;
		apiKey?: string;
		customerShedAppUrl?: string;
		customerShedSuiteId?: number;
		name?: string;
		type: 'Dealer' | 'Manufacturer' | 'SuperAdmin';
		pendingManufacturer?: boolean;
		manufacturer?: number;
		manufacturers: SelectOption[];
		setupComplete: boolean;
		locations: Location[];
		freeDeliveryRange: number;
		deliveryInstructions: string;
		warranty?: string;
		path?: string;
		subscriptionId?: string;
		stripeCustomerId?: string;
		subscription: Subscription;
		colors?: any[];
		newPlan?: boolean;
		corporatePlan?: boolean;
	};
};

export const MeContext = React.createContext<{
	me: Me;
	updateMe: (key: keyof Me, value: ValueOf<Me>) => void;
	replaceMe: (newMe: Me) => void;
}>({
	me: {
		id: 0,
		firstName: '',
		lastName: '',
		email: '',
		role: 'user',
		account: {
			manufacturers: [],
			locations: [],
			subscription: {
				plan: 'essentials',
				locations: 1,
				paymentMethod: 'card',
				options: {
					additionalLocations: 0,
					unlimitedListings: false,
					unlimitedImages: false,
					clickAnalytics: false,
					dealerDirectPrivacy: false,
					corporatePlan: false
				},
			},
			setupComplete: false,
			type: 'Dealer',
			freeDeliveryRange: 100,
			deliveryInstructions: '',
			warranty: '',
			newPlan: false,
			corporatePlan: false,
		},
	},
	updateMe: () => {},
	replaceMe: () => {},
});
