import {
	DesktopComputerIcon,
	HomeIcon,
	MailIcon,
	SearchIcon,
	UsersIcon,
} from '@heroicons/react/outline';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../common/Header';
import LoadingPage from '../../common/LoadingPage';
import useStats from './hooks/useStats';

const icons: any = {
	home: HomeIcon,
	computer: DesktopComputerIcon,
	mail: MailIcon,
	search: SearchIcon,
	user: UsersIcon,
};

const Dashboard = () => {
	const { isFetching, data } = useStats();

	const dataWithIcons = useMemo(() => {
		return data?.map((item: any) => {
			return {
				...item,
				stats: item.stats?.map((stat: any) => {
					return {
						...stat,
						icon: icons[stat.icon],
					};
				}),
			};
		});
	}, [data]);

	return isFetching ? (
		<LoadingPage />
	) : (
		<>
			<Header title="Dashboard" />
			<div className={['bg-gray-50 h-full pt-6 px-6'].join(' ')}>
				{dataWithIcons?.map((group: any) => (
					<div key={group.name}>
						<h3 className="text-lg leading-6 font-medium text-gray-900">
							{group.name}
						</h3>
						<dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3">
							{group.stats?.map((item: any) => (
								<div
									key={item.name}
									className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
								>
									<dt>
										<div className="absolute bg-primary-500 rounded-md p-3">
											<item.icon
												className="h-6 w-6 text-white"
												aria-hidden="true"
											/>
										</div>
										<p className="ml-16 text-sm font-medium text-gray-500 truncate">
											{item.name}
										</p>
									</dt>
									<dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
										<p className="text-2xl font-semibold text-gray-900">
											{item.stat}
										</p>
										<div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
											<div className="text-sm">
												{!!item.link && (
													<Link
														to={item.link.to}
														className="font-medium text-primary-600 hover:text-primary-500"
													>
														{item.link.text}
													</Link>
												)}
											</div>
										</div>
									</dd>
								</div>
							))}
						</dl>
					</div>
				))}
			</div>
		</>
	);
};

export default Dashboard;
