import React, { useContext, useEffect, useState } from 'react';
import { MeContext } from '../../common/contexts/me.context';
import ConfirmModal from '../../common/ConfirmModal';
import AdRequestDetailsModal from './AdRequestDetailsModal';
import Header from '../../common/Header';
import List from '../../common/list/List';
import useRequestedAds from './hooks/useRequestedAds';
import { OpenClosedStates } from '../../common/OpenClosedStates';
import ListItem from '../../common/list/ListItem';
import { upperCaseFirstLetter, isJson } from '../../helpers';
import PillBox from '../../common/form/PillBox';
import { useLocation } from 'react-router';
import { ToastContext, ToastTypes } from '../../common/contexts/toast.context';
import Tabs from '../../common/Tabs';
const dayjs = require('dayjs');

export type AdRequestMetrics = {
	views?: number;
	clicks?: number;
};

export type AdRequestForm = {
	id: number;
	firstName: string;
	lastName: string;
	companyName: string;
	contactEmail: string;
	contactPhone?: string;
	image?: string;
	adType: 'contactForm' | 'link';
	adLink?: string;
	adContactFormEmail?: string;
	excluded?: boolean;
	excludezip?: any;
	targeted?: boolean;
	targetingType?:
		| 'nationWide'
		| 'state'
		| 'zipCodeList'
		| 'cityRadius'
		| 'dealers'
		| 'manufacturers';
	targetingValue?: any;
	adTier: string;
	invoiced?: boolean;
	invoicePaid?: boolean;
	approved?: boolean;
	activeUntil?: string;
	active?: boolean;
	activeTimeInMonths: number;
	invoicedDate?: string;
};

const AdRequest = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get('id');
	const { me } = useContext(MeContext);
	const {
		isFetching,
		data,
		hasNextPage,
		isFetchingNextPage,
		invoice,
		fetchNextPage,
		update,
	} = useRequestedAds();
	const [confirmModalState, setConfirmModalState] = useState<OpenClosedStates>(
		OpenClosedStates.Closed
	);
	const [currRequestId, setCurrRequestId] = useState<number>();
	const [currRequest, setCurrRequest] = useState<Partial<AdRequestForm>>();
	const [adRequestDetailsModalState, setAdRequestDetailsModalState] =
		useState<OpenClosedStates>(OpenClosedStates.Closed);
	const { search } = useLocation();
	const { createToast } = useContext(ToastContext);
	const [invoicing, setInvoicing] = useState<boolean>(false);
	const [requestList, setRequestList] = useState<any>();
	const { pathname } = useLocation();

	useEffect(() => {
		const requests = data?.pages?.map((page: any) => {
			return {
				data: page.data?.map((row: any) => {
					if (
						row.targetingType === 'state' ||
						row.targetingType === 'cityRadius'
					) {
						if (isJson(row.targetingValue)) {
							const parsed = JSON.parse(row.targetingValue);
							if (parsed) {
								row.targetingValue = parsed;
							}
						}

						if (isJson(row.excludezip)) {
							const parsed = JSON.parse(row.excludezip);
							if (parsed) {
								row.excludezip = parsed;
							}
						}
					}
					return row;
				}),
				metadata: page.metaData,
			};
		});
		setRequestList(requests);
	}, [data]);

	useEffect(() => {
		if (id) {
			const requestId = parseInt(id, 10);
			const matchingRequest: any = data?.pages?.reduce(
				(accum: any, page: any) => {
					const hello = page?.data?.find((row: any) => row.id === requestId);
					accum = hello;
					return accum;
				},
				{}
			);
			if (matchingRequest) {
				setCurrRequest(matchingRequest);
				setAdRequestDetailsModalState(OpenClosedStates.Open);
			}
		}
	}, [data?.pages, id]);

	useEffect(() => {
		if (search) {
			const requestId = search.split('=').pop();
			if (requestId) {
				const matchingRequest: any = data?.pages?.reduce(
					(accum: any, page: any) => {
						const hello = page?.data?.find(
							(row: any) => row.id === Number(requestId)
						);
						accum = hello;
						return accum;
					},
					{}
				);
				if (matchingRequest) {
					setCurrRequest(matchingRequest);
				}
			}
		}
	}, [data?.pages, search]);

	useEffect(() => {
		if (currRequest) {
			setAdRequestDetailsModalState(OpenClosedStates.Open);
		}
	}, [currRequest]);

	const invoiceRequest = async () => {
		try {
			setInvoicing(true);
			await invoice(currRequestId);
		} catch (err) {
			console.log({ err });
			setInvoicing(false);
			createToast({
				type: ToastTypes.Fail,
				title: 'Failed to invoice',
				description: <>This request was not successfully invoiced.</>,
				duration: 5000,
			});
		}
		createToast({
			type: ToastTypes.Success,
			title: 'Invoiced successfully!',
			description: <>This request was successfully invoiced.</>,
			duration: 5000,
		});
		setInvoicing(false);
		setConfirmModalState(OpenClosedStates.Closed);
	};

	const handleUpdate = async (updatedRequest: Partial<AdRequestForm>) => {
		await update(updatedRequest);

		setAdRequestDetailsModalState(OpenClosedStates.Closed);
	};

	return (
		<>
			<Header
				title={me?.account?.id === 0 ? 'Requested ads' : 'Request ads'}
				className="pb-6"
				rightSide={
					<div className="flex flex-col gap-1 items-end">
						<Tabs
							tabs={[
								{
									name: `Requested ads`,
									to: `/ad-requests`,
									active: pathname === `/ad-requests`,
								},
								{
									name: 'Display ads',
									to: `/display-ads-list`,
									active: pathname === `/display-ads-list`,
								},
							]}
						/>
					</div>
				}
			/>
			<>
				<List
					isFetching={isFetching}
					isFetchingNextPage={!!isFetchingNextPage}
					fetchNextPage={fetchNextPage}
					hasNextPage={hasNextPage}
				>
					{requestList?.map((page: any, pageI: number) => (
						<React.Fragment key={pageI}>
							{page?.data?.map(
								(row: AdRequestForm & AdRequestMetrics, rowI: number) => (
									<div
										key={rowI}
										className=" w-full overflow x-auto"
										onClick={() => setCurrRequest(row)}
									>
										<ListItem index={rowI}>
											<div className="flex flex-row items-center justify-between space-y-2  space-x-4 w-full cursor-pointer">
												<div className="min-w-0">
													<div className="relative group flex items-center ">
														<div className="flex items-center  flex-shrink w-full">
															<img
																src={row?.image}
																alt="No Ad Image chosen"
																className="w-16 h-16 object-contain"
															/>
															<div className="flex-1 flex flex-col gap-1 px-4 w-40 text-sm h-8 mb-2 md:mb-0">
																<label className="font-bold cursor-pointer">
																	Requestor
																</label>
																<h2 className="text-xs font-medium leading-4 w-full text-clip">
																	{row.firstName} {row.lastName} -{' '}
																	{row.companyName}
																</h2>
															</div>
															<div className="flex-1 flex-col gap-1 px-4 w-40 text-sm h-8 mb-2 md:mb-0 hidden md:block">
																<label className="font-bold cursor-pointer">
																	Targeting
																</label>
																<h2 className="text-xs font-medium leading-4">
																	{row.targeted && row.targetingType
																		? row.targetingType === 'cityRadius'
																			? 'City'
																			: upperCaseFirstLetter(row.targetingType)
																		: 'N/A'}
																</h2>
															</div>
															<div className="flex-1 flex-col gap-1 px-4 w-40 text-sm h-8 hidden md:block">
																<label className="font-bold cursor-pointer">
																	Views
																</label>
																<h2 className="text-xs font-medium leading-4">
																	{row.views || 0}
																</h2>
															</div>
															<div className="flex-1 flex-col gap-1 px-4 w-40 text-sm h-8 hidden md:block">
																<label className="font-bold cursor-pointer">
																	Clicks
																</label>
																<h2 className="text-xs font-medium leading-4">
																	{row.clicks || 0}
																</h2>
															</div>
														</div>
													</div>
												</div>

												<div>
													{me?.account?.id === 0 ? (
														<div className="flex flex-row overflow-x-auto flex-wrap gap-2 w-40">
															<PillBox
																className={
																	row.approved
																		? 'bg-primary-600 cursor-not-allowed focus:ring-primary-500 shadow-sm'
																		: 'bg-gray-600 focus:ring-gray-500 shadow-sm cursor-not-allowed'
																}
																text={
																	row.approved
																		? `Approved and active`
																		: 'Pending'
																}
																onClick={() => null}
																loading={false}
															/>
															{!row.invoicePaid && !row.invoiced ? (
																<PillBox
																	className={
																		row.invoiced
																			? 'bg-primary-600 cursor-none focus:ring-primary-500 shadow-sm'
																			: 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 shadow-sm cursor-pointer'
																	}
																	text="Invoice this request"
																	onClick={() => {
																		if (!row.invoiced) {
																			setCurrRequestId(row.id);
																			setConfirmModalState(
																				OpenClosedStates.Open
																			);
																		}
																	}}
																	loading={false}
																/>
															) : (
																<></>
															)}

															{row.invoiced ? (
																<PillBox
																	className={
																		row.invoicePaid
																			? 'bg-primary-600 cursor-not-allowed focus:ring-primary-500 shadow-sm'
																			: 'bg-gray-600 focus:ring-gray-500 shadow-sm cursor-not-allowed'
																	}
																	text={
																		row.invoicePaid
																			? `Invoice paid`
																			: `Invoiced ${dayjs(
																					row.invoicedDate
																			  ).format('MM/DD/YYYY')} `
																	}
																	onClick={() => null}
																	loading={false}
																	disabled
																/>
															) : (
																<></>
															)}
															{row.active ? (
																<PillBox
																	className="bg-primary-600 cursor-not-allowed focus:ring-primary-500 shadow-sm"
																	text="Active"
																	onClick={() => null}
																	loading={false}
																/>
															) : (
																<></>
															)}
														</div>
													) : (
														<div className="flex flex-row overflow-x-auto w-40">
															<PillBox
																className={
																	row.approved
																		? 'bg-primary-600 cursor-not-allowed focus:ring-primary-500 shadow-sm'
																		: 'bg-gray-600 focus:ring-gray-500 shadow-sm'
																}
																text={
																	row.approved
																		? `Approved and active`
																		: 'Pending'
																}
																onClick={() => null}
																loading={false}
															/>
															{row.invoiced ? (
																<PillBox
																	className={
																		row.invoicePaid
																			? 'bg-primary-600 cursor-not-allowed focus:ring-primary-500 shadow-sm'
																			: 'bg-gray-600 focus:ring-gray-500 shadow-sm cursor-not-allowed'
																	}
																	text={
																		row.invoicePaid
																			? `Invoice paid`
																			: `Invoiced ${dayjs(
																					row.invoicedDate
																			  ).format('MM/DD/YYYY')} `
																	}
																	onClick={() => null}
																	loading={false}
																	disabled
																/>
															) : (
																<></>
															)}
															{row.active ? (
																<PillBox
																	className="bg-primary-600 cursor-not-allowed focus:ring-primary-500 shadow-sm"
																	text="Active"
																	onClick={() => null}
																	loading={false}
																/>
															) : (
																<></>
															)}
														</div>
													)}
												</div>
											</div>
										</ListItem>
									</div>
								)
							)}
						</React.Fragment>
					))}
				</List>

				<ConfirmModal
					title="Invoice this request?"
					description="Invoicing this request will send a stripe invoice to the requestor's email. Once the requestor has paid the invoice, the requested ad will be marked as active and will be entered into the rotation."
					state={confirmModalState}
					confirm={invoiceRequest}
					close={() => setConfirmModalState(OpenClosedStates.Closed)}
					loading={invoicing}
				/>
				{currRequest ? (
					<AdRequestDetailsModal
						state={adRequestDetailsModalState}
						request={currRequest}
						close={() => {
							setCurrRequest(undefined);
							setAdRequestDetailsModalState(OpenClosedStates.Closed);
						}}
						onUpdate={handleUpdate}
					/>
				) : (
					<></>
				)}
			</>
		</>
	);
};

export default AdRequest;
