import { useCallback, useState } from 'react';
import { isEmpty } from '../helpers/isEmpty';
import { isValid } from '../helpers/isValid';
import {
	FormError,
	FormErrors,
	FormFieldErrors,
} from '../types/ErrorResponse.type';
import { ValueOf } from '../types/ValueOf.type';

export type SubmitReturnType<T, RT = void> = Promise<
	Partial<T> | Partial<RT> | undefined | Response
>;

type ReturnType<T, RT = void> = {
	value: Partial<T>;
	registerSubmit: (
		fnSubmit: (values: Partial<T>) => SubmitReturnType<T, RT>,
		// We don't know what the response looks like...we could send in a type, but not sure it is worth the effort?
		{
			onSuccess,
			onFail,
		}: { onSuccess?: (response: any) => void; onFail?: (errors: any) => void }
	) => (e?: any) => void;
	isDirty: boolean;
	isSubmitting: boolean;
	setValue: (values: Partial<T>) => void;
	patchValue: (values: Partial<T>) => void;
	patchValueClean: (values: Partial<T>) => void;
	reset: () => void;
	errors: FormErrors;
	onChange: (key: keyof T) => (val: ValueOf<T>) => void;
	setFormValue: React.Dispatch<React.SetStateAction<Partial<T>>>;
};

type FieldValidationRule<T> = {
	required?: { message: string };
	pattern?: {
		value: RegExp;
		message: string;
	};
	maxLength?: { value: number; message: string };
	minLength?: { value: number; message: string };
	function?: { value: (values: Partial<T>) => false | string };
};

export function useForm<T, RT = void>(
	initialValue: Partial<T>,
	fieldValidationRules?: {
		[key in keyof T]?: FieldValidationRule<T>;
	}
): ReturnType<T, RT> {
	const [formValue, setFormValue] = useState<Partial<T>>(initialValue);
	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [errors, setErrors] = useState<FormErrors>({});

	const updateErrors = useCallback(
		(newValues: Partial<T>) => {
			if (fieldValidationRules) {
				const validation = Object.entries(fieldValidationRules) as [
					keyof T,
					FieldValidationRule<T>
				][];
				const newErrors = validation.reduce(
					(obj: FormFieldErrors, [key, rule]) => {
						const field = key as string;
						const val = newValues[key];

						// Required
						if (rule.required && isEmpty(val)) {
							obj[field] = { message: rule.required.message };
							return obj;
						}

						// Min length
						if (
							isValid(val) &&
							rule.minLength &&
							rule.minLength.value > String(val).length
						) {
							const err =
								rule.minLength.message ||
								`This field must be at least ${rule.minLength.value} characters.`;
							obj[field] = {
								message: obj[field] ? `${obj[field]}\n${err}` : err,
							};
						}

						// Max length
						if (
							isValid(val) &&
							rule.maxLength &&
							rule.maxLength.value < String(val).length
						) {
							const err =
								rule.maxLength.message ||
								`This field must be at most ${rule.maxLength.value} characters.`;
							obj[field] = {
								message: obj[field] ? `${obj[field]}\n${err}` : err,
							};
						}

						// Regex
						if (
							isValid(val) &&
							rule.pattern?.value &&
							!rule.pattern?.value.test(String(val))
						) {
							obj[field] = {
								message:
									rule.pattern.message ||
									'This field has criteria that have not been met.',
							};
							return obj;
						}

						// Function
						if (rule.function?.value) {
							const fnVal = rule.function.value(newValues);
							if (fnVal) {
								obj[field] = {
									message: fnVal || 'Something went wrong.',
								};
								return obj;
							}
						}

						return obj;
					},
					{}
				);
				setErrors({ fieldErrors: newErrors });
				return newErrors;
			}
			return {};
		},
		[fieldValidationRules]
	);

	const onChange = (key: keyof T) => {
		return (val: ValueOf<T>) => {
			const obj: Partial<T> = {};
			obj[key] = val;
			patchValue(obj);
		};
	};

	const markAsDirty = () => {
		setIsDirty(true);
	};

	const setValue = useCallback((newValues: Partial<T>) => {
		setFormValue(newValues);
		markAsDirty();
	}, []);

	const patchValue = useCallback((values: Partial<T>) => {
		setFormValue((prev) => ({
			...prev,
			...values,
		}));
		markAsDirty();
	}, []);

	const patchValueClean = useCallback((values: Partial<T>) => {
		setFormValue((prev) => ({
			...prev,
			...values,
		}));
	}, []);

	const reset = () => {
		setFormValue(initialValue);
	};

	const registerSubmit = (
		fnSubmit: (values: Partial<T>) => SubmitReturnType<T, RT>,
		{
			onSuccess,
			onFail,
		}: {
			onSuccess?: (
				response: Partial<T> | Partial<RT> | undefined | Response
			) => void;
			onFail?: (errors: any) => void;
		}
	) => {
		return async (e?: any) => {
			e?.preventDefault();
			const fieldErrors = updateErrors(formValue);
			if (!fieldErrors || !Object.keys(fieldErrors).length) {
				try {
					setIsSubmitting(true);
					setErrors({});
					const res = await fnSubmit(formValue);
					onSuccess?.(res);
				} catch (err: any) {
					console.log({ err });
					if (err?.response?.data) {
						let { form = [], fieldErrors = {}, ...other } = err.response.data;
						const otherErrors = Object.keys(other || {}).reduce(
							(obj: { [key: string]: FormError }, prop) => {
								if (Object.prototype.hasOwnProperty.call(formValue, prop)) {
									obj[prop] = other[prop];
								} else {
									form.push(JSON.stringify(other[prop]));
								}
								return obj;
							},
							{}
						);
						setErrors(err.response.data);

						const errorsToUse = {
							form,
							fieldErrors,
							otherErrors,
						};
						setErrors(errorsToUse);
					}
					onFail?.(err);
				}
				setIsSubmitting(false);
			} else {
				onFail?.(fieldErrors);
			}
		};
	};

	return {
		value: formValue,
		registerSubmit,
		isSubmitting,
		isDirty,
		setValue,
		patchValue,
		patchValueClean,
		reset,
		errors,
		onChange,
		setFormValue,
	};
}
