import React, { useState } from "react";
import Button from "../../common/Button";
import { OpenClosedStates } from "../../common/OpenClosedStates";
import ChangePasswordModal from "./ChangePasswordModal";

const ChangePassword = () => {
  const [state, setState] = useState(OpenClosedStates.Closed);

  const openPasswordModal = () => {
    setState(OpenClosedStates.Open);
  };

  return (
    <>
      <Button
        text="Change password"
        onClick={openPasswordModal}
        icon={
          <path
            fillRule="evenodd"
            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
            clipRule="evenodd"
          />
        }
      />
      <ChangePasswordModal state={state} setState={setState} />
    </>
  );
};

export default ChangePassword;
