import { Auth } from 'aws-amplify';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MeContext } from '../contexts/me.context';

type Props = {
	closeDropdown: () => void;
};

const UserDropdown: React.FC<Props> = ({ closeDropdown }) => {
	const { me } = useContext(MeContext);

	const logout = () => {
		closeDropdown();
		Auth.signOut();
		window.location.href = '/';
	};

	return (
		<div
			className="mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
			role="menu"
			aria-orientation="vertical"
			aria-labelledby="options-menu"
		>
			<div className="px-4 py-3" role="none">
				<p className="text-sm" role="none">
					Signed in as
				</p>
				<p className="text-sm font-medium text-gray-900 truncate" role="none">
					{me.email}
				</p>
			</div>
			<div className="py-1" role="none">
				<Link
					to="/profile"
					className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
					role="menuitem"
					onClick={closeDropdown}
				>
					Profile
				</Link>
				{me.role !== 'user' && (
					<>
						<Link
							to="/billing"
							className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
							role="menuitem"
							onClick={closeDropdown}
						>
							Billing
						</Link>
						<Link
							to="/account-settings"
							className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
							role="menuitem"
							onClick={closeDropdown}
						>
							Account settings
						</Link>
					</>
				)}
			</div>
			<div className="py-1" role="none">
				<form method="POST" action="#" role="none">
					<button
						className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
						role="menuitem"
						onClick={logout}
					>
						Sign out
					</button>
				</form>
			</div>
		</div>
	);
};

export default UserDropdown;
