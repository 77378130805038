import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Header from '../../common/Header';
import Loading from '../../common/Loading';
import Pagination from '../../common/Pagination';
import { Blog } from '../../types/Blog.type';
import useBlogList from './hooks/useBlogList';

const BlogList = () => {
	const { data, setSkip, isFetching, refetch } = useBlogList();
	const [pages, setPages] = useState<number[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [dataPages, setDataPages] = useState<any>({});
	const navigate = useNavigate();

	const location = useLocation();
	const refetchFromState = location.state?.refetch;

	useEffect(() => {
		if (refetchFromState) {
			refetch();
		}
	}, [refetchFromState, refetch]);

	useEffect(() => {
		const currPage = location.search.split('=').pop();
		setCurrentPage(Number(currPage));
	}, [location]);

	useEffect(() => {
		if (data) {
			const temp = [];
			const totalPages = Math.ceil(
				data?.pages?.[0]?.metaData?.totalRecords / 10
			);
			for (let i = 1; i <= totalPages; i++) {
				temp.push(i);
			}
			setPages(temp);
		}
	}, [currentPage, data, dataPages]);

	useEffect(() => {
		if (data?.pages) {
			let retrievedPage: number;
			const currentSkip = data.pages?.[0]?.metaData?.skip;
			if (currentSkip === 0) {
				retrievedPage = pages.length;
			} else {
				retrievedPage = currentSkip / 10;
			}
			if (retrievedPage > 0) {
				setDataPages((prev: any) => {
					if (!prev[retrievedPage]) {
						return { ...prev, [retrievedPage]: [data?.pages[0].data] };
					} else {
						return prev;
					}
				});
			}
		}
	}, [currentPage, data, pages]);

	useEffect(() => {
		if (!dataPages[currentPage]) {
			setSkip((currentPage - 1) * 10);
		}
	}, [currentPage, dataPages, setSkip]);

	const setNewPage = (val: number) => {
		navigate(`/products?page=${String(val)}`);
	};

	return (
		<>
			<Header
				title="Blog"
				className="pb-6"
				rightSide={
					<Link to={`/blog/new`}>
						<span className="bg-primary-400 text-white rounded-md inline-flex justify-center py-2 pl-4 pr-2 font-medium hover:bg-primary-600">
							New article
						</span>
					</Link>
				}
			/>
			{isFetching ? (
				<Loading />
			) : (
				<div>
					{dataPages?.[currentPage]?.[0]?.map((row: Blog, rowKey: number) => (
						<Link
							to={`/blog/${row.id}`}
							key={row.id}
							className="cursor-pointer"
						>
							<div
								className={[
									'p-6 relative pl-4 pr-6 py-5 sm:py-6 sm:pl-6 hover:bg-gray-200',
									rowKey % 2 === 0 ? 'bg-white' : 'bg-gray-50',
								].join(' ')}
							>
								<div className="flex flex-col gap-4">
									<span className="font-semibold">
										{dayjs(row.createDate).format('MM/DD/YYYY')}
									</span>
									<span className="font-bold">{row.title}</span>
									<span className="italic">
										{row.summary || `${row.article?.substring(0, 150)}...`}
									</span>
								</div>
							</div>
						</Link>
					))}
				</div>
			)}
			<Pagination
				pages={pages}
				currentPage={currentPage}
				totalResults={data?.pages?.[0]?.metaData?.totalRecords}
				setCurrentPage={setNewPage}
			/>
		</>
	);
};

export default BlogList;
