import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import { Amplify, Auth, PubSub } from 'aws-amplify';
import { useContext, useEffect, useState } from 'react';
import {
	QueryFunctionContext,
	useInfiniteQuery,
	useQueryClient,
} from 'react-query';
import * as ShedsAPI from '../../common/helpers/ShedsAPI';
import { LIST_LIMIT } from '../../constants';
import * as notificationService from '../../services/notification.service';
import { MeContext } from '../contexts/me.context';
import { ShedsNotification } from '../types/ShedsNotification.type';

const connectToRealtime = async (accountId: number) => {
	const info = await Auth.currentCredentials();
	return ShedsAPI.get(
		'api',
		`/${accountId}/connect?identityId=${info.identityId}`,
		{}
	);
};

const getUsersQuery = (accountId?: number) => {
	return async (context: QueryFunctionContext) => {
		if (typeof accountId !== 'number') {
			return;
		}
		return notificationService.list(accountId, {
			skip: context.pageParam,
			limit: LIST_LIMIT,
		});
	};
};

const useNotifications = (id?: number) => {
	const queryClient = useQueryClient();
	const { me } = useContext(MeContext);
	const [queryKey] = useState<string>('notifications');
	const [connected, setConnected] = useState(false);
	const {
		status,
		data,
		error,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		refetch,
	} = useInfiniteQuery<{
		data: ShedsNotification[];
		metaData: { skip: number; totalRecords: number };
	}>(queryKey, getUsersQuery(id || me.account?.id), {
		getNextPageParam: (lastGroup) => lastGroup?.metaData?.skip || undefined,
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (!connected && me?.account?.hasOwnProperty('id')) {
			connect(me.account.id);
		}
	}, [connected, me]);

	const connect = async (accountId?: number) => {
		if (typeof accountId !== 'number') {
			return;
		}
		try {
			await connectToRealtime(accountId);
			Amplify.addPluggable(
				new AWSIoTProvider({
					aws_pubsub_region: 'us-east-1',
					aws_pubsub_endpoint:
						'wss://anz43kqbfl4m3-ats.iot.us-east-1.amazonaws.com/mqtt',
				})
			);
			setConnected(true);
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		if (!connected) {
			return;
		}
		PubSub.subscribe([
			`notifications/${process.env.REACT_APP_STAGE}/user/${me.id}`,
			`notifications/${process.env.REACT_APP_STAGE}/account/${me.account.id}`,
		]).subscribe({
			next: (newData) => {
				queryClient.setQueryData('notifications', {
					...data,
					pages: data?.pages.map((page: any) => ({
						...page,
						data: [newData.value, ...page.data],
					})),
				});
			},
			error: (error) => {
				console.error({ globalErr: error });
			},
			complete: () => {
				console.log('disconnected from global notifications');
			},
		});
	}, [me, data, connected, queryClient]);

	const dismiss = async (itemId: number) => {
		try {
			await notificationService.dismiss(id || me.account?.id, itemId);
			queryClient.setQueryData('notifications', {
				...data,
				pages: data?.pages.map((page) => ({
					...page,
					data: page.data.filter((item) => item.id !== itemId),
				})),
			});
		} catch (err) {
			console.error('Failed to dismiss notification.');
		}
	};

	return {
		status,
		data,
		error,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		refetch,
		dismiss,
	};
};

export default useNotifications;
