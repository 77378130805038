import React from 'react';
import Button, { ButtonColors } from '../common/Button';
import Modal, { ModalSizes } from '../common/Modal';
import { OpenClosedStates } from './OpenClosedStates';

type Props = {
	state: OpenClosedStates;
	title?: string;
	description?: string;
	confirmText?: string;
	confirm: () => void;
	close: () => void;
	loading?: boolean;
	buttonType?: string;
	icon?: JSX.Element;
};

const ConfirmModal: React.FC<Props> = ({
	title = 'Are you sure?',
	description,
	confirmText = 'Yes',
	confirm,
	close,
	state,
	loading = false,
	buttonType,
	icon,
}) => {
	return (
		<Modal state={state} size={ModalSizes['sm']} noScroll>
			<div>
				<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 dark:bg-darkGray-500">
					{icon || (
						<svg
							className="h-6 w-6 text-gray-600 dark:text-gray-400"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
							/>
						</svg>
					)}
				</div>
				<div className="mt-3 text-center sm:mt-5">
					<h3
						className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100"
						id="modal-headline"
					>
						{title}
					</h3>
					{description && (
						<div className="mt-6 text-left">
							<p className="text-sm leading-5 text-gray-500 dark:text-gray-400">
								{description}
							</p>
						</div>
					)}
				</div>
				<div className="mt-6 grid grid-cols-2 gap-3 grid-flow-row-dense">
					<Button
						text="Cancel"
						onClick={close}
						fullWidth
						color={ButtonColors.plain}
						className="border border-gray-300 dark:border-darkGray-500"
					/>
					{buttonType === 'submit' ? (
						<Button
							text={confirmText}
							type="submit"
							fullWidth
							loading={loading}
						/>
					) : (
						<Button
							text={confirmText}
							onClick={confirm}
							fullWidth
							loading={loading}
						/>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default ConfirmModal;
