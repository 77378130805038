import { useEffect, useMemo, useState } from 'react';
import {
	QueryFunctionContext,
	useInfiniteQuery,
	useMutation,
	useQueryClient,
} from 'react-query';
import { useDebounce } from '../../../common/hooks/useDebounce';
import { LIST_LIMIT } from '../../../constants';
import { listManufacturers } from '../../../services/manufacturer.service';
import { Manufacturer } from '../Manufacturer.type';

const getManufacturersQuery = (
	definitelyInclude?: string,
	publicRoute?: boolean
) => {
	return async (context: QueryFunctionContext) => {
		const search = context.queryKey[1] as string;
		return listManufacturers(
			{
				skip: context.pageParam,
				limit: LIST_LIMIT,
				search,
				include:
					definitelyInclude && (!context.pageParam || context.pageParam === 0)
						? definitelyInclude
						: undefined,
			},
			publicRoute
		);
	};
};

const useManufacturers = (
	includeNone: boolean = false,
	definitelyInclude?: string,
	publicRoute?: boolean
) => {
	const queryClient = useQueryClient();
	const [queryKey, setQueryKey] = useState<[string, string | undefined]>([
		'manufacturers',
		undefined,
	]);

	const {
		status,
		data,
		error,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		refetch,
	} = useInfiniteQuery<{
		data: Manufacturer[];
		metaData: { skip: number; totalRecords: number };
	}>(queryKey, getManufacturersQuery(definitelyInclude, publicRoute), {
		getNextPageParam: (lastGroup) => lastGroup?.metaData?.skip || undefined,
		refetchOnWindowFocus: false,
	});

	const allData = useMemo(
		() => data?.pages.flatMap((page) => page.data),
		[data]
	);

	const [searchTerm, setSearchTerm] = useState<string>();
	const debouncedSearchTerm = useDebounce(searchTerm);
	const [manufacturerOptions, setManufacturerOptions] = useState<any[]>([]);

	useEffect(() => {
		setQueryKey(['manufacturers', debouncedSearchTerm]);
	}, [debouncedSearchTerm]);

	useEffect(() => {
		if (hasNextPage) {
			fetchNextPage();
		} else {
			const options = [
				...(includeNone
					? [
							{ name: 'None', id: undefined },
							{ name: 'Add new', id: '$add' },
					  ]
					: [{ name: 'Add new', id: '$add' }]),
				...(allData ?? []),
			];

			setManufacturerOptions(options || []);
		}
	}, [allData, data, fetchNextPage, hasNextPage, includeNone]);

	const onSearch = (val: string) => {
		setSearchTerm(val);
	};

	const onAddManufacturer = (val: Manufacturer) => {
		queryClient.invalidateQueries('manufacturers');
	};

	return {
		status,
		data,
		error,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		refetch,
		onSearch,
		manufacturerOptions,
		searchTerm,
		onAddManufacturer,
	};
};

export default useManufacturers;
