import { Dealer } from '../pages/dealers/Dealer.type';
import * as ShedsAPI from '../common/helpers/ShedsAPI';
import { ContactForm } from '../pages/logged-out/SupportModal';

export const list = async (
	accountId: number,
	params: {
		search?: string;
		limit?: number;
		skip?: number;
	}
) => {
	return ShedsAPI.get('api', `/${accountId}/dealers`, {
		queryStringParameters: params,
	});
};

export const get = ({ dealerId }: { dealerId: number }): Promise<Dealer> => {
	return ShedsAPI.get('api', `/dealers/${dealerId}`, {});
};

export const update = ({
	dealer,
}: {
	dealer: Partial<Dealer>;
}): Promise<Dealer> => {
	const { id, ...restOfDealer } = dealer;
	return ShedsAPI.put('api', `/dealers/${id}`, {
		body: restOfDealer,
	});
};

export const create = ({
	dealer,
}: {
	dealer: Partial<Dealer>;
}): Promise<Dealer> => {
	return ShedsAPI.post('api', `/dealers`, {
		body: dealer,
	});
};

export const save = ({
	dealer,
}: {
	dealer: Partial<Dealer>;
}): Promise<Dealer> => {
	return dealer.id ? update({ dealer }) : create({ dealer });
};

export const supportForm = (val: Partial<ContactForm>) => {
	return ShedsAPI.post('api-noauth', `/support`, { body: val });
};

export const revoke = (
	accountId: number,
	manufacturerId: number,
	dealerId: number
) => {
	return ShedsAPI.post('api', `/${accountId}/dealers/revoke`, {
		body: {
			manufacturerId,
			dealerId,
		},
	});
};

export const contact = async (
	values: Partial<ContactForm>,
	advertiserEmail?: string
): Promise<any> => {
	return ShedsAPI.post('api', `/advertiserContact`, {
		body: { ...values, advertiserEmail },
	});
};
