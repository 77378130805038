import { Popover } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import { FormError } from '../types/ErrorResponse.type';
import { ErrorIcon } from './ErrorIcon';
import { FieldErrors } from './FieldErrors';
import { Options } from './Options';

export const getSelectedValue = (
	value: any,
	options: any[],
	valueProp = 'value'
) => {
	return value
		? Array.isArray(value)
			? value.reduce((obj: { [key: number]: string }, val: string) => {
					obj[options.findIndex((opt) => opt[valueProp] === val)] = val;
					return obj;
			  }, {})
			: { [options.findIndex((opt) => opt[valueProp] === value)]: value }
		: {};
};

export type SelectProps = {
	name: string;
	className: string;
	label: string;
	value?: any;
	onChange: (val: any) => void;
	errors?: FormError;
	options?: any[];
	limit?: number;
	showLabel?: boolean;
	disabled?: boolean;
	alwaysReturnArray?: boolean;
	clearable?: boolean;
	valueProp?: string;
	textProp?: string;
	isFetchingNextPage?: boolean;
	fetchNextPage?: (options?: FetchNextPageOptions | undefined) => Promise<
		InfiniteQueryObserverResult<
			{
				data: any[];
				lastKey?: string;
				skip?: number;
			},
			unknown
		>
	>;
	hasNextPage?: boolean;
	onAdd?: () => void;
	hint?: string;
	hintOnClick?: () => void;
	endOfList?: boolean;
};

export const Select: React.FC<SelectProps> = ({
	name,
	className,
	label,
	value,
	onChange,
	errors,
	options = [],
	limit,
	showLabel = true,
	disabled = false,
	alwaysReturnArray = false,
	clearable = false,
	valueProp = 'value',
	textProp = 'text',
	isFetchingNextPage,
	fetchNextPage,
	hasNextPage,
	onAdd,
	hint,
	hintOnClick,
	endOfList = true,
}) => {
	let [referenceElement, setReferenceElement] =
		useState<HTMLButtonElement | null>(null);
	let [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
	let { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: 'bottom-start',
	});
	const initialSelected = getSelectedValue(value, options, valueProp);
	const [selected, setSelected] = useState<{ [key: number]: any }>(
		initialSelected
	);

	useEffect(() => {
		const newSelected = getSelectedValue(value, options, valueProp);
		setSelected(newSelected);
	}, [value, options, valueProp]);

	const toggleSelected = (i: number | string) => {
		const hasAdd = i === '$add';
		if (hasAdd && onAdd) {
			onAdd();
		} else {
			const optionI = i as number;
			if (limit === 1) {
				referenceElement?.click();
			}
			if (limit === 1 && !alwaysReturnArray) {
				const val = options[optionI][valueProp];
				onChange(val);
				return;
			}
			let newValues = JSON.parse(JSON.stringify(selected));
			if (limit === 1) {
				newValues = {};
			}
			const currentNumberSelected = Object.keys(newValues).length;
			const isSelected = !!newValues[optionI];
			if (isSelected) {
				delete newValues[optionI];
			} else {
				if (limit && currentNumberSelected + 1 > limit) {
					console.error('nope');
				} else {
					newValues[optionI] = options[optionI][valueProp];
				}
			}
			onChange(Object.values(newValues) as string[]);
		}
	};

	const clear = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
		e.stopPropagation();
		onChange(limit === 1 && !alwaysReturnArray ? '' : []);
		referenceElement?.click();
	};

	const displayValue = (val: string) => {
		const selectedOption = options.find((option) => option[valueProp] === val);
		return selectedOption?.[textProp] || '1 item selected';
	};

	return (
		<div className={className}>
			{showLabel && (
				<div>
					<label
						htmlFor={name}
						className="block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2"
					>
						{label}
					</label>
				</div>
			)}
			<Popover className="w-full">
				<Popover.Button
					ref={setReferenceElement}
					className={[
						'cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5',
						errors
							? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red'
							: 'focus:ring-blue focus:border-blue-300',
						disabled ? 'text-gray-500 bg-gray-200 cursor-not-allowed' : '',
					].join(' ')}
					disabled={disabled}
				>
					<span
						className={[
							'block truncate',
							Object.keys(selected).length ? '' : 'text-gray-400',
						].join(' ')}
					>
						{Object.keys(selected).length
							? Object.keys(selected).length > 1
								? `${Object.keys(selected).length} items selected`
								: `${displayValue(Object.values(selected)[0])}`
							: showLabel
							? ''
							: label}
						&nbsp;
					</span>
					<span
						className="absolute inset-y-0 right-0 flex items-center pr-2"
						data-cy={`select-${name}`}
					>
						{errors ? (
							<ErrorIcon />
						) : (
							<>
								{clearable &&
									!!(Array.isArray(value) ? value.length : value) && (
										<svg
											className="h-4 w-4 text-gray-400"
											viewBox="0 0 20 20"
											fill="currentColor"
											onClick={clear}
										>
											<path
												fillRule="evenodd"
												d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
												clipRule="evenodd"
											/>
										</svg>
									)}
								<svg
									className="h-5 w-5 text-gray-400 pointer-events-none"
									viewBox="0 0 20 20"
									fill="none"
									stroke="currentColor"
								>
									<path
										d="M7 7l3-3 3 3m0 6l-3 3-3-3"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</>
						)}
					</span>
				</Popover.Button>{' '}
				{ReactDOM.createPortal(
					<Popover.Panel
						ref={setPopperElement}
						style={{
							...styles.popper,
							width: referenceElement?.clientWidth,
							zIndex: 99,
						}}
						{...attributes.popper}
					>
						<Options
							open={true}
							options={options}
							selected={selected}
							toggleSelected={toggleSelected}
							valueProp={valueProp}
							textProp={textProp}
							isFetchingNextPage={isFetchingNextPage}
							fetchNextPage={fetchNextPage}
							hasNextPage={hasNextPage}
							endOfList={endOfList}
						/>
					</Popover.Panel>,
					document.querySelector('body') as Element
				)}
			</Popover>

			<FieldErrors errors={errors} />
			{!!hint && (
				<p
					className={[
						'mt-2 text-sm',
						hintOnClick
							? 'text-primary-600 font-semibold hover:text-primary-500 cursor-pointer'
							: 'text-gray-500',
					].join(' ')}
					id={`${name}-hint`}
					onClick={hintOnClick}
				>
					{hint}
				</p>
			)}
		</div>
	);
};
