import { MailIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Button, { ButtonColors } from '../../common/Button';
import Modal, { ModalSizes } from '../../common/Modal';
import { OpenClosedStates } from '../../common/OpenClosedStates';
import { Contact } from '../../types/Contact.type';
import utc from 'dayjs/plugin/utc';
import { MeContext } from '../../common/contexts/me.context';
import PillBox from '../../common/form/PillBox';
dayjs.extend(utc);

type Props = {
	close: () => void;
	contact: Contact;
};

const ContactModal: React.FC<Props> = ({ close, contact }) => {
	const { me } = useContext(MeContext);

	const isHTML = (message: string) => {
		const div = document.createElement('div');
		div.innerHTML = message;
		return div.childNodes.length > 0;
	};

	return (
		<Modal
			state={!!contact ? OpenClosedStates.Open : OpenClosedStates.Closed}
			 maxHeight={850} size={ModalSizes['xl']}
		>
			<div className="p-6 max-w-lg mx-auto">
				<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 dark:bg-darkGray-500">
					<MailIcon className="h-6 w-6 text-gray-600 dark:text-gray-400" />
				</div>
				<div className="mt-3 sm:mt-5 text-left">
					<h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100 text-center">
						Sent{' '}
						<span className="font-light">
							{dayjs.utc(contact?.createDate).local().format('MM-DD-YYYY')} at{' '}
							{dayjs.utc(contact?.createDate).local().format('hh:mma')}
						</span>
					</h3>
					<div className="mt-6">
						<dl className="w-full space-y-4">
							{!!contact?.product && (
								<dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12">
									<div>
										<dt className="text-lg leading-6 font-medium text-gray-900">
											Regarding product
										</dt>
										<dd className="text-sm text-gray-500">
											{me.role === 'superAdmin' ? (
												<a
													href={`${process.env.REACT_APP_CUSTOMER_URL}/products/${contact.productPath}`}
													target="_blank"
													rel="noreferrer"
													className="text-primary-500 hover:text-primary-600"
												>
													{contact?.productName}
												</a>
											) : (
												<Link
													to={`/products/${contact.product}`}
													className="text-primary-500 hover:text-primary-600"
												>
													{contact?.productName}
												</Link>
											)}
										</dd>
									</div>
								</dl>
							)}
							{contact?.message && contact?.message.includes("New Lead") && (
								<div className="grid grid-cols-1 sm:grid-cols-1 md:flex items-center justify-between space-x-4">
									<div className="sm:flex flex-col flex-shrink-0 items-end space-y-3">
										<p className="flex items-center space-x-4">
											<span className="relative text-sm leading-4 text-gray-500 hover:text-gray-900 font-medium">
												&nbsp;
											</span>
										</p>
										<PillBox
											className={
												'bg-primary-600 focus:ring-primary-500 shadow-sm'
											}
											text={`3D Dream Builder Quote Request`}
											onClick={() => {}}
										/>
									</div>
								</div>
							)}
							{contact?.name && (
								<div>
									<dt className="text-md leading-6 font-medium text-gray-900">
										Name
									</dt>
									<dd className="text-sm text-gray-500">{contact?.name}</dd>
								</div>
							)}
							{contact?.email && (
								<div>
									<dt className="text-md leading-6 font-medium text-gray-900">
										Email
									</dt>
									<dd className="text-sm text-primary-500">
										<a href={`mailto:${contact?.email}`}>{contact?.email}</a>
									</dd>
								</div>
							)}
							{contact?.phone && (
								<div>
									<dt className="text-md leading-6 font-medium text-gray-900">
										Phone
									</dt>
									<dd className="text-sm text-primary-500">
										<a href={`tel:${contact?.phone}`}>{contact?.phone}</a>
									</dd>
								</div>
							)}
							{contact?.message && (
								<div>
									<dt className="text-md leading-6 font-medium text-gray-900">
										Message
									</dt>
									<dd className="text-sm text-gray-500">
										{isHTML(contact.message) ? (
											<div dangerouslySetInnerHTML={{ __html: contact.message }} />
										) : (
											<span>{contact.message}</span>
										)}
									</dd>
								</div>
							)}
							{contact?.zip && (
								<div>
									<dt className="text-md leading-6 font-medium text-gray-900">
										Near Zip Code
									</dt>
									<dd className="text-sm text-gray-500">{contact?.zip}</dd>
								</div>
							)}
							{contact?.contacted && (
								<div>
									<dt className="text-md leading-6 font-medium text-primary-500">
										Contacted
									</dt>
									<dd className="text-sm text-gray-500">
										Contacted by{' '}
										<span className="font-bold">{contact?.contacted}</span>{' '}
										{contact?.contactedDate && (
											<span>
												on{' '}
												<span className="font-bold">
													{dayjs(contact?.contactedDate).format('MM-DD-YYYY')}
												</span>
											</span>
										)}
									</dd>
								</div>
							)}
						</dl>
					</div>
				</div>
				<div className="mt-6 grid grid-cols-1 gap-3">
					<Button
						text="Close"
						onClick={close}
						fullWidth
						color={ButtonColors.plain}
						className="border border-gray-300 dark:border-darkGray-500"
					/>
				</div>
			</div>
		</Modal>
	);
};

export default ContactModal;
