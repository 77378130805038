import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DealerContainer from './detail/DealerContainer';
import DealerList from './DealerList';

const Dealers = () => {
	return (
		<Routes>
			<Route path="/" element={<DealerList />} />
			<Route path=":dealerId/*" element={<DealerContainer />} />
			<Route path="*" element={<span>no match</span>} />
		</Routes>
	);
};

export default Dealers;
