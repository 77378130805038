import { QueryFunctionContext, useQuery, useQueryClient } from "react-query";
import { ErrorResponse } from "../../common/types/ErrorResponse.type";
import * as userService from "../../services/user.service";
import { User } from "../users/User.type";

const getUserQuery = () => {
  return async (context: QueryFunctionContext) => {
    return userService.getProfile();
  };
};

const useProfile = () => {
  const queryClient = useQueryClient();
  const { data, isFetching, refetch, remove, isFetched } = useQuery<
    User,
    ErrorResponse
  >(["profile"], getUserQuery(), {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const save = async (user: Partial<User>) => {
    const res = await userService.saveProfile({
      user,
    });
    queryClient.setQueryData(["profile"], res);
    return res;
  };

  return {
    isFetching,
    data,
    save,
    refetch,
    remove,
    isFetched,
  };
};

export default useProfile;
