import React from 'react';
import { Link } from 'react-router-dom';

export type Tab = {
	name: string;
	to: string;
	active?: boolean;
};

type Props = {
	tabs: Tab[];
};

const Tabs: React.FC<Props> = ({ tabs }) => {
	return (
		<div className="mt-4">
			<div>
				<nav className="-mb-px flex space-x-8">
					{tabs.map((tab) => (
						<Link
							key={tab.to}
							to={tab.to}
							className={
								tab.active
									? 'border-primary-500 text-primary-500 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
									: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
							}
						>
							{tab.name}
						</Link>
					))}
				</nav>
			</div>
		</div>
	);
};

export default Tabs;
