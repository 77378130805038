import React, { Dispatch, SetStateAction } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import SignUp from './SignUp';

type Props = {
	onAuth: Dispatch<SetStateAction<object | undefined>>;
};

const LoggedOut: React.FC<Props> = ({ onAuth }) => {
	const location = useLocation();

	return (
		<Routes>
			<Route
				path={'/'}
				element={<Login onAuth={onAuth} />}
			/>
			<Route path="/forgot-password" element={<ForgotPassword />} />
			<Route path="/sign-up" element={<SignUp onAuth={onAuth} />} />
			<Route path="*" element={<Login onAuth={onAuth} />} />
		</Routes>
	);
};

export default LoggedOut;
