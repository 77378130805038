import { MailIcon, XIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import Button from '../../common/Button';
import { Input } from '../../common/form/Input';
import { useForm } from '../../common/hooks/useForm';
import Modal, { ModalSizes } from '../../common/Modal';
import { OpenClosedStates } from '../../common/OpenClosedStates';

type Props = {
	state: OpenClosedStates;
	patchValue: (arg: Partial<any>) => void;
	currEmails?: any[];
	close: () => void;
};

const AddContactEmailModal = ({
	state,
	patchValue,
	currEmails,
	close,
}: Props) => {
	const { value: newEmailValue, onChange, reset } = useForm({ email: '' });
	const validateEmail = (email: string) => {
		const emailRegex = new RegExp(
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
		return emailRegex.test(email);
	};
	const [errorState, setErrorState] = useState(false);
	return (
		<Modal state={state} size={ModalSizes.lg} noScroll>
			<div className="flex flex-col justify-center items-center gap-4 p-4 relative">
				<XIcon
					className="w-5 h-5 absolute top-0 right-0 cursor-pointer"
					onClick={() => close()}
				/>
				<MailIcon className="h-10 w-10 text-primary-500" />
				<span className="font-semibold">
					Add new email to receive contact form notifications.
				</span>
				<div className="flex gap-2 items-end">
					<Input
						name="email"
						type="email"
						value={newEmailValue.email as string}
						onChange={onChange('email')}
						label="Email"
					/>
					<Button
						className="w-full"
						text="Add"
						onClick={() => {
							let isValid = validateEmail(newEmailValue.email as string);
							if (!isValid) {
								setErrorState(true);
								return;
							} else {
							}
							setErrorState(false);
							patchValue({
								contactFormEmails: [...(currEmails || []), newEmailValue.email],
							});
							reset();
							close();
						}}
						disabled={newEmailValue.email === ''}
					/>
					<MailIcon className="h-10 w-10 text-primary-500" />
				</div>
				{errorState && (
					<div>
						<p style={{ color: 'red' }}>Email does not match form validation</p>
					</div>
				)}
			</div>
		</Modal>
	);
};
export default AddContactEmailModal;
