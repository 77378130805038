import React, { useEffect, useState } from 'react';
import useManufacturers from '../pages/manufacturers/hooks/useManufacturers';
import { Manufacturer } from '../pages/manufacturers/Manufacturer.type';
import { Autocomplete } from './form/Autocomplete';
import ManufacturerAddModal from './ManufacturerAddModal';
import { OpenClosedStates } from './OpenClosedStates';
import { FormError } from './types/ErrorResponse.type';

type Props = {
	onChange: (val: number | number[] | '$add') => void;
	value?: number | number[];
	errors?: FormError;
	className?: string;
	limit?: number;
	label?: string;
	publicRoute?: boolean;
};

const ManufacturerAutocomplete: React.FC<Props> = ({
	onChange,
	value,
	errors,
	className = 'col-span-6',
	limit,
	label = 'Manufacturers',
	publicRoute,
}) => {
	const {
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		manufacturerOptions,
		onSearch,
		searchTerm,
		isFetching,
		onAddManufacturer,
	} = useManufacturers(
		false,
		value ? (Array.isArray(value) ? value : [value]).join(',') : undefined,
		publicRoute
	);

	const [modalState, setModalState] = useState<OpenClosedStates>(
		OpenClosedStates.Closed
	);

	useEffect(() => {
		console.log({ manufacturerOptions });
	}, [manufacturerOptions]);

	const onAdd = () => {
		setModalState(OpenClosedStates.Open);
	};

	const onSaveAdd = (val: Manufacturer) => {
		const id = val?.id as number;
		if (val?.id) {
			if (limit === 1) {
				onAddManufacturer(val);
				onChange(id);
			} else {
				const newValues = (value || []) as number[];
				onAddManufacturer(val);
				onChange([id].concat(newValues));
			}
		}
	};

	return (
		<>
			<Autocomplete
				options={manufacturerOptions}
				valueProp="id"
				textProp="name"
				label={label}
				value={value}
				onChange={onChange}
				name="manufacturer"
				className={className}
				errors={errors}
				isFetchingNextPage={!!isFetchingNextPage}
				isFetching={!!isFetching}
				fetchNextPage={fetchNextPage}
				hasNextPage={hasNextPage}
				onChangeSearch={onSearch}
				searchTerm={searchTerm}
				limit={limit}
				onAdd={onAdd}
			/>
			<ManufacturerAddModal
				isDataFetching={isFetching}
				state={modalState}
				setState={setModalState}
				save={onSaveAdd}
			/>
		</>
	);
};

export default ManufacturerAutocomplete;
