import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';

type Props = {
	playbackId: string;
};

const VideoPlayer: React.FC<Props> = ({ playbackId }) => {
	const videoRef = useRef(null);

	useEffect(() => {
		const src = `https://stream.mux.com/${playbackId}.m3u8`;
		let hls: any;
		if (videoRef.current) {
			const video: any = videoRef.current;

			if (video.canPlayType('application/vnd.apple.mpegurl')) {
				// Some browers (safari and ie edge) support HLS natively
				video.src = src;
			} else if (Hls.isSupported()) {
				// This will run in all other modern browsers
				hls = new Hls();
				hls.loadSource(src);
				hls.attachMedia(video);
			} else {
				console.error("This is a legacy browser that doesn't support MSE");
			}
		}

		return () => {
			if (hls) {
				hls.destroy();
			}
		};
	}, [videoRef, playbackId]);

	return (
		<video
			controls
			ref={videoRef}
			style={{ width: '100%', maxWidth: '500px' }}
		/>
	);
};

export default VideoPlayer;
