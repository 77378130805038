import {
	ViewGridIcon,
	OfficeBuildingIcon,
	UsersIcon,
	ViewListIcon,
	BriefcaseIcon,
	ShoppingCartIcon,
	CogIcon,
	MailIcon,
	PhotographIcon,
	RssIcon,
	CurrencyDollarIcon,
} from '@heroicons/react/outline';
import { MenuItem } from './MenuItem.type';

export const menuItems: MenuItem[] = [
	{
		path: '/',
		title: 'Dashboard',
		icon: ViewGridIcon,
	},
	{
		path: '/products?page=1',
		title: 'Products',
		icon: ShoppingCartIcon,
	},
	{
		path: '/users',
		title: 'Users',
		icon: UsersIcon,
		restrictRoles: ['groupAdmin', 'superAdmin'],
	},
	{
		path: '/contact',
		title: 'Contact',
		icon: MailIcon,
	},
	{
		path: '/accounts',
		title: 'Accounts',
		icon: ViewListIcon,
		restrict: ['SuperAdmin'],
	},
	{
		path: '/dealers',
		title: 'Dealers',
		icon: BriefcaseIcon,
		restrict: ['Manufacturer'],
	},
	{
		path: '/manufacturers',
		title: 'Manufacturers',
		icon: OfficeBuildingIcon,
		restrict: ['SuperAdmin'],
	},
	{
		path: '/ad-requests',
		title: 'Ad requests',
		icon: CurrencyDollarIcon,
		restrictRoles: ['superAdmin'],
	},
	{
		path: '/account-settings',
		title: 'Account settings',
		icon: CogIcon,
		restrictRoles: ['groupAdmin', 'superAdmin'],
	},
	{
		path: '/display-ads',
		title: 'Display Ads',
		icon: PhotographIcon,
		restrictRoles: ['superAdmin'],
	},
	{
		path: '/blog?page=1',
		title: 'Blog',
		icon: RssIcon,
		restrictRoles: ['superAdmin'],
	},
];
