import React from "react";
import Tabs from "../../../common/Tabs";

type Props = {
  active: string;
  dealerId: string;
};

const DealerTabs: React.FC<Props> = ({ active, dealerId }) => {
  return (
    <Tabs
      tabs={[
        {
          name: "General",
          to: `/dealers/${dealerId}`,
          active: active === `/dealers/${dealerId}`,
        },
      ]}
    />
  );
};

export default DealerTabs;
