import React, { ReactNode } from 'react';

type Props = {
	title?: string;
	rightSide?: JSX.Element;
	className?: string;
	children?: ReactNode;
};

const Header: React.FunctionComponent<Props> = ({
	title = '',
	rightSide,
	children,
	className = '',
}) => {
	return (
		<div
			className={[
				'sticky z-10 bg-gray-50 top-0 sm:flex sm:items-center sm:justify-between border-b border-gray-200',
				children ? 'pt-6 px-6' : 'p-6',
				className,
			].join(' ')}
		>
			<div className="relative flex-grow">
				<h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
				{children}
			</div>
			<div className="flex mt-3 sm:mt-0 flex-shrink">{rightSide}</div>
		</div>
	);
};

export default Header;
