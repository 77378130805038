import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonColors } from '../../../common/Button';
import {
	ToastContext,
	ToastTypes,
} from '../../../common/contexts/toast.context';
import { Input } from '../../../common/form/Input';
import { useForm } from '../../../common/hooks/useForm';
import ManufacturerSelect from '../../../common/ManufacturerSelect';
import { Dealer } from '../Dealer.type';

const validation = {
	name: {
		required: {
			message: 'Name is required.',
		},
	},
};

type Props = {
	defaultValues?: Partial<Dealer>;
	save: (val: Partial<Dealer>) => Promise<Dealer | undefined>;
};

const DealerGeneral: React.FC<Props> = ({ defaultValues, save }) => {
	const { createToast } = useContext(ToastContext);
	const navigate = useNavigate();
	const {
		value,
		isDirty,
		errors,
		onChange,
		registerSubmit,
		isSubmitting,
		patchValue,
	} = useForm<Dealer>(defaultValues || {}, validation);

	useEffect(() => {
		if (defaultValues) {
			patchValue(defaultValues);
		}
	}, [patchValue, defaultValues]);

	const onSuccess = () => {
		createToast({
			title: 'Success!',
			description: <>Successfully saved dealer!</>,
			type: ToastTypes.Success,
			duration: 5000,
		});
		if (!value.id) {
			navigate('/accounts');
		}
	};

	const onFail = (err: any) => {
		const description = (
			<>{err?.response?.data || err?.response || 'Failed to save dealer!'}</>
		);
		createToast({
			title: 'Error!',
			description,
			type: ToastTypes.Fail,
			duration: 5000,
		});
	};

	const onSubmit = async (account: Partial<Dealer>) => {
		return save(account);
	};

	const onClickCancel = () => {
		navigate(-1);
	};

	return (
		<>
			<div className="grid grid-cols-1 sm:grid-cols-6 gap-4">
				<Input
					name="name"
					className="col-span-6"
					label="Name *"
					value={value.name}
					onChange={onChange('name')}
					errors={errors?.fieldErrors?.name}
				/>
				<ManufacturerSelect
					value={value.manufacturer}
					onChange={onChange('manufacturer')}
					errors={errors.fieldErrors?.manufacturer}
				/>
			</div>
			<div className="w-full flex justify-end mt-8">
				<div className="flex space-x-4 justify-end">
					<Button
						text="Cancel"
						onClick={onClickCancel}
						color={ButtonColors.transparent}
					/>
					<Button
						text={!!value.id ? 'Save' : 'Create'}
						onClick={registerSubmit(onSubmit, { onSuccess, onFail })}
						color={ButtonColors.primary}
						loading={isSubmitting}
						disabled={!isDirty}
					/>
				</div>
			</div>
		</>
	);
};

export default DealerGeneral;
