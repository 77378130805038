import React, { ReactNode } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import LoadingPage from '../LoadingPage';
import EndOfList from './EndOfList';

type Props = {
	children?: ReactNode;
	isFetching: boolean;
	isFetchingNextPage?: boolean;
	fetchNextPage?: (options?: FetchNextPageOptions | undefined) => Promise<
		InfiniteQueryObserverResult<
			{
				data: any[];
				lastKey?: string;
				skip?: number;
			},
			unknown
		>
	>;
	hasNextPage?: boolean;
	className?: string;
};

const List: React.FC<Props> = ({
	children,
	isFetching,
	isFetchingNextPage,
	fetchNextPage,
	hasNextPage,
	className = 'relative z-0 divide-y divide-gray-200 border-b border-gray-200',
}) => {
	return (
		<>
			{isFetching && !isFetchingNextPage ? (
				<>
					<LoadingPage />
				</>
			) : (
				<Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
					<ul className={className}>{children}</ul>
					<EndOfList
						isFetching={isFetching}
						isFetchingNextPage={!!isFetchingNextPage}
						fetchNextPage={fetchNextPage}
						hasNextPage={hasNextPage}
					/>
				</Scrollbars>
			)}
		</>
	);
};

export default List;
