import { useNavigate } from 'react-router-dom';
import SupportModal from './logged-out/SupportModal';
import { useState } from 'react';
import { OpenClosedStates } from '../common/OpenClosedStates';

const NotFound = () => {
	const navigate = useNavigate();
	const navigateHome = () => {
		navigate('/');
	};

	const [supportModal, setSupportModal] = useState<OpenClosedStates>(
		OpenClosedStates.Closed
	);

	return (
		<div className="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
			<div className="max-w-max mx-auto">
				<main className="sm:flex">
					<p className="text-4xl font-extrabold text-primary-500 sm:text-5xl">
						404
					</p>
					<div className="sm:ml-6">
						<div className="sm:border-l sm:border-gray-200 sm:pl-6">
							<h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
								Page not found
							</h1>
							<p className="mt-1 text-base text-gray-500">
								Please check the URL in the address bar and try again.
							</p>
						</div>
						<div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
							<button
								onClick={navigateHome}
								className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
							>
								Go back home
							</button>
							<button
								onClick={() => setSupportModal(OpenClosedStates.Open)}
								className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-primary-600 bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
							>
								Contact support
							</button>
						</div>
					</div>
				</main>
			</div>
			<SupportModal modalState={supportModal} setModalState={setSupportModal} />
		</div>
	);
};

export default NotFound;
