import { User } from '../pages/users/User.type';
import * as ShedsAPI from '../common/helpers/ShedsAPI';
import { Me } from '../common/contexts/me.context';

export const me = () => {
	return ShedsAPI.get('api', '/me', {});
};

export const forgotPassword = async (email: string) => {
	return ShedsAPI.post('api-noauth', '/forgot-password', {
		body: { email },
	});
};

export const list = async (
	accountId: number,
	params: {
		search?: string;
		limit?: number;
		skip?: number;
	}
) => {
	return ShedsAPI.get('api', `/${accountId}/users`, {
		queryStringParameters: params,
	});
};

export const get = ({
	accountId,
	userId,
}: {
	accountId: number;
	userId: string;
}): Promise<User> => {
	return ShedsAPI.get('api', `/${accountId}/users/${userId}`, {});
};

export const update = ({
	accountId,
	user,
}: {
	accountId: number;
	user: Partial<User>;
}): Promise<User> => {
	const { id, ...restOfUser } = user;
	return ShedsAPI.put('api', `/${accountId}/users/${id}`, {
		body: restOfUser,
	});
};

export const create = ({
	accountId,
	user,
}: {
	accountId: number;
	user: Partial<User>;
}): Promise<User> => {
	return ShedsAPI.post('api', `/${accountId}/users`, {
		body: user,
	});
};

export const save = ({
	accountId,
	user,
}: {
	accountId: number;
	user: Partial<User>;
}): Promise<User> => {
	return user.id ? update({ accountId, user }) : create({ accountId, user });
};

export const getProfile = () => {
	return ShedsAPI.get('api', `/settings`, {});
};

export const saveProfile = ({
	user,
}: {
	user: Partial<User>;
}): Promise<User> => {
	return ShedsAPI.put('api', `/settings`, {
		body: user,
	});
};

export const saveAccountSettings = ({
	account,
	isNew,
}: {
	account: Partial<any>;
	isNew?: boolean;
}): Promise<Me> => {
	return ShedsAPI.put('api', `/${account.id}/settings`, {
		body: { ...account, isNew },
	});
};

export const changePassword = ({
	newPassword,
}: {
	newPassword: string;
}): Promise<any> => {
	return ShedsAPI.put('api', `/settings/change-password`, {
		body: { newPassword },
	});
};

export const createLocation = ({
	accountId,
	location,
}: {
	accountId: number;
	location: Partial<any>;
}): Promise<any> => {
	return ShedsAPI.post('api', `/${accountId}/settings/location`, {
		body: location,
	});
};

export const updateLocation = ({
	accountId,
	location,
}: {
	accountId: number;
	location: Partial<any>;
}): Promise<any> => {
	return ShedsAPI.put('api', `/${accountId}/settings/location/${location.id}`, {
		body: location,
	});
};

export const saveLocation = ({
	accountId,
	location,
}: {
	accountId: number;
	location: Partial<any>;
}): Promise<any> => {
	return location.id
		? updateLocation({ accountId, location })
		: createLocation({ accountId, location });
};

export const deleteLocation = ({
	accountId,
	locationId,
}: {
	accountId: number;
	locationId: number;
}): Promise<any> => {
	return ShedsAPI.del(
		'api',
		`/${accountId}/settings/location/${locationId}`,
		{}
	);
};
