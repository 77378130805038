import { useEffect, useState } from 'react';
import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import * as productService from '../../services/product.service';
import { useDebounce } from './useDebounce';
import { useForm } from './useForm';

const getQuery = (search: string) => {
	return async (context: QueryFunctionContext) => {
		const params: any = {
			search,
			limit: 25,
		};
		return productService.zipcodeList(params);
	};
};

const useZipcodes = () => {
	const [searchQuery, setSearchQuery] = useState<string>();
	const {
		status,
		data,
		error,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		refetch,
	} = useInfiniteQuery<{
		data: any;
		metaData: { skip: number; totalRecords: number };
	}>(['zipcodes', searchQuery], getQuery(searchQuery as string), {
		getNextPageParam: (lastGroup) => lastGroup?.metaData?.skip || undefined,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
	});
	const { registerSubmit, value, onChange } = useForm<any>(
		{},
		{
			zip: {
				required: {
					message: 'Zip code is required.',
				},
				minLength: {
					value: 5,
					message: 'Zip code must be 5 digits.',
				},
				maxLength: {
					value: 5,
					message: 'Zip code must be 5 digits.',
				},
			},
		}
	);
	const debouncedSearchTerm = useDebounce(value.zip);

	useEffect(() => {
		if (debouncedSearchTerm) {
			setSearchQuery(debouncedSearchTerm);
		}
	}, [debouncedSearchTerm]);

	return {
		status,
		data,
		error,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		refetch,
		value,
		onChange,
		registerSubmit,
	};
};

export default useZipcodes;
