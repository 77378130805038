import { Transition } from '@headlessui/react';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { OpenClosedStates } from './OpenClosedStates';
import Overlay from './Overlay';

export enum ModalSizes {
	sm = 'max-w-sm',
	md = 'max-w-md',
	lg = 'max-w-lg',
	xl = 'max-w-xl',
	'2xl' = 'max-w-2xl',
	'3xl' = 'max-w-3xl',
	'4xl' = 'max-w-4xl',
	'5xl' = 'max-w-5xl',
	'6xl' = 'max-w-6xl',
	'7xl' = 'max-w-7xl',
	full = 'max-w-full',
}

type Props = {
	state: OpenClosedStates;
	children: JSX.Element;
	size?: ModalSizes;
	minHeight?: number;
	maxHeight?: number;
	noScroll?: boolean;
	close?: () => void;
};

const Modal: React.FC<Props> = ({
	state,
	children,
	size = ModalSizes.sm,
	minHeight,
	maxHeight,
	noScroll = false,
	close,
}) => {
	return (
		<div
			className={['fixed z-20 inset-0'].join(' ')}
			hidden={state === OpenClosedStates.Closed}
			// onMouseDown={close}
		>
			<div className="justify-center min-h-screen px-4 text-center block p-0">
				<Overlay show={state === OpenClosedStates.Open} />
				<span className="inline-block align-middle h-screen" />
				&#8203;
				<Transition
					show={state === OpenClosedStates.Open}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
					as={React.Fragment}
				>
					<div
						className={[
							'inline-block text-left transform transition-all align-middle w-full',
							size,
							'max-h-screen overflow-auto',
						].join(' ')}
						role="dialog"
						aria-modal="true"
						aria-labelledby="modal-headline"
						onMouseDown={(e) => e.stopPropagation()}
					>
						<div className="bg-white dark:bg-darkGray-700 rounded-lg my-8 shadow-xl">
							{noScroll ? (
								<div className="p-6">{children}</div>
							) : (
								<Scrollbars
									autoHide
									autoHideTimeout={1000}
									autoHideDuration={200}
									autoHeight
									autoHeightMin={minHeight}
									autoHeightMax={maxHeight}
									className="w-full"
								>
									<div className="p-6">{children}</div>
								</Scrollbars>
							)}
						</div>
					</div>
				</Transition>
			</div>
		</div>
	);
};

export default Modal;
