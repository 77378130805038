import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Button, { ButtonColors } from '../../common/Button';
import { ToastContext, ToastTypes } from '../../common/contexts/toast.context';
import { Input } from '../../common/form/Input';
import { Phone } from '../../common/form/Phone';
import { useForm } from '../../common/hooks/useForm';
import { User } from '../users/User.type';

const validation = {
	firstName: {
		required: {
			message: 'First name is required.',
		},
	},
	lastName: {
		required: {
			message: 'Last name is required.',
		},
	},
};

type Props = {
	defaultValues?: Partial<User>;
	save: (val: Partial<User>) => Promise<User | undefined>;
};

const ProfileForm: React.FC<Props> = ({ defaultValues, save }) => {
	const { createToast } = useContext(ToastContext);
	const navigate = useNavigate();

	const { value, isDirty, errors, onChange, registerSubmit, isSubmitting } =
		useForm<User>(defaultValues || {}, validation);

	const onSuccess = () => {
		createToast({
			title: 'Success!',
			description: <>Successfully saved user!</>,
			type: ToastTypes.Success,
			duration: 5000,
		});
		if (!value.id) {
			navigate('/users');
		}
	};

	const onFail = (err: any) => {
		const description = (
			<>{err?.response?.data || err?.response || 'Failed to save user!'}</>
		);
		createToast({
			title: 'Error!',
			description,
			type: ToastTypes.Fail,
			duration: 5000,
		});
	};

	const onSubmit = async (user: Partial<User>) => {
		return save(user);
	};

	return (
		<>
			<div className="grid grid-cols-1 sm:grid-cols-6 gap-4">
				<Input
					name="email"
					className="text-gray-400"
					label="Email *"
					value={value.email}
					disabled
					type="email"
				/>
				<Input
					name="firstName"
					className="sm:col-span-3"
					label="First name *"
					value={value.firstName}
					onChange={onChange('firstName')}
					errors={errors?.fieldErrors?.firstName}
				/>
				<Input
					name="lastName"
					className="sm:col-span-3"
					label="Last name *"
					value={value.lastName}
					onChange={onChange('lastName')}
					errors={errors?.fieldErrors?.lastName}
				/>
				<Phone
					name="phone"
					className="sm:col-span-6"
					label="Phone"
					value={value.phone}
					onChange={onChange('phone')}
					errors={errors?.fieldErrors?.phone}
				/>
			</div>
			<div className="w-full flex gap-4 justify-end mt-8">
				<Button
					text="Save"
					onClick={registerSubmit(onSubmit, { onSuccess, onFail })}
					color={ButtonColors.primary}
					loading={isSubmitting}
					disabled={!isDirty}
				/>
			</div>
		</>
	);
};

export default ProfileForm;
