import * as ShedsAPI from '../common/helpers/ShedsAPI';

const logGroupName = 'shedsforsale-admin-frontend-logs';

const logToCloudWatch = async (logStreamName: string, logMessage: any) => {
    try {
        const response = await ShedsAPI.post('api', '/log', {
            body: {
                logGroupName,
                logStreamName,
                logMessage,
            },
        });

        if (!response.ok) {
            throw new Error(`Error logging to CloudWatch: ${response.statusText}`);
        }
    } catch (error) {
        console.error('Failed to send log to backend:', error);
    }
};

const cloudwatchService = (pageName: string) => {
    return {
        info: (message: string) => logToCloudWatch(pageName, { level: 'info', message }),
        error: (message: string, error?: Error) => logToCloudWatch(pageName, { level: 'error', message, error }),
        warn: (message: string) => logToCloudWatch(pageName, { level: 'warn', message }),
        debug: (message: string) => logToCloudWatch(pageName, { level: 'debug', message }),
    };
};

export default cloudwatchService;
