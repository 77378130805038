import React, { useContext, useEffect, useState } from 'react';
import Button from '../../common/Button';
import { Input } from '../../common/form/Input';
import SearchableSelect from '../../common/form/SearchableSelect';
import { Toggle } from '../../common/form/Toggle';
import Modal, { ModalSizes } from '../../common/Modal';
import { OpenClosedStates } from '../../common/OpenClosedStates';
import { STATES } from '../../constants';
import { AdRequestForm } from './AdRequest';
import { useForm } from '../../common/hooks/useForm';
import { SelectZip } from '../../common/form/SelectZip';
import { Select } from '../../common/form/Select';
import { Phone } from '../../common/form/Phone';
import SingleImageUploader from '../../common/SingleImageUploader';
import { ToastContext, ToastTypes } from '../../common/contexts/toast.context';
import { isValidUrl } from '../../helpers';
import { isValid } from '../../common/helpers/isValid';

type Props = {
	request: Partial<AdRequestForm>;
	state: OpenClosedStates;
	close: () => void;
	onUpdate: (updatedRequest: Partial<AdRequestForm>) => Promise<void>;
};

const validation = {
	firstName: {
		required: {
			message: 'First name is required.',
		},
	},
	lastName: {
		required: {
			message: 'Last name is required.',
		},
	},
	companyName: {
		required: {
			message: 'Company name is required.',
		},
	},
	image: {
		required: {
			message: 'Advertisement Image is required.',
		},
	},
	contactEmail: {
		function: {
			value: (val: Partial<any>) => {
				if (!val.contactEmail && !val.contactPhone) {
					return 'Either contact email or contact phone is required.';
				}
				return false;
			},
		},
	},

	contactPhone: {
		function: {
			value: (val: Partial<any>) => {
				if (!val.contactPhone && !val.contactEmail) {
					return 'Either contact phone or contact email is required.';
				}
				return false;
			},
		},
	},
	adLink: {
		function: {
			value: (val: Partial<any>) => {
				if (val.adType === 'link' && !isValidUrl(val.adLink)) {
					var link = isValid(val.adLink)
						? 'and the format of the URL should be https://' +
						  val?.adLink?.replace('http://', '')
						: '';
					return (
						'A link to navigate the user to on ad click is required when you choose the ad type of link ' +
						link
					);
				}
				return false;
			},
		},
	},
	adContactFormEmail: {
		function: {
			value: (val: Partial<any>) => {
				if (val.adType === 'contactForm' && !val.adContactFormEmail) {
					return 'A valid email address which can receive customer inquiries is required when you choose the ad type of "Contact form".';
				}
				return false;
			},
		},
	},
};

const adTypeOptions = [
	{ text: 'Contact form', value: 'contactForm' },
	{ text: 'Link', value: 'link' },
];

const targetingTypeOptions = [
	{ text: 'Nationwide', value: 'nationWide' },
	{ text: 'State', value: 'state' },
	{ text: 'City', value: 'cityRadius' },
	// { text: 'Zip code list', value: 'zipCodeList' },
	{ text: 'Dealers', value: 'dealers' },
	{ text: 'Manufacturers', value: 'manufacturers' },
];

const AdRequestDetailsModal = ({ request, state, close, onUpdate }: Props) => {
	const {
		value,
		registerSubmit,
		patchValue,
		onChange,
		errors,
		isDirty,
		isSubmitting,
	} = useForm<Partial<AdRequestForm>>(request, validation);

	const { createToast } = useContext(ToastContext);

	const [active, setActive] = useState(false);

	const onSubmit = async (val: Partial<AdRequestForm>) => {
		if (!val.activeTimeInMonths) {
			val.activeTimeInMonths = 1;
		}
		await onUpdate(val);
	};

	const onSuccess = async (val: any) => {
		close();
		createToast({
			title: 'Success!',
			description: <>Successfully updated Advertisement!</>,
			type: ToastTypes.Success,
			duration: 5000,
		});
	};

	const onFail = async (val: any) => {
		createToast({
			title: 'Failed!',
			description: (
				<>There was an issue submitted your request! Please try again.</>
			),
			type: ToastTypes.Fail,
			duration: 5000,
		});
	};

	let timeout: any;
	const showTip = () => {
		timeout = setTimeout(() => {
			setActive(true);
		}, 400);
	};

	const hideTip = () => {
		clearInterval(timeout);
		setActive(false);
	};

	useEffect(() => {
		if (!value.excluded) {
			patchValue({
				excludezip: [],
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value.excluded]);

	return (
		<Modal state={state} size={ModalSizes.full} minHeight={500} close={close}>
			<form onSubmit={registerSubmit(onSubmit, { onSuccess, onFail })}>
				<div className="px-6 pt-6 bg-gray-50 h-full">
					<div className="grid grid-cols-1 sm:grid-cols-6 gap-4">
						<Input
							name="firstName"
							label="First name *"
							value={value.firstName}
							onChange={onChange('firstName')}
							className="col-span-4 sm:col-span-2"
							errors={errors.fieldErrors?.firstName}
						/>
						<Input
							name="lastName"
							label="Last name *"
							value={value.lastName}
							onChange={onChange('lastName')}
							className="col-span-4 sm:col-span-2"
							errors={errors.fieldErrors?.lastName}
						/>
						<Input
							name="companyName"
							label="Company name *"
							value={value.companyName}
							onChange={onChange('companyName')}
							className="col-span-4 sm:col-span-2"
							errors={errors.fieldErrors?.companyName}
						/>
						<Input
							name="contactEmail"
							label="Contact email *"
							value={value.contactEmail}
							onChange={onChange('contactEmail')}
							className="col-span-4 sm:col-span-2"
							errors={errors.fieldErrors?.contactEmail}
						/>
						<Phone
							name="contactPhone"
							label="Contact phone *"
							value={value.contactPhone}
							onChange={onChange('contactPhone')}
							className="col-span-4 sm:col-span-2"
							errors={errors.fieldErrors?.contactPhone}
						/>
						<Select
							name="adType"
							label="Ad type"
							options={adTypeOptions}
							value={value.adType}
							onChange={onChange('adType')}
							className="col-span-4 sm:col-span-2"
							limit={1}
							errors={errors.fieldErrors?.adType}
						/>
						{value.adType === 'link' ? (
							<Input
								name="adLink"
								label="Ad link *"
								value={value.adLink}
								onChange={onChange('adLink')}
								className="col-span-4 sm:col-span-2 lg:col-span-6"
								errors={errors.fieldErrors?.adLink}
							/>
						) : (
							<Input
								name="adContactFormEmail"
								label="Ad contact form email *"
								value={value.adContactFormEmail}
								onChange={onChange('adContactFormEmail')}
								className="col-span-4 sm:col-span-2"
								errors={errors.fieldErrors?.adContactFormEmail}
							/>
						)}
						<div className="col-span-6 grid grid-cols-6">
							<div className="flex items-center w-full col-span-2">
								<Toggle
									// name="targeted"
									label="Targeted ad"
									value={!!value.targeted}
									onChange={onChange('targeted')}
									className="col-span-4 sm:col-span-2 w-full"
									errors={errors.fieldErrors?.targeted}
								/>
							</div>
							{value.targeted ? (
								<>
									<Select
										name="targetingType"
										label="Targeting style"
										options={targetingTypeOptions}
										value={value.targetingType}
										onChange={(val?: any) => {
											patchValue({
												targetingValue: val === 'state' ? ['AL'] : null,
												targetingType: val,
											});
										}}
										className="col-span-4 sm:col-span-2 mr-2"
										limit={1}
										errors={errors.fieldErrors?.targetingType}
									/>

									{value.targetingType === 'state' ? (
										<div className="col-span-4 sm:col-span-2 mr-2">
											<SearchableSelect
												name="targetingValue"
												label="Targeted state"
												options={STATES.map((state) => {
													return { label: state.text, value: state.value };
												})}
												value={
													Array.isArray(value.targetingValue)
														? value.targetingValue
														: value.targetingValue.split(',')
												}
												onChange={onChange('targetingValue')}
												errors={errors.fieldErrors?.targetingValue}
												isMultiSelect={true}
											/>
										</div>
									) : value.targetingType === 'cityRadius' ? (
										<div className="col-span-4 sm:col-span-2 mr-2 relative">
											<div onMouseEnter={showTip} onMouseLeave={hideTip}>
												{active && (
													<div className="tooltip absolute w-40 z-30 right-0 inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm  dark:bg-gray-700">
														<span>Search Radius is 50</span>
													</div>
												)}
												<label className="block leading-5 sm:mt-px sm:pt-2 text-gray-900 text-sm font-medium mt-5">
													Target Location City by Zip
												</label>
												<SelectZip
													value={value}
													resultLabel="Selected zip codes for targeting"
													patchValue={(val: any) => {
														patchValue({
															targetingValue: [
																...(value?.targetingValue || []),
																val,
															],
														});
													}}
												>
													<div className="p-2">
														{(value?.targetingValue || []).map(
															(zip: string, i: number) => (
																<span
																	key={zip + i}
																	className="bg-gray-200 text-sm p-1 rounded-sm mr-2"
																>
																	<span>
																		<span className="mr-1">{zip}</span>
																		<span
																			className="font-bold text-md cursor-pointer hover:text-gray-400"
																			onClick={() => {
																				patchValue({
																					targetingValue:
																						value?.targetingValue?.filter(
																							(extantZip: any) =>
																								extantZip !== zip
																						),
																				});
																			}}
																		>
																			x
																		</span>
																	</span>
																</span>
															)
														)}
													</div>
												</SelectZip>
											</div>
											<Toggle
												// name="targeted"
												label="Excluded Zip"
												value={!!value.excluded}
												onChange={onChange('excluded')}
												className="col-span-4 mt-2 sm:col-span-1 w-1/2"
												errors={errors.fieldErrors?.excluded}
											/>
											{/**Excluded Zipcode */}
											{value.excluded ? (
												<div>
													<label className="block leading-5 sm:mt-px sm:pt-2 text-gray-900 text-sm font-medium mt-5">
														Exclude Target Location City by Zip
													</label>
													<SelectZip
														value={value}
														resultLabel="Excluded Selected zip codes for targeting"
														patchValue={(val: any) => {
															patchValue({
																excludezip: [...(value?.excludezip || []), val],
															});
														}}
													>
														<div className="p-2">
															{(value?.excludezip || []).map(
																(excludezip: string, i: number) => (
																	<span
																		key={excludezip + i}
																		className="bg-gray-200 text-sm p-1 rounded-sm mr-2"
																	>
																		<span>
																			<span className="mr-1">{excludezip}</span>
																			<span
																				className="font-bold text-md cursor-pointer hover:text-gray-400"
																				onClick={() => {
																					patchValue({
																						excludezip:
																							value?.excludezip?.filter(
																								(extantZip: any) =>
																									extantZip !== excludezip
																							),
																					});
																				}}
																			>
																				x
																			</span>
																		</span>
																	</span>
																)
															)}
														</div>
													</SelectZip>
												</div>
											) : (
												<></>
											)}
										</div>
									) : (
										<></>
									)}
								</>
							) : (
								<></>
							)}
						</div>
						<Input
							name="activeTimeInMonths"
							type="number"
							min="1"
							label="Ad timeline (in months) *"
							value={value.activeTimeInMonths || 1}
							onChange={onChange('activeTimeInMonths')}
							className="col-span-4"
							errors={errors.fieldErrors?.activeTimeInMonths}
						/>

						<div className="col-span-6 grid grid-cols-6">
							<SingleImageUploader
								name="image"
								label="Image"
								value={value.image}
								onChange={onChange('image')}
								className="col-span-4"
								imageClassName="h-44 w-95"
								scaleForAds
							/>
							{!value.image && (
								<div className="col-span-4 text-red-500 mt-2">
									{errors?.fieldErrors?.image.message}
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="col-span-6 flex justify-end p-4">
					<Button
						text="Close"
						loading={isSubmitting}
						onClick={() => {
							close();
						}}
					/>
					<span className="mr-5"></span>
					<Button
						text="Update request"
						type="submit"
						loading={isSubmitting}
						disabled={!isDirty}
						onClick={(e) => {
							e.stopPropagation();
						}}
					/>
				</div>
			</form>
		</Modal>
	);
};

export default AdRequestDetailsModal;
