import PlusCircleIcon from '@heroicons/react/outline/PlusCircleIcon';
import React, { useEffect } from 'react';
import { FormErrors } from '../../../common/types/ErrorResponse.type';
import ManufacturerColor from './ManufacturerColor';

type Props = {
	setColors: (arg: any) => void;
	colors?: any[];
	errors?: FormErrors;
	setColorsToDelete: (arg: any) => void;
};

const ManufacturerColors = ({
	setColors,
	colors,
	errors,
	setColorsToDelete,
}: Props) => {
	const addColor = () => {
		setColors((prev: any) => [
			...prev,
			{ primaryColor: '', specificColor: '' },
		]);
	};

	const removeColor = (index: number) => {
		if (colors?.length) {
			const removed = colors?.find((color, i) => i === index);
			setColorsToDelete((prev: any) => [...prev, removed.ID]);
			const update = [...colors?.filter((color, i) => index !== i)];
			setColors(update);
		}
	};

	useEffect(() => {
		if (!colors || colors.length < 1) {
			setColors([{ primaryColor: '', specificColor: '' }]);
		}
	}, [colors, setColors]);

	return (
		<>
			{colors?.map((color, i) => (
				<React.Fragment key={color.ID || i}>
					<div className="col-span-6 w-full">
						<ManufacturerColor
							color={color}
							index={i}
							errors={errors}
							setColors={setColors}
							removeColor={removeColor}
						/>
					</div>

					{i === colors.length - 1 && (
						<div className="col-span-6 flex justify-center">
							<PlusCircleIcon
								className="h-6 w-6 text-primary-500 cursor-pointer hover:text-primary-400 mb-4"
								onClick={addColor}
							/>
						</div>
					)}
				</React.Fragment>
			))}
		</>
	);
};

export default ManufacturerColors;
