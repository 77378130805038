import * as ShedsAPI from '../common/helpers/ShedsAPI';

export const list = async (
	accountId: number,
	params: {
		limit?: number;
		skip?: number;
	}
) => {
	return ShedsAPI.get('api', `/${accountId}/notifications`, {
		queryStringParameters: params,
	});
};

export const dismiss = async (accountId?: number, notificationId?: number) => {
	if (typeof accountId !== 'number' || !notificationId) {
		return;
	}
	return ShedsAPI.del(
		'api',
		`/${accountId}/notifications/${notificationId}`,
		{}
	);
};
