import { API, Auth } from 'aws-amplify';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './output.css';

Auth.configure({
	mandatorySignIn: true,
	region: process.env.REACT_APP_COGNITO_REGION,
	userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
	userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
	identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
	oauth: {
		domain: 'shedsforsale.auth.us-east-1.amazoncognito.com',
		scope: [
			'phone',
			'email',
			'profile',
			'openid',
			'aws.cognito.signin.user.admin',
		],
		redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT,
		redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT,
		responseType: 'code',
	},
});
API.configure({
	endpoints: [
		{
			name: 'api',
			endpoint: process.env.REACT_APP_API_URL,
			// eslint-disable-next-line
			custom_header: async () => {
				return {
					Authorization: `Bearer ${(await Auth.currentSession())
						.getIdToken()
						.getJwtToken()}`,
				};
			},
		},
		{
			name: 'api-noauth',
			endpoint: process.env.REACT_APP_API_URL,
		},
	],
});

const container = document.getElementById('app') as HTMLElement;
const root = createRoot(container);

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
