import { XIcon } from '@heroicons/react/outline';
import React from 'react';
import Modal, { ModalSizes } from '../../../common/Modal';
import { OpenClosedStates } from '../../../common/OpenClosedStates';
import {
	COLORS_MAIN,
	COLORS_METAL,
	COLORS_PAINT,
	COLORS_ROOF,
} from '../../../constants';
import { Product } from '../Product.type';

type Props = {
	setColors: (arg: any) => void;
	state: OpenClosedStates;
	setModalState: (arg: OpenClosedStates) => void;
	patchValue: (values: Partial<Product>) => void;
	currentKey?: keyof Product;
	colorOptions?: any[];
	roofingType?: string;
	sidingType?: string;
};

const ManufacturerColorModal = ({
	setColors,
	state,
	setModalState,
	patchValue,
	currentKey,
	colorOptions,
	roofingType,
	sidingType,
}: Props) => {
	const addColor = (color: any) => {
		if (currentKey === 'colorMainSpecific') {
			patchValue({
				colorMainSpecific: color.specificColor,
			});
			if (
				COLORS_MAIN[sidingType || 'painted'].find(
					(c) => c.value === color.primaryColor
				)
			) {
				patchValue({ colorMain: color.primaryColor });
			}
		} else if (currentKey === 'colorRoofSpecific') {
			patchValue({
				colorRoofSpecific: color.specificColor,
			});
			if (
				COLORS_ROOF[roofingType || 'shingle']?.find(
					(c) => c.value === color.primaryColor
				)
			) {
				patchValue({ colorRoof: color.primaryColor });
			}
		} else if (currentKey === 'colorTrimSpecific') {
			patchValue({
				colorTrimSpecific: color.specificColor,
			});
			if (
				sidingType === 'metal'
					? COLORS_METAL?.find((c) => c.value === color.primaryColor)
					: COLORS_PAINT.find((c) => c.value === color.primaryColor)
			) {
				patchValue({ colorTrim: color.primaryColor });
			}
		}
		setModalState(OpenClosedStates.Closed);
	};
	return (
		<Modal state={state} size={ModalSizes['lg']} minHeight={500}>
			<div>
				<XIcon
					className="h-5 w-5 absolute right-2 top-2 cursor-pointer"
					onClick={() => setModalState(OpenClosedStates.Closed)}
				/>
				<div className="w-full p-2 flex flex-col items-center">
					<h3 className="font-bold text-primary-500 text-xl">
						Manufacturer color options
					</h3>
					<div className="-mx-4 mt-6 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg w-full">
						<table className="min-w-full divide-y divide-gray-300">
							<thead>
								<tr>
									<th
										scope="col"
										className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
									>
										Specific color
									</th>
									<th
										scope="col"
										className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
									>
										Primary color
									</th>
									<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
										<span className="sr-only">Select</span>
									</th>
								</tr>
							</thead>
							<tbody>
								{colorOptions?.map((color, i) => (
									<tr key={color.id} className="border-b border-gray-200">
										<td className={'relative py-4 pl-4 sm:pl-6 pr-3 text-sm'}>
											<div className="font-medium text-gray-900">
												{color.specificColor}
											</div>
										</td>
										<td
											className={
												'px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
											}
										>
											{color.primaryColor}
										</td>
										<td
											className={
												'relative py-3.5 pl-3 pr-4 sm:pr-6 text-right text-sm font-medium'
											}
										>
											<button
												type="button"
												className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
												onClick={() => addColor(color)}
											>
												Select
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ManufacturerColorModal;
