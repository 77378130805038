import { Route, Routes } from 'react-router-dom';
import BlogDetail from './BlogDetail';
import BlogList from './BlogList';

const Blog = () => {
	return (
		<Routes>
			<Route path="/" element={<BlogList />} />
			<Route path=":id/*" element={<BlogDetail />} />
			<Route path="*" element={<span>no match</span>} />
		</Routes>
	);
};

export default Blog;
