import React, { ReactNode, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import useMe from '../common/hooks/useMe';
import DesktopSubsciptionAlert from './alerts/DesktopSubscriptionAlert';
import { MeContext } from './contexts/me.context';
import LoadingPage from './LoadingPage';
import SideNav from './nav/SideNav';
import TopNav from './nav/TopNav';
import Notifications from './notifications/Notifications';
import { OpenClosedStates } from './OpenClosedStates';
import RequestedAdsDisplay from './RequestedAdsDisplay';

type Props = {
	children: ReactNode;
};

const Container: React.FC<Props> = ({ children }) => {
	const { me, getMe, updateMe, replaceMe } = useMe();
	const [subAlertModalState, setSubAlertModalState] =
		useState<OpenClosedStates>(OpenClosedStates.Closed);
	const [showedModal, setShowedModal] = useState(false);

	const [sideNavState, setSideNavState] = useState<OpenClosedStates>(
		OpenClosedStates.Closed
	);

	const closeMenu = () => {
		setSideNavState(OpenClosedStates.Closed);
	};

	const openMenu = () => {
		setSideNavState(OpenClosedStates.Open);
	};

	useEffect(() => {
		getMe();
	}, [getMe]);

	useEffect(() => {
		if (
			me?.account &&
			me?.account?.subscription?.plan !== 'essentials' &&
			// !Object.keys(me?.account?.subscription?.options || {})?.length &&
			!showedModal
		) {
			setSubAlertModalState(OpenClosedStates.Open);
			setShowedModal(true);
		}
	}, [me, showedModal]);

	return me ? (
		<>
			<MeContext.Provider
				value={{
					me,
					updateMe,
					replaceMe,
				}}
			>
				<div className="h-screen overflow-hidden bg-gray-50 flex flex-col">
					<TopNav
						sideNavState={sideNavState}
						openMenu={openMenu}
						closeMenu={closeMenu}
					/>

					<div className="flex-1 flex overflow-hidden">
						<SideNav />

						<div className="flex-1 flex flex-col">
							<Scrollbars
								autoHide
								autoHideTimeout={1000}
								autoHideDuration={200}
							>
								{children}
							</Scrollbars>
							<div style={{ alignItems: 'center' }}>
								{me?.account?.id !== 0 && <RequestedAdsDisplay />}
							</div>
						</div>

						<aside className="hidden lg:block lg:flex-shrink-0">
							<Notifications />
						</aside>
					</div>
				</div>

				<DesktopSubsciptionAlert
					title=""
					subtitle=""
					acceptLink="/"
					rejectLink="/"
					icon={<></>}
					state={subAlertModalState}
					close={() => {
						setSubAlertModalState(OpenClosedStates.Closed);
					}}
					acceptMessage="Accept"
					rejectMessage="Later"
				/>
			</MeContext.Provider>
		</>
	) : (
		<LoadingPage />
	);
};

export default Container;
