import React, { useEffect, useState } from 'react';
import Header from '../../common/Header';
import List from '../../common/list/List';
import ListItem from '../../common/list/ListItem';
import { useLocation } from 'react-router';
import useDisplayAds from './hooks/useDisplayAds';
import Tabs from '../../common/Tabs';

const AdRequest = () => {
	const { isFetching, data } = useDisplayAds();
	const [dispAdsList, setDispAdsList] = useState<any>();
	const { pathname } = useLocation();

	useEffect(() => {
		const reduced = (data?.large || []).concat(data?.small || []);
		setDispAdsList(reduced);
	}, [data]);

	return (
		<>
			<Header
				title="Display ads"
				className="pb-6"
				rightSide={
					<Tabs
						tabs={[
							{
								name: `Requested ads`,
								to: `/ad-requests`,
								active: pathname === `/ad-requests`,
							},
							{
								name: 'Display ads',
								to: `/display-ads-list`,
								active: pathname === `/display-ads-list`,
							},
						]}
					/>
				}
			/>
			<>
				<List isFetching={isFetching}>
					{dispAdsList?.map((row: any, rowI: number) => (
						<div key={rowI} className="w-full overflow-x-auto">
							<ListItem index={rowI}>
								<div className="flex flex-row items-center justify-between space-y-2 space-x-4 w-full cursor-pointer">
									<div className="min-w-0 w-full">
										<div className="relative group flex items-center w-full">
											<div className="flex items-center justify-between space-x-4 w-full">
												<div className="flex items-center">
													<img
														src={row.url}
														alt="Thumbnail"
														className="w-16 h-16 object-contain"
													/>

													<div className="flex-1 flex flex-col gap-1 px-4 w-40 text-sm h-8 mb-2 md:mb-0 mr-10">
														<label className="font-bold cursor-pointer">
															Link
														</label>
														<h2 className="text-xs font-medium leading-4 w-full">
															{row.link}
														</h2>
													</div>
												</div>
												<div className="flex-1 flex-col gap-1 px-4 w-40 text-sm h-8 mb-2 md:mb-0 hidden md:block">
													<label className="font-bold cursor-pointer">
														Views
													</label>
													<h2 className="text-xs font-medium leading-4">
														{row.views}
													</h2>
												</div>
												<div className="flex-1 flex-col gap-1 px-4 w-40 text-sm h-8 hidden md:block">
													<label className="font-bold cursor-pointer">
														Clicks
													</label>
													<h2 className="text-xs font-medium leading-4">
														{row.clicks || 0}
													</h2>
												</div>
											</div>
										</div>
									</div>
								</div>
							</ListItem>
						</div>
					))}
				</List>
			</>
		</>
	);
};

export default AdRequest;
