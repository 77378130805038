import { useContext } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { MeContext } from '../../../common/contexts/me.context';
import { ErrorResponse } from '../../../common/types/ErrorResponse.type';
import * as statsService from '../../../services/stats.service';

const getanyQuery = (accountId?: number) => {
	return async (context: QueryFunctionContext) => {
		if (typeof accountId !== 'number') {
			return;
		}
		return statsService.get({ accountId });
	};
};

const useStats = (accountId?: number) => {
	const { me } = useContext(MeContext);
	const { data, isFetching, refetch, remove, isFetched } = useQuery<
		any,
		ErrorResponse
	>(
		['stats', accountId || me.account?.id],
		getanyQuery(accountId || me.account?.id),
		{
			refetchOnWindowFocus: false,
			retry: false,
		}
	);

	return {
		isFetching,
		data,
		refetch,
		remove,
		isFetched,
	};
};

export default useStats;
