import Auth from '@aws-amplify/auth';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';
import Button, { ButtonColors } from '../../common/Button';
import { Checkbox } from '../../common/form/Checkbox';
import { Input } from '../../common/form/Input';
import { emailValidator } from '../../common/form/validators/emailValidator';
import { useForm } from '../../common/hooks/useForm';
import { FormError } from '../../common/types/ErrorResponse.type';
import FederatedLogin from './FederatedLogin';
import logo from '../../images/logo.png';
import { OpenClosedStates } from '../../common/OpenClosedStates';
import SupportModal from './SupportModal';

type Props = {
	onAuth: Dispatch<SetStateAction<object | undefined>>;
};

const Login: React.FC<Props> = ({ onAuth }) => {
	const [user, setUser] = useState<any>();
	const { value, registerSubmit, onChange, errors, isSubmitting } = useForm<{
		email: string;
		password: string;
		newPassword: string;
		rememberMe: boolean;
	}>(
		{ email: '', password: '', rememberMe: false },
		{
			email: {
				required: {
					message: 'Email is required.',
				},
				pattern: emailValidator,
			},
			password: {
				required: {
					message: 'Password is required.',
				},
			},
			newPassword: {
				function: {
					value: (val) => {
						if (
							user?.challengeName === 'NEW_PASSWORD_REQUIRED' &&
							!val.newPassword
						) {
							return 'New password is required.';
						}
						return false;
					},
				},
			},
		}
	);
	const [serverErrors, setServerErrors] = useState<FormError>();
	const [supportModal, setSupportModal] = useState<OpenClosedStates>(
		OpenClosedStates.Closed
	);

	const onSubmit = async () => {
		setServerErrors(undefined);
		if (user?.challengeName === 'NEW_PASSWORD_REQUIRED' && value.newPassword) {
			const res = await Auth.completeNewPassword(user, value.newPassword);
			return res;
		} else {
			setUser(undefined);
			const res = await Auth.signIn({
				username: value.email || '',
				password: value.password || '',
			});
			onAuth(res);
			if (res.challengeName) {
				setUser(res);
			}
			return res;
		}
	};

	const onFail = async (err: any) => {
		const message = err?.message || '';
		setServerErrors({
			message: message.replace(/PreAuthentication failed with error /g, ''),
		});
	};

	const onSuccess = async (res: CognitoUserInterface) => {
		if (value.rememberMe) {
			res.getCachedDeviceKeyAndPassword();
			res.setDeviceStatusRemembered();
		}
	};

	return (
		<div className="min-h-screen bg-primary-500 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<img
					className="mx-auto h-24 w-auto"
					src={logo}
					alt="ShedsForSale.com"
				/>
			</div>

			<div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
					<form
						className="space-y-6"
						onSubmit={registerSubmit(onSubmit, { onSuccess, onFail })}
					>
						<>
							{user?.challengeName === 'NEW_PASSWORD_REQUIRED' ? (
								<div>
									<Input
										label="New password"
										name="newPassword"
										onChange={onChange('newPassword')}
										type="password"
										value={value.newPassword}
										errors={errors.fieldErrors?.newPassword || serverErrors}
									/>
								</div>
							) : (
								<>
									<div>
										<Input
											label="Email address"
											name="email"
											onChange={onChange('email')}
											value={value.email}
											errors={errors.fieldErrors?.email}
										/>
										<Input
											label="Password"
											name="password"
											onChange={onChange('password')}
											type="password"
											value={value.password}
											errors={errors.fieldErrors?.password || serverErrors}
										/>
									</div>
									<div className="flex items-center justify-between">
										<div className="flex items-center">
											<Checkbox
												name="rememberMe"
												value={value.rememberMe}
												onChange={onChange('rememberMe')}
												label="Remember me"
											/>
										</div>

										<div className="text-sm">
											<Link
												to="/forgot-password"
												className="font-medium text-primary-600 hover:text-primary-500"
											>
												Forgot your password?
											</Link>
										</div>
									</div>
								</>
							)}
							<div className="flex gap-2">
								<Button
									type="submit"
									text="Sign in"
									loading={isSubmitting}
									fullWidth
								/>

								<Button
									fullWidth
									text="Contact support"
									color={ButtonColors.plainWithBorder}
									onClick={() => setSupportModal(OpenClosedStates.Open)}
								/>
							</div>
						</>
					</form>
				</div>
				<p className="mt-6 text-center text-sm text-gray-700 max-w">
					Or{' '}
					<Link to="/sign-up" className="font-medium hover:text-white">
						sign up for a new account
					</Link>
				</p>
			</div>
			<SupportModal modalState={supportModal} setModalState={setSupportModal} />
		</div>
	);
};

export default Login;
