import dayjs from 'dayjs';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button, { ButtonColors } from '../../../common/Button';
import {
	ToastContext,
	ToastTypes,
} from '../../../common/contexts/toast.context';
import { Input } from '../../../common/form/Input';
import { useForm } from '../../../common/hooks/useForm';
import { Manufacturer } from '../Manufacturer.type';
import utc from 'dayjs/plugin/utc';
import ManufacturerColors from './ManufacturerColors';
dayjs.extend(utc);

export type Colors = {
	id?: number;
	primaryColor?: string;
	specificColor?: string;
};

const validation = {
	name: {
		required: {
			message: 'Name is required.',
		},
	},
};

type Props = {
	defaultValues?: Partial<Manufacturer>;
	save: (val: Partial<Manufacturer>) => Promise<Manufacturer | undefined>;
	accept: (pendingAccountId: number) => void;
	reject: (pendingAccountId: number) => void;
	approve: () => void;
	isApproving: boolean;
	del: () => void;
	isDeleting: boolean;
};

const ManufacturerGeneral: React.FC<Props> = ({
	defaultValues,
	save,
	accept,
	reject,
	approve,
	isApproving,
	del,
	isDeleting,
}) => {
	const { createToast } = useContext(ToastContext);
	const navigate = useNavigate();
	const {
		value,
		isDirty,
		errors,
		onChange,
		registerSubmit,
		isSubmitting,
		patchValue,
	} = useForm<Manufacturer>(
		{ ...defaultValues, colorsToDelete: [] } || {},
		validation
	);
	const [colors, setColors] = useState<Colors[]>();
	const [colorsToDelete, setColorsToDelete] = useState<number[]>([]);

	useEffect(() => {
		console.log({ value });
	}, [value]);

	useEffect(() => {
		if (defaultValues) {
			patchValue(defaultValues);
			if (defaultValues.colors?.length) {
				setColors(defaultValues.colors);
			} else {
				setColors([{ primaryColor: '', specificColor: '' }]);
			}
		}
	}, [patchValue, defaultValues]);

	useMemo(() => {
		patchValue({ colors: colors });
	}, [colors, patchValue]);

	useMemo(() => {
		patchValue({ colorsToDelete: colorsToDelete });
	}, [colorsToDelete, patchValue]);

	const onSuccess = () => {
		createToast({
			title: 'Success!',
			description: <>Successfully saved manufacturer!</>,
			type: ToastTypes.Success,
			duration: 5000,
		});
		if (!value.id) {
			navigate('/manufacturers');
		}
	};

	const onFail = (err: any) => {
		const description = (
			<>
				{err?.response?.data || err?.response || 'Failed to save manufacturer!'}
			</>
		);
		createToast({
			title: 'Error!',
			description,
			type: ToastTypes.Fail,
			duration: 5000,
		});
	};

	const onSubmit = async (manufacturer: Partial<Manufacturer>) => {
		return save(manufacturer);
	};

	const onClickCancel = () => {
		navigate(-1);
	};

	return (
		<>
			{defaultValues?.account && (
				<div className="w-full flex justify-end mb-6">
					<Link to={`/accounts/${defaultValues?.account}`}>
						<Button text="Go to account" color={ButtonColors.plainWithBorder} />
					</Link>
				</div>
			)}
			{!!defaultValues?.pendingClaims?.length && (
				<>
					<h3 className="text-lg leading-6 font-medium text-gray-900">
						Pending approvals
					</h3>
					<div className="mt-5 mb-6 gap-4 grid grid-cols-1">
						{defaultValues?.pendingClaims?.map((pendingAccount) => (
							<div
								className="rounded-md bg-white px-6 py-5 sm:flex sm:items-start sm:justify-between"
								key={pendingAccount.id}
							>
								<div className="sm:flex sm:items-start">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-5 w-5 text-primary-500"
										viewBox="0 0 20 20"
										fill="currentColor"
									>
										<path
											fillRule="evenodd"
											d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
											clipRule="evenodd"
										/>
									</svg>
									<div className="mt-3 sm:mt-0 sm:ml-4">
										<div className="text-sm font-medium text-gray-900">
											{pendingAccount.name}
										</div>
										<div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
											<div>
												Requested on{' '}
												{dayjs
													.utc(pendingAccount.createDate)
													.local()
													.format('M/D/YYYY @ hh:mma')}
											</div>
										</div>
									</div>
								</div>
								<div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0 gap-x-2 flex">
									<Button
										color={ButtonColors.plain}
										text="Reject"
										onClick={() => reject(pendingAccount.id)}
									/>
									<Button
										color={ButtonColors.plainWithBorder}
										text="Accept"
										onClick={() => accept(pendingAccount.id)}
									/>
								</div>
							</div>
						))}
					</div>
				</>
			)}
			<div className="grid grid-cols-1 sm:grid-cols-6 gap-4">
				<Input
					name="name"
					className="col-span-6"
					label="Name *"
					value={value.name}
					onChange={onChange('name')}
					errors={errors?.fieldErrors?.name}
				/>
				<Input
					name="address"
					className="col-span-6"
					label="Address"
					value={value.address}
					onChange={onChange('address')}
					errors={errors?.fieldErrors?.address}
				/>
				<Input
					name="city"
					className="col-span-6 sm:col-span-3"
					label="City"
					value={value.city}
					onChange={onChange('city')}
					errors={errors?.fieldErrors?.city}
				/>
				<Input
					name="state"
					className="col-span-6 sm:col-span-1"
					label="State"
					value={value.state}
					onChange={onChange('state')}
					errors={errors?.fieldErrors?.state}
				/>
				<Input
					name="zip"
					className="col-span-6 sm:col-span-2"
					label="Zip code"
					value={value.zip}
					onChange={onChange('zip')}
					errors={errors?.fieldErrors?.zip}
				/>
			</div>
			<div className="relative mt-6">
				<div className="absolute inset-0 flex items-center" aria-hidden="true">
					<div className="w-full border-t border-gray-300" />
				</div>
				<div className="relative flex justify-center">
					<span className="px-2 bg-gray-50 text-sm text-gray-500">Colors</span>
				</div>
			</div>
			<div className="w-full grid grid-cols-1 sm:grid-cols-6 gap-4 mt-6">
				<ManufacturerColors
					setColors={setColors}
					colors={colors}
					setColorsToDelete={setColorsToDelete}
				/>
			</div>
			<div className="w-full flex justify-end mt-8">
				<div className="flex space-x-4 justify-end">
					<Button
						text="Cancel"
						onClick={onClickCancel}
						color={ButtonColors.transparent}
					/>
					{defaultValues?.pendingManufacturer && (
						<Button
							text="Approve"
							onClick={approve}
							color={ButtonColors.transparent}
							loading={isApproving}
							disabled={isSubmitting || isDeleting}
						/>
					)}
					{!!defaultValues?.id && (
						<Button
							text="Delete"
							onClick={del}
							color={ButtonColors.red}
							loading={isDeleting}
							disabled={isSubmitting || isApproving}
						/>
					)}
					<Button
						text={!!value.id ? 'Save' : 'Create'}
						onClick={registerSubmit(onSubmit, { onSuccess, onFail })}
						color={ButtonColors.primary}
						loading={isSubmitting}
						disabled={!isDirty || isDeleting}
					/>
				</div>
			</div>
		</>
	);
};

export default ManufacturerGeneral;
