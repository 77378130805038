import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ManufacturerContainer from './detail/ManufacturerContainer';
import ManufacturerList from './ManufacturerList';

const Manufacturers = () => {
	return (
		<Routes>
			<Route path="/" element={<ManufacturerList />} />
			<Route path=":manufacturerId/*" element={<ManufacturerContainer />} />
			<Route path="*" element={<span>no match</span>} />
		</Routes>
	);
};

export default Manufacturers;
