import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AccountContainer from './detail/AccountContainer';
import AccountList from './AccountList';

const Accounts = () => {
	return (
		<Routes>
			<Route path="/" element={<AccountList />} />
			<Route path=":accountId/*" element={<AccountContainer />} />
			<Route path="*" element={<span>no match</span>} />
		</Routes>
	);
};

export default Accounts;
