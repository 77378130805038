import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/outline';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import EndOfList from '../list/EndOfList';
import { FormError } from '../types/ErrorResponse.type';
import { Input } from './Input';
import { FieldErrors } from './FieldErrors';

export type SelectOption = {
	value: any;
	text: string;
};

export type SelectProps = {
	name: string;
	className: string;
	label: string;
	value?: any;
	limit?: number;
	onChange: (val: any) => void;
	errors?: FormError;
	options?: any[];
	showLabel?: boolean;
	disabled?: boolean;
	alwaysReturnArray?: boolean;
	clearable?: boolean;
	valueProp?: string;
	textProp?: string;
	isFetchingNextPage?: boolean;
	isFetching: boolean;
	fetchNextPage?: (options?: FetchNextPageOptions | undefined) => Promise<
		InfiniteQueryObserverResult<
			{
				data: any[];
				lastKey?: string;
				skip?: number;
			},
			unknown
		>
	>;
	hasNextPage?: boolean;
	onChangeSearch: (searchTerm: string) => void;
	searchTerm?: string;
	onAdd?: () => void;
	steadyDisplay?: string;
};

export const Autocomplete: React.FC<SelectProps> = ({
	name,
	className,
	label,
	value,
	limit,
	onChange,
	errors,
	options = [],
	showLabel = true,
	disabled = false,
	alwaysReturnArray = false,
	clearable = false,
	valueProp = 'value',
	textProp = 'text',
	isFetchingNextPage,
	isFetching,
	fetchNextPage,
	hasNextPage,
	onChangeSearch,
	searchTerm,
	onAdd,
	steadyDisplay = '',
}) => {
	const [currentDisplay, setCurrentDisplay] = useState('None');
	console.log(currentDisplay);
	const [open, setOpen] = useState(false);
	const searchRef = useRef<HTMLInputElement>(null);
	const ref = useRef<HTMLDivElement>(null);
	useOnClickOutside(ref, () => setOpen(false));

	useEffect(() => {
		if (steadyDisplay) {
			setCurrentDisplay(steadyDisplay);
		} else if (value?.length && limit !== 1) {
			setCurrentDisplay(`${value?.length} items selected`);
		} else if (limit === 1 && value) {
			const valueDisplay = options?.find((opt) => opt[valueProp] === value);

			setCurrentDisplay(valueDisplay?.[textProp] || '1 item selected');
		} else {
			setCurrentDisplay('None');
		}
	}, [options, value, textProp, valueProp, limit, steadyDisplay]);

	const onChangeSelections = (val: any) => {
		let hasAdd: boolean;
		if (Array.isArray(val)) {
			hasAdd = val.some((item: string) => item === '$add');
		} else {
			hasAdd = val === '$add';
		}
		if (hasAdd && onAdd) {
			onAdd();
			setOpen(false);
		} else {
			onChange(val);
		}
	};

	return (
			<div className={`${className} ${errors ? 'border-red-300 text-red-900' : ''}`}>			
			<Listbox
				value={value}
				onChange={(val) => {
					if (limit === 1) {
						onChangeSelections(val);
						setOpen(false);
					} else {
						const isThere = value.includes(val);
						const newValue = isThere
							? value.filter((item: any) => item !== val)
							: [...value, val];
						onChangeSelections(newValue);
					}
				}}
			>
				<>
					{showLabel && (
						<Listbox.Label
							htmlFor={name}
							className="block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2"
						>
							{label}
						</Listbox.Label>
					)}
					<div className="mt-0 relative" ref={ref}>
						{open ? (
							<Input
								name="search"
								className={['w-full', open ? 'block' : 'hidden'].join(' ')}
								ref={searchRef}
								onChange={onChangeSearch}
								value={searchTerm}
								loading={isFetching}
								errors={errors}
							/>
						) : (
							<div
								onClick={() => {
									setOpen(true);
									setTimeout(() => {
										searchRef.current?.focus();
									}, 0);
								}}
								className={open ? 'hidden' : 'block'}
							>
								<div className={`bg-white relative w-full border rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm ${
									errors ? 'border-red-300' : 'border-gray-300'
								}`}>									
								<span className="flex gap-2 truncate h-5">
										{options.map(
											(opt, key) =>
												value?.find(
													(val: number) => val === opt[valueProp]
												) && (
													<span
														key={key}
														className="inline-flex items-center rounded-full bg-primary-400 py-0.5 pl-2 pr-0.5 text-xs font-medium text-gray-700"
														onClick={(e) => e.stopPropagation()}
													>
														{opt[textProp]}
														<button
															type="button"
															className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-700 hover:bg-primary-300 hover:text-primary-500 focus:bg-primary-500 focus:text-white focus:outline-none"
															onClick={() => {
																const isThere = value.includes(opt[valueProp]);
																const newValue = isThere
																	? value.filter(
																			(item: any) => item !== opt[valueProp]
																	  )
																	: [...value, opt[valueProp]];
																onChangeSelections(newValue);
															}}
														>
															<svg
																className="h-2 w-2"
																stroke="currentColor"
																fill="none"
																viewBox="0 0 8 8"
															>
																<path
																	strokeLinecap="round"
																	strokeWidth="1.5"
																	d="M1 1l6 6m0-6L1 7"
																/>
															</svg>
														</button>
													</span>
												)
										)}
									</span>
									<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
										<SelectorIcon
											className="h-5 w-5 text-gray-400"
											aria-hidden="true"
										/>
									</span>
								</div>
							</div>
						)}

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options
								static
								className={`absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm ${
									errors ? 'border-red-300' : ''
								  }`}
							>
								{options.map((option, i: number) => {
									const isActive =
										limit === 1
											? value === option?.[valueProp]
											: value?.includes(option?.[valueProp]);
									return (
										<Listbox.Option
											key={option?.[valueProp]}
											className={({ active }) =>
												[
													isActive
														? 'text-white bg-primary-500'
														: 'text-gray-900',
													'cursor-default select-none relative py-2 pl-3 pr-9',
												].join(' ')
											}
											value={option?.[valueProp]}
										>
											{({ selected, active }) => (
												<>
													<span
														className={[
															isActive ? 'font-semibold' : 'font-normal',
															'block truncate',
														].join(' ')}
													>
														{option?.[textProp]}
													</span>

													{isActive ? (
														<span
															className={[
																'text-white',
																'absolute inset-y-0 right-0 flex items-center pr-4',
															].join(' ')}
														>
															<CheckIcon
																className="h-5 w-5"
																aria-hidden="true"
															/>
														</span>
													) : null}
												</>
											)}
										</Listbox.Option>
									);
								})}
								<EndOfList
									isFetching={isFetching}
									isFetchingNextPage={!!isFetchingNextPage}
									fetchNextPage={fetchNextPage}
									hasNextPage={hasNextPage}
									small
								/>
							</Listbox.Options>
						</Transition>
					</div>
				</>
			</Listbox>
			{errors && <FieldErrors errors={errors} />} 
		</div>
	);
};
