import React, { useContext, useEffect, useState } from 'react';
import Button, { ButtonColors } from '../common/Button';
import { ToastContext, ToastTypes } from '../common/contexts/toast.context';
import { Input } from '../common/form/Input';
import { Select } from '../common/form/Select';
import { useForm } from '../common/hooks/useForm';
import Modal from '../common/Modal';
import { OpenClosedStates } from '../common/OpenClosedStates';
import { STATES } from '../constants';
import { Manufacturer } from '../pages/manufacturers/Manufacturer.type';
import * as manufacturerService from '../services/manufacturer.service';

type ManufacturerForm = {
	id?: number | '$add';
	name: string;
	address: string;
	city: string;
	state: string;
	zip: string;
};

const validation = {
	name: {
		required: {
			message: 'Name is required.',
		},
		pattern: {
			value: new RegExp(/^[a-zA-Z0-9\s]*$/),
			message: 'Cannot contain special characters',
		},
	},
	address: {
		required: {
			message: 'Address is required.',
		},
	},
	city: {
		required: {
			message: 'City is required.',
		},
	},
	state: {
		required: {
			message: 'State is required.',
		},
	},
	zip: {
		required: {
			message: 'Zip code is required.',
		},
	},
};

type Props = {
	state: OpenClosedStates;
	setState: React.Dispatch<React.SetStateAction<OpenClosedStates>>;
	save: (val: Manufacturer) => void;
	isDataFetching: boolean;
};

const ManufacturerAddModal: React.FC<Props> = ({
	state,
	setState,
	save,
	isDataFetching,
}) => {
	const { createToast } = useContext(ToastContext);
	const [isSuccessAction, setIsSuccessAction] = useState(false);
	const { value, isDirty, errors, onChange, isSubmitting, registerSubmit } =
		useForm<ManufacturerForm, 'SUCCESS'>({}, validation);

	useEffect(() => {
		if (!isDataFetching && isSuccessAction) {
			setState(OpenClosedStates.Closed);
			setIsSuccessAction(false);
		}
	}, [isDataFetching, setState, isSuccessAction]);

	const onSuccess = (res: Manufacturer) => {
		save(res);
		setIsSuccessAction(true);
		createToast({
			title: 'Success!',
			description: <>Successfully added manufacturer!</>,
			type: ToastTypes.Success,
			duration: 5000,
		});
	};

	const onFail = () => {
		createToast({
			title: 'Error!',
			description: <>Failed to add manufacturer!</>,
			type: ToastTypes.Fail,
			duration: 5000,
		});
	};

	const onSubmit = async (val: Partial<ManufacturerForm>) => {
		return manufacturerService.createRequest({
			manufacturer: val,
		});
	};

	return (
		<Modal state={state} minHeight={550}>
			<form>
				<div>
					<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 dark:bg-darkGray-500">
						<svg
							className="h-6 w-6 text-gray-600 dark:text-gray-400"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
							/>
						</svg>
					</div>
					<div className="mt-3 text-center sm:mt-5">
						<h3
							className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100"
							id="modal-headline"
						>
							Add manufacturer
						</h3>
						<div className="mt-2">
							<p className="text-sm leading-5 text-gray-500 dark:text-gray-400">
								Enter manufacturer information
							</p>
						</div>
						<div className="text-left pt-6 grid grid-cols-2 gap-2">
							<Input
								label="Name *"
								value={value.name}
								onChange={onChange('name')}
								name="name"
								className="col-span-2"
								errors={errors.fieldErrors?.name}
							/>
							<Input
								label="Address *"
								value={value.address}
								onChange={onChange('address')}
								name="address"
								className="col-span-2"
								errors={errors.fieldErrors?.address}
							/>
							<Input
								label="City *"
								value={value.city}
								onChange={onChange('city')}
								name="city"
								className="col-span-2"
								errors={errors.fieldErrors?.city}
							/>
							<Select
								label="State *"
								value={value.state}
								onChange={onChange('state')}
								name="state"
								className="col-span-2 sm:col-span-1"
								errors={errors.fieldErrors?.state}
								options={STATES}
								limit={1}
							/>
							<Input
								label="Zip *"
								value={value.zip}
								onChange={onChange('zip')}
								name="zip"
								className="col-span-2 sm:col-span-1"
								errors={errors.fieldErrors?.zip}
							/>
						</div>
					</div>
				</div>
				<div className="grid-cols-1 mt-6 grid gap-3 grid-flow-row-dense sm:grid-cols-2">
					<Button
						text="Close"
						onClick={() => setState(OpenClosedStates.Closed)}
						fullWidth
						color={ButtonColors.plain}
						className="border border-gray-300 dark:border-darkGray-500"
					/>
					<Button
						text="Save"
						onClick={registerSubmit(onSubmit, { onSuccess, onFail })}
						fullWidth
						loading={isSubmitting || isDataFetching}
						disabled={!isDirty || isSubmitting || isDataFetching}
					/>
				</div>
			</form>
		</Modal>
	);
};

export default ManufacturerAddModal;
