import React from 'react';
import Tabs from '../../../common/Tabs';

type Props = {
	active: string;
	accountId: string;
};

const AccountTabs: React.FC<Props> = ({ active, accountId }) => {
	return (
		<Tabs
			tabs={[
				{
					name: 'General',
					to: `/accounts/${accountId}`,
					active: active === `/accounts/${accountId}`,
				},
				{
					name: 'Locations',
					to: `/accounts/${accountId}/locations`,
					active: active === `/accounts/${accountId}/locations`,
				},
				{
					name: 'Users',
					to: `/accounts/${accountId}/users`,
					active: active.startsWith(`/accounts/${accountId}/users`),
				},
				{
					name: 'Integrations',
					to: `/accounts/${accountId}/api-keys`,
					active: active === `/accounts/${accountId}/api-keys`,
				},
			]}
		/>
	);
};

export default AccountTabs;
