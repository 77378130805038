import 'cleave.js/dist/addons/cleave-phone.us';
import Cleave from 'cleave.js/react';
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { FormError } from '../types/ErrorResponse.type';
import { ErrorIcon } from './ErrorIcon';
import { FieldErrors } from './FieldErrors';
import { CALLING_CODES } from '../vars/CallingCodes';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

type Props = {
    name: string;
    className: string;
    label: string;
    value?: string;
    onChange: (val: string) => void;
    errors?: FormError;
    disabled?: boolean;
};

export const Phone: React.FC<Props> = ({
    name,
    className,
    label,
    value,
    onChange,
    errors,
    disabled,
}) => {
    const [number, setNumber] = useState<string>('');
    const [phoneRegionCode, setPhoneRegionCode] = useState<string>('+1'); // Default to US

    useEffect(() => {
        if (value) {
			const phoneNumber = parsePhoneNumberFromString(value);
            if (phoneNumber) {
                setPhoneRegionCode(`+${phoneNumber.countryCallingCode}`);
                setNumber(phoneNumber.nationalNumber);
            }
        }
    }, [value]);

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (event.target.tagName === 'SELECT') {
            const newRegionCode = event.target.value;
            setPhoneRegionCode(newRegionCode);
            const phoneNumber = parsePhoneNumberFromString(`${newRegionCode} ${number}`);
            if (phoneNumber) {
                setNumber(phoneNumber.nationalNumber);
                onChange(`${newRegionCode} ${phoneNumber.nationalNumber}`);
            } else {
                setNumber(''); 
                onChange('');
            }
        } else {
            const val = event.target.value;
            const cleanedNumber = val.replace(/\s/g, '');
            setNumber(val);
            onChange(`${phoneRegionCode} ${cleanedNumber}`);
        }
    };

    const preventInvalidChars = (event: KeyboardEvent<HTMLInputElement>) => {
        const { key } = event;
        const isInvalid = (!number && key === '1');
        if (isInvalid) {
            event.preventDefault();
        }
    };

    return (
        <div className={className}>
            <div>
                <label htmlFor={name} className="block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2">
                    {label}
                </label>
            </div>
            <div>
                <div className="relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 flex items-center">
                        <select
                            aria-label="Country"
                            className="form-select h-full py-0 pl-3 pr-6 border-transparent bg-transparent text-gray-500 sm:text-sm sm:leading-5 w-28"
                            onChange={handleChange}
                            value={phoneRegionCode}
                        >
                            {CALLING_CODES.map((code) => (
                                <option value={code} key={code}>
                                    {code}
                                </option>
                            ))}
                            <option value="">Other</option>
                        </select>
                    </div>
                    <Cleave
                        data-cy={`select-${name}`}
                        value={number}
                        onChange={handleChange}
                        onKeyPress={preventInvalidChars}
                        options={{
                            blocks: phoneRegionCode === '+1' ? [3, 3, 4] : [15],
                            numericOnly: true,
                        }}
                        className={[
                            'block w-full border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5 pl-28',
                            errors
                                ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red'
                                : '',
                        ].join(' ')}
                        disabled={disabled}
                    />
                    {errors && <ErrorIcon />}
                </div>
                <FieldErrors errors={errors} />
            </div>
        </div>
    );
};
