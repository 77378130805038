import { MailIcon, PhoneIcon, XIcon } from '@heroicons/react/outline';
import { useContext } from 'react';
import Button, { ButtonColors } from '../../common/Button';
import { ToastContext, ToastTypes } from '../../common/contexts/toast.context';
import { Input } from '../../common/form/Input';
import { Select } from '../../common/form/Select';
import { Textarea } from '../../common/form/Textarea';
import { formatPhone } from '../../common/helpers/format';
import { useForm } from '../../common/hooks/useForm';
import Modal, { ModalSizes } from '../../common/Modal';
import { OpenClosedStates } from '../../common/OpenClosedStates';
import { supportForm } from '../../services/dealer.service';

export type ContactForm = {
	firstName: string;
	lastName: string;
	businessName: string;
	businessAddress: string;
	associatedManufacturers: string;
	phone: string;
	email: string;
	message: string;
	preferredRespMethod: 'phone' | 'email' | 'text';
};

type Props = {
	modalState: OpenClosedStates;
	setModalState: (arg: OpenClosedStates) => void;
};

const SupportModal = ({ modalState, setModalState }: Props) => {
	const { value, onChange, errors, registerSubmit, isSubmitting, reset } =
		useForm<ContactForm>(
			{
				preferredRespMethod: 'email',
			},
			{
				email: {
					function: {
						value: (value: Partial<ContactForm>) => {
							if (value.preferredRespMethod === 'email' && !value.email) {
								return 'Email is required if preferred method of response if email.';
							} else {
								return false;
							}
						},
					},
				},
				phone: {
					function: {
						value: (value: Partial<ContactForm>) => {
							if (
								(value.preferredRespMethod === 'phone' ||
									value.preferredRespMethod === 'text') &&
								!value.phone
							) {
								return 'Phone is required if preferred method of response if phone or text.';
							} else {
								return false;
							}
						},
					},
					pattern: {
						value: new RegExp(
							/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
						),
						message: 'Provide a valid phone number',
					},
				},
				firstName: {
					required: {
						message: 'Name is required.',
					},
				},
				lastName: {
					required: {
						message: 'Name is required.',
					},
				},
				message: {
					required: {
						message: 'Message is required.',
					},
				},
			}
		);
	const { createToast } = useContext(ToastContext);

	const onSubmit = async (val: Partial<ContactForm>) => {
		await supportForm(val);
	};

	const onSuccess = () => {
		createToast({
			title: 'Success!',
			description: <>Submitted request for support!</>,
			type: ToastTypes.Success,
			duration: 5000,
		});
		reset();
		setModalState(OpenClosedStates.Closed);
	};

	const onFail = () => {
		createToast({
			title: 'Failed!',
			description: <>Failed to submit request!</>,
			type: ToastTypes.Fail,
			duration: 5000,
		});
	};

	return (
		<Modal state={modalState} minHeight={640} size={ModalSizes['3xl']}>
			<div className="flex flex-col">
				<XIcon
					className="h-5 w-5 cursor-pointer self-end"
					onClick={() => setModalState(OpenClosedStates.Closed)}
				/>
				<div className="p-4 h-full flex flex-col gap-2">
					<MailIcon className="h-10 w-10 self-center text-primary-400" />
					<h3 className="text-sm text-gray-500">
						We are here to help you! If you are a dealer needing technical
						support or have suggestions that will make ShedsForSale.com a better
						experience for you and your customers, please use the form below.
					</h3>
					<form onSubmit={registerSubmit(onSubmit, { onSuccess, onFail })}>
						<div className="grid grid-cols-4 gap-2 h-full">
							<Input
								name="firstName"
								label="First name *"
								className="col-span-2"
								value={value.firstName}
								onChange={onChange('firstName')}
								errors={errors?.fieldErrors?.firstName}
							/>
							<Input
								name="lastName"
								label="Last name *"
								className="col-span-2"
								value={value.lastName}
								onChange={onChange('lastName')}
								errors={errors?.fieldErrors?.lastName}
							/>
							<Input
								name="businessName"
								label="Business name"
								className="col-span-2"
								value={value.businessName}
								onChange={onChange('businessName')}
								errors={errors?.fieldErrors?.businessName}
							/>
							<Input
								name="businessAddress"
								label="Business address"
								className="col-span-2"
								value={value.businessAddress}
								onChange={onChange('businessAddress')}
								errors={errors?.fieldErrors?.businessAddress}
							/>
							<Input
								name="associatedManufacturers"
								label="Associated manufacturers"
								className="col-span-2"
								value={value.associatedManufacturers}
								onChange={onChange('associatedManufacturers')}
								errors={errors?.fieldErrors?.associatedManufacturers}
							/>
							<Input
								name="phone"
								label="Phone"
								type="tel"
								className="col-span-2"
								value={value.phone}
								onChange={onChange('phone')}
								errors={errors?.fieldErrors?.phone}
							/>
							<Input
								name="email"
								label="Email"
								type="email"
								className="col-span-2"
								value={value.email}
								onChange={onChange('email')}
								errors={errors?.fieldErrors?.email}
							/>
							<Select
								name="preferredRespMethod"
								label="Preferred method of response *"
								value={value.preferredRespMethod}
								onChange={onChange('preferredRespMethod')}
								className="col-span-2"
								options={[
									{ text: 'Email', value: 'email' },
									{ text: 'Phone', value: 'phone' },
									{ text: 'Text', value: 'text' },
								]}
								limit={1}
								errors={errors?.fieldErrors?.preferredRespMethod}
								endOfList={false}
							/>
							<Textarea
								name="message"
								value={value.message}
								onChange={onChange('message')}
								label="Message *"
								className="col-span-4"
								errors={errors?.fieldErrors?.message}
							/>
							<div className="flex flex-row justify-end w-full col-span-4 mt-2">
								<div className="flex gap-1 items-center w-full">
									<PhoneIcon className="h-5 w-5" />
									<span className="text-primary-500">
										{formatPhone('+1 6188456112')}
									</span>
								</div>
								<Button
									text="Submit"
									color={ButtonColors.primary}
									type="submit"
									disabled={isSubmitting}
									loading={isSubmitting}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default SupportModal;
