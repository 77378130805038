import Auth from '@aws-amplify/auth';
import { EyeIcon } from '@heroicons/react/outline';
import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../common/Button';
import { Input } from '../../common/form/Input';
import { useForm } from '../../common/hooks/useForm';
import { PasswordValidationMeter } from '../../common/PasswordValidationMeter';
import logo from '../../images/logo.png';
import { forgotPassword } from '../../services/user.service';

export enum ForgotPasswordStep {
	sendCode,
	setPassword,
	welcomeSent,
}

const ForgotPassword: React.FC = () => {
	const navigate = useNavigate();
	const passwordRef = useRef<HTMLInputElement>(null);
	const { value, registerSubmit, onChange, errors, isSubmitting } = useForm(
		{ email: '', code: '', password: '' },
		{
			email: {
				function: {
					value: (val) => {
						if (step === ForgotPasswordStep.sendCode && !val.email) {
							return 'Email is required.';
						}
						return false;
					},
				},
			},
			code: {
				function: {
					value: (val) => {
						if (step === ForgotPasswordStep.setPassword && !val.code) {
							return 'Enter the verification code you received in your email.';
						}
						return false;
					},
				},
			},
			password: {
				function: {
					value: (val) => {
						if (step === ForgotPasswordStep.setPassword && !val.password) {
							return 'Password is required.';
						}
						return false;
					},
				},
			},
		}
	);
	const [step, setStep] = useState<ForgotPasswordStep>(
		ForgotPasswordStep.sendCode
	);

	const onSubmit = async () => {
		if (!value.email) {
			return;
		}
		if (step === ForgotPasswordStep.sendCode) {
			try {
				const res = await forgotPassword(value.email);
				setStep(ForgotPasswordStep.setPassword);
				return res;
			} catch (err) {
				console.error({ err });
			}
		} else if (step === ForgotPasswordStep.setPassword) {
			if (!value.code || !value.password) {
				return;
			}
			try {
				const res = await Auth.forgotPasswordSubmit(
					value.email,
					value.code,
					value.password
				);
				await Auth.signIn(value.email, value.password);
				navigate('/');
				return res;
			} catch (err) {
				console.error({ err });
			}
		}
	};

	return (
		<div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<img
					className="mx-auto h-24 w-auto"
					src={logo}
					alt="ShedsForSale.com"
				/>
				<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
					Reset your password
				</h2>
				<p className="mt-2 text-center text-sm text-gray-600 max-w">
					Remember?{' '}
					<Link
						to="/"
						className="font-medium text-primary-600 hover:text-primary-500"
					>
						Then just sign in
					</Link>
				</p>
			</div>

			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
					<form className="space-y-6" onSubmit={registerSubmit(onSubmit, {})}>
						{step === ForgotPasswordStep.sendCode ? (
							<Input
								label="Email address"
								name="email"
								onChange={onChange('email')}
								value={value.email}
								errors={errors.fieldErrors?.email}
							/>
						) : (
							<div>
								<Input
									label="Verification code"
									name="code"
									onChange={onChange('code')}
									value={value.code}
									errors={errors.fieldErrors?.code}
								/>
								<Input
									label="New password"
									name="password"
									onChange={onChange('password')}
									type="password"
									value={value.password}
									errors={errors.fieldErrors?.password}
									autoComplete="new-password"
									ref={passwordRef}
									icon={
										<EyeIcon
											className="text-gray-300 hover:text-gray-400 h-5 w-5 cursor-pointer"
											onClick={() => {
												if (passwordRef.current?.type) {
													passwordRef.current.type =
														passwordRef.current.type === 'password'
															? 'text'
															: 'password';
												}
											}}
										/>
									}
									clickIcon
								/>
								{value.password && (
									<PasswordValidationMeter
										password={value.password}
										errors={errors.fieldErrors?.password}
									/>
								)}
							</div>
						)}
						<Button
							type="submit"
							text={
								step === ForgotPasswordStep.sendCode
									? 'Send code'
									: 'Change password'
							}
							loading={isSubmitting}
							fullWidth
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
