import { PhotographIcon } from '@heroicons/react/outline';
import { fromImage } from '../common/helpers/Imtool.index';
import React, { ChangeEvent, useContext, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { UploadStatus } from '../pages/products/Product.type';
import uploadService from '../services/upload.service';
import { MeContext } from './contexts/me.context';
import Loading from './Loading';

type Props = {
	label: string;
	name: string;
	value?: string;
	onChange: (val: string) => void;
	maxSize?: number;
	className?: string;
	imageClassName?: string;
	scaleForAds?: boolean;
};

const SingleImageUploader: React.FC<Props> = ({
	label,
	name,
	value,
	onChange,
	maxSize = 800,
	className = '',
	imageClassName = '',
	scaleForAds = false,
}) => {
	const { me } = useContext(MeContext);
	const input = useRef<HTMLInputElement>(null);
	const [isLoading, setIsLoading] = useState(false);

	const onChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files as any;
		if (!files?.length) {
			return;
		}
		try {
			setIsLoading(true);
			const file = await processImage(files[0]);

			const url = await uploadService.upload(
				me?.account.id,
				file,
				'image/jpeg'
			);
			onChange(url);
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	const processImage = async (file: any) => {
		const filename = `${name}-${v4()}`;
		const extension = file.name.split('.').pop();
		const url = `${process.env.REACT_APP_S3_URL}/accounts/${me?.account.id}/${filename}.${extension}`;
		let finalImage = file;

		if (file.type !== 'image/gif') {
			const image = await fromImage(file);
			if (scaleForAds) {
				finalImage = await image
					.type(file.type)
					.quality(1)
					.scale(380, 180)
					.thumbnail(maxSize)
					.toFile(`${filename}.${extension}`);
			} else {
				finalImage = await image
					.type(file.type)
					.quality(1)
					.thumbnail(maxSize)
					.toFile(`${filename}.${extension}`);
			}
		}

		Object.assign(finalImage, {
			preview: URL.createObjectURL(finalImage),
			uploadStatus: UploadStatus.new,
			url,
			extension,
			filename,
		});

		return finalImage;
	};

	return (
		<div className={className}>
			<label
				htmlFor={name}
				className="block text-sm font-medium leading-5 text-gray-900  sm:mt-px sm:pt-2"
			>
				{label}
			</label>
			<div className="mt-1 flex items-center">
				<span
					className={`${imageClassName} relative rounded-md overflow-hidden bg-gray-200`}
				>
					{isLoading && (
						<div className="absolute h-full w-full flex items-center justify-center">
							<Loading color="text-primary-500" />
						</div>
					)}
					{value ? (
						<img
							style={
								scaleForAds
									? { width: '380px', height: '180px', objectFit: 'cover' }
									: {
											maxWidth: '300px',
											maxHeight: '300px',
											objectFit: 'cover',
									  }
							}
							className={
								imageClassName !== ''
									? 'rounded-lg shadow-lg'
									: 'object-cover w-full h-full rounded-lg shadow-lg'
							}
							src={value}
							alt={label}
						/>
					) : (
						<PhotographIcon className="h-full w-full text-gray-400" />
					)}
				</span>
				<div className="flex gap-2 ml-4">
					<button
						type="button"
						className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
						onClick={() => {
							input.current?.click();
						}}
					>
						Upload
					</button>
					{value && (
						<button
							type="button"
							className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
							onClick={() => onChange('')}
						>
							Remove
						</button>
					)}
				</div>
				<input
					ref={input}
					onChange={onChangeFile}
					multiple={false}
					type="file"
					hidden
				/>
			</div>
		</div>
	);
};

export default SingleImageUploader;
