import React from 'react';
import { SubOptions } from '../accounts/Account.type';

type Props = {
	subValue: SubOptions;
	options: any[];
	totalPrice?: number;
};

const freeOptions = ['5 listings', '1 location', '1 image per listing'];

const SignUpPlanInformation = ({ subValue, options, totalPrice }: Props) => {
	return (
		<div className="border-l-2 border-1-gray-200 px-4 h-72 w-64 sm:flex flex-col hidden">
			<span className="font-bold text-lg mb-4">Plan summary:</span>
			{freeOptions.map((opt, i) => (
				<div key={i} className="flex justify-between text-sm text-gray-500">
					<span>{opt}</span>
					<span>Free</span>
				</div>
			))}
			{options.map((opt, i) =>
				!!subValue[opt.val as unknown as keyof SubOptions] && (
					<div key={i} className="flex justify-between text-sm text-gray-500">
						<span>{opt.title}</span>
						<span>
							$
							{opt.val === 'additionalLocations'
								? (subValue.additionalLocations || 0) * 10
								: opt.price}
						</span>
					</div>
				)
			)}
			{totalPrice ? (
				<div className="flex justify-between mt-4 font-bold">
					<span>Total:</span>
					<span>${totalPrice}</span>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

export default SignUpPlanInformation;
