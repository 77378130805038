import { isValid } from './common/helpers/isValid';
export const upperCaseFirstLetter = (string: string) => {
	if (!string) {
		return;
	}
	return (
		string.slice(0, 1).toUpperCase() +
		string.slice(1, string.length).toLowerCase()
	);
};

export const isJson = (str: string) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

export const isValidUrl = (value: any): boolean => {
	return (
		isValid(value) &&
		/^(https:\/\/)([a-z]+\.)?[a-z0-9\-]+(\.[a-z]{2,})(\/[^ "]+)?(\?.*)?(\/)?$/i.test(value)
	);
};
