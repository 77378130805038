import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ImageUploaderImage from './ImageUploaderImage';

type Props = {
	image: any;
	onClickDelete: (url: string) => void;
	extraIcons?: JSX.Element;
};

const ImageUploaderImageSortable: React.FC<Props> = ({
	image,
	onClickDelete,
	extraIcons,
}) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({
		id: String(image?.url),
		transition: {
			duration: 150, // milliseconds
			easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
		},
	});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		opacity: isDragging ? '0.2' : '1',
		cursor: 'grab',
	};

	return (
		<ImageUploaderImage
			attributes={attributes}
			listeners={listeners}
			style={style}
			ref={setNodeRef}
			image={image}
			onClickDelete={onClickDelete}
			extraIcons={extraIcons}
		/>
	);
};

export default ImageUploaderImageSortable;
