import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Button, { ButtonColors } from '../../../common/Button';
import { MeContext } from '../../../common/contexts/me.context';
import {
	ToastContext,
	ToastTypes,
} from '../../../common/contexts/toast.context';
import { Input } from '../../../common/form/Input';
import { Phone } from '../../../common/form/Phone';
import { Select } from '../../../common/form/Select';
import { emailValidator } from '../../../common/form/validators/emailValidator';
import { useForm } from '../../../common/hooks/useForm';
import { ROLES } from '../../../constants';
import { User } from '../User.type';
import UserToggleActive from './UserToggleActive';

const validation = {
	email: {
		required: {
			message: 'Email is required.',
		},
		pattern: emailValidator,
	},
	phone: {
		pattern: {
			value: new RegExp(/^\+?\d{1,3}[-\s]?(\(?\d{1,4}?\)?[-\s]?\d{1,4}[-\s]?\d{1,9})$/),
			message: 'Provide a valid phone number',
		},
	},	
	role: {
		required: {
			message: 'Role is required.',
		},
	},
	firstName: {
		required: {
			message: 'First name is required.',
		},
	},
	lastName: {
		required: {
			message: 'Last name is required.',
		},
	},
};

type Props = {
	defaultValues?: Partial<User>;
	save: (val: Partial<User>) => Promise<User | undefined>;
};

const UserGeneral: React.FC<Props> = ({
	defaultValues,
	save,
}) => {
	const { createToast } = useContext(ToastContext);
	const navigate = useNavigate();
	const { me } = useContext(MeContext);
	const {
		value,
		isDirty,
		errors,
		onChange,
		registerSubmit,
		isSubmitting,
		patchValue,
	} = useForm<User>(defaultValues || {}, validation);

	useEffect(() => {
		if (defaultValues) {
			patchValue(defaultValues);
		}
	}, [patchValue, defaultValues]);

	const onSuccess = () => {
		createToast({
			title: 'Success!',
			description: <>Successfully saved user!</>,
			type: ToastTypes.Success,
			duration: 5000,
		});
		if (!value.id) {
			navigate(-1);
		}
	};

	const onFail = (err: any) => {
		const description = (
			<>{err?.response?.data || err?.response || 'Failed to save user!'}</>
		);
		createToast({
			title: 'Error!',
			description,
			type: ToastTypes.Fail,
			duration: 5000,
		});
	};

	const onSubmit = async (user: Partial<User>) => {
		return save(user);
	};

	const onClickCancel = () => {
		navigate(-1);
	};

	const toggleStatus = () => {
		return save({ id: value.id, active: !!!value.active });
	};

	return (
		<>
			<form onSubmit={registerSubmit(onSubmit, { onSuccess, onFail })}>
				<div className="grid grid-cols-1 sm:grid-cols-6 gap-4">
					<Input
						name="email"
						className={[
							'sm:col-span-3',
							me.role !== 'superAdmin' ? 'text-gray-400' : '',
						].join(' ')}
						label="Email *"
						value={value.email}
						onChange={onChange('email')}
						errors={errors?.fieldErrors?.email}
						type="email"
						disabled={me.role !== 'superAdmin' && me.role !== 'groupAdmin'}/>
					{value.role === 1 ? (
						<Select
							name="role"
							className="sm:col-span-3"
							label="Role *"
							options={[{ text: 'SuperAdmin', value: 1 }]}
							value={value.role}
							onChange={onChange('role')}
							errors={errors?.fieldErrors?.role}
							limit={1}
							disabled
						/>
					) : (
						<Select
							name="role"
							className="sm:col-span-3"
							label="Role *"
							options={ROLES}
							value={value.role}
							onChange={onChange('role')}
							errors={errors?.fieldErrors?.role}
							limit={1}
						/>
					)}
					<Input
						name="firstName"
						className="sm:col-span-3"
						label="First name *"
						value={value.firstName}
						onChange={onChange('firstName')}
						errors={errors?.fieldErrors?.firstName}
					/>
					<Input
						name="lastName"
						className="sm:col-span-3"
						label="Last name *"
						value={value.lastName}
						onChange={onChange('lastName')}
						errors={errors?.fieldErrors?.lastName}
					/>
					<Phone
						name="phone"
						className="sm:col-span-6"
						label="Phone"
						value={value.phone}
						onChange={onChange('phone')}
						errors={errors?.fieldErrors?.phone}
					/>
					<Select
						name="locations"
						className="sm:col-span-6"
						label={value.role === 2 ? `Notification locations` : `Locations`}
						options={
							(me?.account.locations || [])
								.filter(location => (location as any).active) 
								.map((location: any) => ({
									text: `${location.address} ${location.city}, ${location.state} ${location.zip}`,
									value: location.id,
								}))
						}
						value={value.locations}
						onChange={onChange('locations')}
						errors={errors?.fieldErrors?.locations}
						hint={
							value.role === 3
								? `Locations are required for users that are not admins to be able to use the system.`
								: value.role === 2
								? `To narrow the locations you would like notifications for, select the ones you would like to receive.`
								: ``
						}
					/>
				</div>
				<div
					className={[
						'w-full flex mt-8',
						value.id ? 'justify-between' : 'justify-end',
					].join(' ')}
				>
					{value.id && (
						<UserToggleActive active={!!value.active} confirm={toggleStatus} />
					)}
					<div className="flex space-x-4 justify-end">
						<Button
							text="Cancel"
							onClick={onClickCancel}
							color={ButtonColors.transparent}
						/>
						<Button
							text={!!value.id ? 'Save' : 'Create'}
							type="submit"
							color={ButtonColors.primary}
							loading={isSubmitting}
							disabled={!isDirty}
						/>
					</div>
				</div>
			</form>
		</>
	);
};

export default UserGeneral;
