import { useContext, useEffect, useState } from 'react';
import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import { MeContext } from '../../../common/contexts/me.context';
import { useDebounce } from '../../../common/hooks/useDebounce';
import { LIST_LIMIT } from '../../../constants';
import * as userService from '../../../services/user.service';
import { User } from '../User.type';

const getUsersQuery = (accountId?: number) => {
	return async (context: QueryFunctionContext) => {
		if (typeof accountId !== 'number') {
			return;
		}
		const search = context.queryKey[1] as string;
		return userService.list(accountId, {
			skip: context.pageParam,
			limit: LIST_LIMIT,
			search,
		});
	};
};

const useUsers = (id?: number) => {
	const { me } = useContext(MeContext);
	const [queryKey, setQueryKey] = useState<[string, string | undefined]>([
		'users',
		undefined,
	]);
	const {
		status,
		data,
		error,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		refetch,
	} = useInfiniteQuery<{
		data: User[];
		metaData: { skip: number; totalRecords: number };
	}>(queryKey, getUsersQuery(id || me.account?.id), {
		getNextPageParam: (lastGroup) => lastGroup?.metaData?.skip || undefined,
		refetchOnWindowFocus: false,
	});
	const [searchTerm, setSearchTerm] = useState<string>();
	const debouncedSearchTerm = useDebounce(searchTerm);

	useEffect(() => {
		setQueryKey(['users', debouncedSearchTerm]);
	}, [debouncedSearchTerm]);

	const onSearch = (val: string) => {
		setSearchTerm(val);
	};

	return {
		status,
		data,
		error,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		refetch,
		onSearch,
	};
};

export default useUsers;
