import { MinusCircleIcon } from '@heroicons/react/outline';
import React, { useEffect } from 'react';
import { Input } from '../../../common/form/Input';
import { Select } from '../../../common/form/Select';
import { useForm } from '../../../common/hooks/useForm';
import { FormErrors } from '../../../common/types/ErrorResponse.type';
import { COLORS_ALL } from '../../../constants';
import { Colors } from './ManufacturerGeneral';

type Props = {
	color?: Colors;
	errors?: FormErrors;
	index: number;
	setColors: (arg: any) => void;
	removeColor: (arg: any) => void;
};

const ManufacturerColor = ({
	color,
	errors,
	index,
	setColors,
	removeColor,
}: Props) => {
	const { value, onChange } = useForm<Colors>(color || {});

	useEffect(() => {
		setColors((prev: any) =>
			prev.map((color: Colors, i: number) => {
				if (index !== i) {
					return color;
				} else {
					return {
						...value,
					};
				}
			})
		);
	}, [index, setColors, value]);

	return (
		<div className="flex gap-2 items-center">
			<Select
				name="primaryColor"
				className="w-1/2"
				label="Primary color"
				options={COLORS_ALL}
				value={value?.primaryColor}
				onChange={onChange('primaryColor')}
				errors={errors?.fieldErrors?.colors}
				limit={1}
			/>
			<Input
				name="specificColor"
				className="w-1/2"
				label="Specific color"
				value={value?.specificColor}
				onChange={onChange('specificColor')}
				errors={errors?.fieldErrors?.colors}
			/>
			<MinusCircleIcon
				className="h-5 w-5 self-end mb-2 text-gray-400 hover:text-gray-300 cursor-pointer"
				onClick={() => removeColor(index)}
			/>
		</div>
	);
};

export default ManufacturerColor;
