import { useContext, useState } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { MeContext } from '../../../common/contexts/me.context';
import { SelectOption } from '../../../common/form/Autocomplete';
import { ErrorResponse } from '../../../common/types/ErrorResponse.type';
import * as productService from '../../../services/product.service';
import { Product } from '../Product.type';

const getProductQuery = (
	productId: number | 'new',
	manufacturers: SelectOption[],
	freeDeliveryRange: number,
	deliveryInstructions: string,
	accountId?: number
) => {
	return async (context: QueryFunctionContext) => {
		if (productId === 'new' || !accountId) {
			const defaults = {
				active: true,
				type: 'new',
				freeDeliveryRange,
				deliveryInstructions,
			} as Product;
			if (manufacturers?.length === 1) {
				defaults.manufacturer = manufacturers[0].value;
			}
			return defaults;
		}
		return productService.get({ accountId, productId });
	};
};

const useProduct = (id?: string) => {
	const { me } = useContext(MeContext);
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [isDeleting, setIsDeleting] = useState(false);
	const [isMarking, setIsMarking] = useState(false);
	const [isCloning] = useState(false);
	const { data, isFetching, refetch, remove, isFetched } = useQuery<
		Product,
		ErrorResponse
	>(
		['product', id],
		getProductQuery(
			id === 'new' ? id : Number(id),
			me.account.manufacturers,
			me.account.freeDeliveryRange,
			me.account.deliveryInstructions,
			me.account.id
		),
		{
			refetchOnWindowFocus: false,
			retry: false,
		}
	);

	const save = async (val: Partial<Product>) => {
		if (!me.account.id) {
			return;
		}
		const res = await productService.save({
			accountId: me.account.id,
			product: val,
		});
		queryClient.setQueryData(['product', id], res);
		navigate(`/products/${res?.id}`);
		return res;
	};

	const clone = async () => {
		if (!data?.id || !me.account.id) {
			return;
		}
		const res = await productService.clone({
			accountId: me.account.id,
			productId: data.id,
		});
		navigate(`/products/${res?.id}`);
	};

	const del = async (sold = false) => {
		if (id && me.account.id) {
			setIsDeleting(true);
			try {
				await productService.del(me.account.id, id, sold);
				setIsDeleting(false);
				navigate('/products');
			} catch (err) {
				setIsDeleting(false);
			}
		}
	};

	const mark = async (val: boolean) => {
		if (id && me.account.id) {
			setIsMarking(true);
			try {
				const res = await productService.mark(me.account.id, id, val);
				queryClient.setQueryData(['product', id], res);
				setIsMarking(false);
				return true;
			} catch (err) {
				setIsMarking(false);
				return false;
			}
		}
		return false;
	};

	return {
		isFetching,
		data,
		save,
		refetch,
		remove,
		isFetched,
		del,
		mark,
		clone,
		isDeleting,
		isMarking,
		isCloning,
	};
};

export default useProduct;
