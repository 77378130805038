import { useContext, useEffect, useState } from 'react';
import Button, { ButtonColors } from '../../common/Button';
import { MeContext } from '../../common/contexts/me.context';
import { ToastContext, ToastTypes } from '../../common/contexts/toast.context';
import { Input } from '../../common/form/Input';
import { useForm } from '../../common/hooks/useForm';
import { Account } from '../accounts/Account.type';

const validation = {
	customerShedAppUrl: {
		required: { message: 'Data Feed Base URL is required.' },
		pattern: {
			value: new RegExp(
				/^(https?:\/\/)([a-z0-9-]+\.)*[a-z0-9-]+\.[a-z]+$/i
			),
			message:
				'Please provide a valid domain-only URL. (e.g., https://example.com)',
		},
	},
};

enum States {
	waiting = 'waiting',
	loading = 'loading',
	generating = 'generating',
	updating = 'updating',
	revoking = 'revoking',
}

type Params = {
	userData?: any;
	hasApiAccess?: boolean;
	isFetchingUserData?: boolean;
	save?: (val: Partial<Account>) => Promise<Account | undefined>;
};

function IntegrationsShedApp({
	userData,
	hasApiAccess,
	isFetchingUserData,
	save,
}: Params) {
	const { me, updateMe } = useContext(MeContext);
	const { createToast } = useContext(ToastContext);

	const [selectedAPIKey, setSelectedAPIKey] = useState(null);
	const [currentState, setCurrentState] = useState(States.waiting);

	const { value, onChange, registerSubmit, errors, isSubmitting, patchValue } =
		useForm(
			{
				customerShedAppUrl: userData?.customerShedAppUrl,
			},
			validation
		);

	useEffect(() => {
		patchValue({
			customerShedAppUrl: userData?.customerShedAppUrl
		});
	}, [userData?.customerShedAppUrl]);

	async function handleRemoveShedAppUrl() {
		if (save) {
			await save({
				...userData,
				customerShedAppUrl: null,
			})
		}
	}

	async function onSubmit() {
		if (userData?.id === undefined || userData?.id === null) {
			return console.error('no Account Id present');
		}
		const acc = {
			...userData,
			customerShedAppUrl: value?.customerShedAppUrl,
		};
		if (save) {
			await save(acc);
		}
		return;
	};
		
	function onSuccess() {
		updateMe('account', {
			...me.account,
			customerShedAppUrl: value?.customerShedAppUrl as string,
		});
		createToast({
			title: 'Success!',
			description: <>Successfully saved URL!</>,
			type: ToastTypes.Success,
			duration: 5000,
		});
	};

	function onFail(e: any) {
		let errorMsg = e?.response?.data?.message;
		createToast({
			title: 'Error!',
			description: errorMsg ? errorMsg : 'Failed to save URL!',
			type: ToastTypes.Fail,
			duration: 5000,
		});
	};

	return (
		<div className="px-6 pt-6 bg-gray-50 h-full">
			<div className="bg-white overflow-hidden sm:rounded-lg sm:shadow">
				<div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
					<div className="-ml-4 -mt-2 flex-wrap sm:flex-nowrap">
						<div className="ml-4 mt-2">
							<h3 className="text-lg leading-6 font-medium text-gray-900">
								Shed App
							</h3>

							<form
								onSubmit={registerSubmit(onSubmit, {
									onSuccess,
									onFail,
								})}
							>
								<div className="grid grid-cols-1 sm:grid-cols-6 gap-4">
									<div className="flex flex-col col-span-4 sm:col-span-3.5">
										<Input
											name="shedAppUrl"
											value={value.customerShedAppUrl as string}
											onChange={onChange('customerShedAppUrl')}
											label="Data Feed Base URL"
											disabled={isFetchingUserData || !hasApiAccess}
										/>
										<div className="text-sm h-5 text-red-500">
											{errors.fieldErrors?.customerShedAppUrl?.message}
										</div>
									</div>

									<div className="flex items-center sm:col-span-1 mt-2">
										<Button
											type="submit"
											color={ButtonColors.primary}
											text="Save"
											disabled={isSubmitting || !hasApiAccess}
										/>
										<div className="ml-2" />
										<Button
											onClick={handleRemoveShedAppUrl}
											color={ButtonColors.red}
											text="Remove"
											disabled={isSubmitting || !hasApiAccess}
										/>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default IntegrationsShedApp;
