import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon, XIcon } from '@heroicons/react/outline';
import React, { Fragment, useRef, useState } from 'react';
import { useOnClickOutside } from '../hooks/useOnClickOutside';

type Props = {
	name: string;
	className: string;
	label: string;
	options?: any[];
	showLabel?: boolean;
	disabled?: boolean;
	clearable?: boolean;
	valueProp?: string;
	textProp?: string;
	type?: string;
	onAdd?: () => void;
	patchValue: (arg: Partial<any>) => void;
};

const Dropdown = ({
	className,
	name,
	label,
	options,
	showLabel,
	disabled,
	clearable,
	valueProp = 'id',
	textProp = 'name',
	type = 'text',
	onAdd,
	patchValue,
}: Props) => {
	const [open, setOpen] = useState<boolean>(false);
	console.log(open);
	const ref = useRef<HTMLDivElement>(null);
	useOnClickOutside(ref, () => setOpen(false));
	const onChangeSelection = (val: any) => {
		const hasAdd = val[valueProp] === '$add';
		if (hasAdd && onAdd) {
			onAdd();
		}
	};

	return (
		<div className={className}>
			<Listbox value="" onChange={(val) => onChangeSelection(val)}>
				{({ open }) => (
					<>
						<Listbox.Label className="block text-sm font-medium text-gray-700">
							{label}
						</Listbox.Label>
						<div className="relative mt-1">
							<Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm sm:text-sm">
								<span className="block truncate">Emails</span>
								<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
									<ChevronDownIcon
										className="h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
								</span>
							</Listbox.Button>

							<Transition
								show={open}
								as={Fragment}
								leave="transition ease-in duration-100"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
									{options?.map((opt) => (
										<Listbox.Option
											key={opt[valueProp]}
											className="cursor-default select-none relative py-2 pl-3 pr-9 z-60"
											value={opt}
											onClick={(e: any) => {
												e.stopPropagation();
											}}
										>
											<>
												<span className="font-normal flex items-center gap-1">
													{opt[valueProp] !== '$add' && (
														<XIcon
															className="h-4 w-4 text-gray-500 z-50 cursor-pointer flex-shrink-0"
															style={{ marginTop: '2px' }}
															onClick={(e) => {
																e.stopPropagation();
																const newVal = options
																	.filter(
																		(maintainOpt) =>
																			maintainOpt[valueProp] !== opt[valueProp]
																	)
																	.reduce((acc, curr) => {
																		if (curr[valueProp] !== '$add') {
																			acc.push(curr[textProp]);
																		}
																		return acc;
																	}, []);
																patchValue({ contactFormEmails: newVal });
															}}
														/>
													)}
													<span className="truncate flex-shrink">
														{opt[textProp]}
													</span>
												</span>
											</>
										</Listbox.Option>
									))}
								</Listbox.Options>
							</Transition>
						</div>
					</>
				)}
			</Listbox>
		</div>
	);
};

export default Dropdown;
