import { Agent } from "./Agent";
import { Logger } from "./Logger";
import { isError, isWorker } from "./utils";

declare global {
  var CONSTANTS: {
    IS_PROD: boolean;
    VERSION: string;
  }
}

const sdk = {

  __agent: null,

  version: CONSTANTS.VERSION,

  install: (options: RM.InstallOptions) => {
    try {
      if (sdk.__agent) {
        console.warn("Request Metrics is already installed.");
        return;
      }

      // If we are loaded in Node, or in Server-Side Rendering like NextJS
      if (typeof self === "undefined") {
        console.warn("Request Metrics does not operate in this environment.");
        return;
      }

      if (!options || !options.token) {
        console.error("You must provide a token to install Request Metrics.")
        return;
      }

      Logger.token = options.token;

      sdk.__agent = new Agent(options);
    }
    catch (e) {
      Logger.error(e);
    }
  },

  identify: (userId: string, identifyOptions?: RM.IdentifyOptions) => {
    try {
      if (!sdk.__agent) {
        console.warn("Request Metrics isn't installed.");
        return;
      }

      if (!userId) {
        console.warn("You must provide a userId.");
        return;
      }

      return sdk.__agent.identify(userId, identifyOptions);
    }
    catch (e) {
      Logger.error(e);
    }
  },

  sendEvent: (eventName: string, metadata: RM.Metadata, time: number = null) => {
    try {
      if (!sdk.__agent) {
        console.warn("Request Metrics isn't installed.");
        return;
      }

      if (!eventName) {
        console.warn("You must provide an event name.");
        return;
      }

      return sdk.__agent.sendEvent(eventName, metadata, time);
    }
    catch (e) {
      Logger.error(e);
    }
  },

  track: (error: Error, metadata: RM.Metadata, time: number = null) => {
    try {
      if (!sdk.__agent) {
        console.warn("Request Metrics isn't installed.");
        return;
      }

      if (!isError(error)) {
        console.warn("You must provide an instance of Error");
        return;
      }

      return sdk.__agent.track(error, metadata, time);
    }
    catch (e) {
      Logger.error(e);
    }
  },

  addMetadata: (metadata: RM.Metadata) => {
    try {
      if (!sdk.__agent) {
        console.warn("Request Metrics isn't installed.");
        return;
      }

      if (!metadata) {
        return;
      }

      return sdk.__agent.addMetadata(metadata);
    }
    catch (e) {
      Logger.error(e);
    }
  }
}

export const RM = sdk;

// Try to automatically install the agent for the default use case
(function () {
  try {
    if (isWorker()) { return; }
    if (!document.querySelector) { return; }

    // Detect if we are loading from the async snippet
    if (self["RM"] && self["RM"]._options) {
      sdk.install(self["RM"]._options);

      if (self["RM"]._userId) {
        sdk.identify(self["RM"]._userId, self["RM"]._identifyOptions);
      }

      if (self["RM"]._events) {
        self["RM"]._events.forEach(eventEntry => {
          sdk.sendEvent(eventEntry.eventName, eventEntry.metadata, eventEntry.time);
        });
      }

      if (self["RM"]._errors) {
        self["RM"]._errors.forEach(errorEntry => {
          sdk.track(errorEntry.error, errorEntry.metadata, errorEntry.time);
        });
      }

      if (self["RM"]._metadata) {
        sdk.addMetadata(self["RM"]._metadata);
      }

      return;
    }

    var scriptEl = document.querySelector("[data-rm-token]");
    if (!scriptEl) { return; }

    var token = scriptEl.getAttribute("data-rm-token");
    if (!token) { return; }

    var tagString = scriptEl.getAttribute("data-rm-tags");
    var tags = (tagString || "").split(",").filter(x => x);

    Logger.token = token;

    sdk.install({
      token: token,
      ingestUrl: scriptEl.getAttribute("data-rm-ingest"),
      monitorSelfCalls: !!scriptEl.getAttribute("data-rm-monitor-self"),
      tags: tags
    });

    var userId = scriptEl.getAttribute("data-rm-userId");
    if (userId) {
      sdk.identify(userId);
    }
  }
  catch (e) {
    Logger.error(e);
  }

})();