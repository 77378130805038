import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Button, { ButtonColors } from '../../common/Button';
import ConfirmModal from '../../common/ConfirmModal';
import { MeContext } from '../../common/contexts/me.context';
import Header from '../../common/Header';
import List from '../../common/list/List';
import ListItem from '../../common/list/ListItem';
import { OpenClosedStates } from '../../common/OpenClosedStates';
import { Dealer } from './Dealer.type';
import useDealers from './hooks/useDealers';
import * as dealerService from '../../services/dealer.service';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const DealerList = () => {
	const { me } = useContext(MeContext);
	const {
		data,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		onSearch,
		refetch,
	} = useDealers();
	const [state, setState] = useState(OpenClosedStates.Closed);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [dealerToRevoke, setDealerToRevoke] = useState<Dealer>();

	const closeModal = () => {
		setState(OpenClosedStates.Closed);
	};

	const onConfirm = async () => {
		if (!dealerToRevoke || !me.account.manufacturer || !me.account.id) {
			return;
		}
		setIsSubmitting(true);
		try {
			await dealerService.revoke(
				me.account.id,
				me.account.manufacturer,
				dealerToRevoke.id
			);
			setIsSubmitting(false);
			closeModal();
			refetch();
		} catch (err) {
			console.log(err);
			setIsSubmitting(false);
		}
	};

	const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const val = e.target.value;
		onSearch(val);
	};

	const onClickDealer = (dealer: Dealer) => {
		setDealerToRevoke(dealer);
		setState(OpenClosedStates.Open);
	};

	return (
		<>
			<Header
				title="Dealers"
				className="pb-6"
				rightSide={
					<>
						<Link to="/dealer/new">
							<Button text="Add dealer" />
						</Link>
					</>
				}
			>
				<div className="w-full sm:w-1/2 pt-2">
					<label htmlFor="search" className="sr-only">
						Search
					</label>
					<div className="relative">
						<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
							<svg
								className="h-5 w-5 text-gray-400"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
							>
								<path
									fillRule="evenodd"
									d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
									clipRule="evenodd"
								/>
							</svg>
						</div>
						<input
							id="search"
							name="search"
							className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white shadow-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
							placeholder="Search"
							type="search"
							onChange={onChangeSearch}
						/>
					</div>
				</div>
			</Header>
			<List
				isFetching={isFetching}
				isFetchingNextPage={!!isFetchingNextPage}
				fetchNextPage={fetchNextPage}
				hasNextPage={hasNextPage}
			>
				{data?.pages?.map((page, i: number) => (
					<React.Fragment key={i}>
						{page?.data?.map(
							(row: Dealer & { $new?: boolean }, rowKey: number) => (
								<div key={row.id}>
									<ListItem index={rowKey} newItem={row.$new}>
										<div className="flex items-center justify-between space-x-4">
											<div className="min-w-0">
												<div className="relative group flex items-center space-x-4">
													<div className="space-y-3">
														<div className="flex items-center space-x-3">
															<span className="block">
																<h2 className="text-sm font-medium leading-4">
																	<span className="absolute inset-0" />
																	{row.name}
																</h2>
															</span>
														</div>
														<div className="text-sm leading-4 text-gray-500 group-hover:text-gray-900 font-medium truncate">
															{row.products || 0} products
														</div>
													</div>
												</div>
											</div>
											<div className="sm:hidden">
												<svg
													className="h-5 w-5 text-gray-400"
													viewBox="0 0 20 20"
													fill="currentColor"
												>
													<path
														fillRule="evenodd"
														d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
														clipRule="evenodd"
													/>
												</svg>
											</div>
											<div className="hidden sm:flex flex-col flex-shrink-0 items-end space-y-3">
												<p className="flex items-center space-x-4">
													<Button
														color={ButtonColors.red}
														onClick={() => onClickDealer(row)}
														text="Revoke access"
														tight
													/>
												</p>
												<p className="flex text-gray-500 text-sm leading-4 space-x-2">
													Created{' '}
													{dayjs
														.utc(row.createDate)
														.local()
														.format('MM/DD/YYYY')}{' '}
													at{' '}
													{dayjs.utc(row.createDate).local().format('hh:mma')}
												</p>
											</div>
										</div>
									</ListItem>
								</div>
							)
						)}
					</React.Fragment>
				))}
			</List>
			<ConfirmModal
				state={state}
				close={closeModal}
				description={`Are you sure you want to revoke access to your products for ${dealerToRevoke?.name}? This action will block the dealer from listing your products.`}
				confirm={onConfirm}
				loading={isSubmitting}
			/>
		</>
	);
};

export default DealerList;
