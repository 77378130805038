import * as ShedsAPI from '../common/helpers/ShedsAPI';
import { Account } from '../pages/accounts/Account.type';
import { Manufacturer } from '../pages/manufacturers/Manufacturer.type';

export const listManufacturers = async (
	params: {
		search?: string;
		limit?: number;
		skip?: number;
		include?: string;
	},
	publicRoute?: boolean
) => {
	if (publicRoute) {
		return ShedsAPI.get('api-noauth', `/public/manufacturers`, {
			queryStringParameters: params,
		});
	} else
		return ShedsAPI.get('api', `/manufacturers`, {
			queryStringParameters: params,
		});
};

export const requestManufacturer = async (
	accountId: number,
	manufacturerId: number
) => {
	return ShedsAPI.post('api', `/accounts/${accountId}/manufacturers/request`, {
		body: { manufacturerId },
	});
};

export const getManufacturer = ({
	manufacturerId,
}: {
	manufacturerId: number;
}): Promise<Account> => {
	return ShedsAPI.get('api', `/manufacturers/${manufacturerId}`, {});
};

export const update = ({
	manufacturer,
}: {
	manufacturer: Partial<Manufacturer>;
}): Promise<Manufacturer> => {
	const { id, ...restOfManufacturer } = manufacturer;
	return ShedsAPI.put('api', `/manufacturers/${id}`, {
		body: restOfManufacturer,
	});
};

export const create = ({
	manufacturer,
}: {
	manufacturer: Partial<Manufacturer>;
}): Promise<Manufacturer> => {
	return ShedsAPI.post('api', `/manufacturers`, {
		body: manufacturer,
	});
};

export const createRequest = ({
	manufacturer,
}: {
	manufacturer: Partial<Manufacturer>;
}): Promise<Manufacturer> => {
	return ShedsAPI.post('api-noauth', `/public/request-manufacturer`, {
		body: manufacturer,
	});
};

export const saveManufacturer = ({
	manufacturer,
}: {
	manufacturer: Partial<Manufacturer>;
}): Promise<Manufacturer> => {
	return manufacturer.id ? update({ manufacturer }) : create({ manufacturer });
};

export const acceptManufacturer = async (
	accountId: number,
	manufacturerId: string,
	pendingAccountId: number
) => {
	return ShedsAPI.post(
		'api',
		`/accounts/${accountId}/manufacturers/${manufacturerId}/accept/${pendingAccountId}`,
		{}
	);
};

export const rejectManufacturer = async (
	accountId: number,
	manufacturerId: string,
	pendingAccountId: number
) => {
	return ShedsAPI.post(
		'api',
		`/accounts/${accountId}/manufacturers/${manufacturerId}/reject/${pendingAccountId}`,
		{}
	);
};

export const approveManufacturer = async (
	accountId: number,
	manufacturerId?: string
) => {
	return ShedsAPI.post(
		'api',
		`/accounts/${accountId}/manufacturers/${manufacturerId}/approve`,
		{}
	);
};

export const deleteManufacturer = async (
	accountId: number,
	manufacturerId?: string
) => {
	return ShedsAPI.del(
		'api',
		`/accounts/${accountId}/manufacturers/${manufacturerId}`,
		{}
	);
};

export const listManufacturerColors = async (manufacturerId: string) => {
	return ShedsAPI.post('api', `/colors/${manufacturerId}`, {});
};
