import React, { useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import Header from '../../../common/Header';
import LoadingPage from '../../../common/LoadingPage';
import useManufacturer from '../hooks/useManufacturer';
import ManufacturerGeneral from './ManufacturerGeneral';

const ManufacturerContainer = () => {
	const { manufacturerId } = useParams();

	const {
		isFetching,
		data,
		save,
		accept,
		reject,
		approve,
		isApproving,
		del,
		isDeleting,
	} = useManufacturer(manufacturerId);

	useEffect(() => {
		console.log({ data });
	}, [data]);

	return isFetching ? (
		<LoadingPage />
	) : (
		<>
			<Header title={data?.id ? data.name : 'New manufacturer'} />
			<Routes>
				<Route
					path="/"
					element={
						<div className="px-6 pt-6 bg-gray-50 h-full">
							<ManufacturerGeneral
								defaultValues={data}
								save={save}
								accept={accept}
								reject={reject}
								approve={approve}
								isApproving={isApproving}
								del={del}
								isDeleting={isDeleting}
							/>
						</div>
					}
				/>
			</Routes>
		</>
	);
};

export default ManufacturerContainer;
