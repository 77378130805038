export enum UploadStatus {
	new = 'new',
	uploading = 'uploading',
	complete = 'complete',
	failed = 'failed',
}

export type Product = {
	model: string;
	id: number;
	title: string;
	price: number;
	reducedPrice?: number;
	description: string;
	sku: string;
	active: boolean;
	manufacturer: number;
	dealer: number;
	createDate: string;
	images: ProductImage[];
	accountManufacturerLogo?: string;
	manufacturerLogo?: string;
	manufacturerName?: string;
	monthlyPayment: number;
	width: number;
	length: number;
	colorMain: string;
	colorMainSpecific?: string;
	colorTrim: string;
	colorTrimSpecific?: string;
	colorRoof: string;
	colorRoofSpecific?: string;
	location: number;
	roofingType: string;
	sidingType: string;
	doors: number;
	windows: number;
	warranty: string;
	specialFeatures: string;
	specialFeatures2: string;
	specialFeatures3: string;
	specialFeatures4: string;
	type: 'preowned' | 'new' | 'builttoorder';
	path: string;
	style: string;
	roofStyle: string;
	views: number;
	freeDeliveryRange: number;
	deliveryInstructions: string;
	sold: Date | null;
	externalUrl: string;
	video: string;
	sponsoredListing?: {
		active?: boolean;
		endDate?: string;
		createDate?: string;
		duration?: number;
	};
	pinned?: boolean;
};

export type ProductImage = {
	url: string;
	link?: string;
	preview?: string;
	uploadStatus?: UploadStatus;
	extension?: string;
	filename?: string;
};
