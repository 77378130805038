import * as ShedsAPI from '../common/helpers/ShedsAPI';

export const list = async (
	accountId: number,
	params: {
		search?: string;
		limit?: number;
		skip?: number;
	}
) => {
	return ShedsAPI.get('api', `/${accountId}/contact`, {
		queryStringParameters: params,
	});
};

export const markContacted = (
	accountId?: number,
	userId?: number,
	contactId?: number
) => {
	return ShedsAPI.put(
		'api',
		`/${accountId}/mark-contacted/${userId}/${contactId}`,
		{}
	);
};
