import { UserIcon, HomeIcon } from '@heroicons/react/solid';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../common/Button';
import Header from '../../common/Header';
import { formatPhone } from '../../common/helpers/format';
import List from '../../common/list/List';
import ListItem from '../../common/list/ListItem';
import { Account, SubOptions } from './Account.type';
import useAccounts from './hooks/useAccounts';
import utc from 'dayjs/plugin/utc';
import { ChatAltIcon } from '@heroicons/react/outline';
import { useForm } from '../../common/hooks/useForm';
import { Select } from '../../common/form/Select';
dayjs.extend(utc);

const AccountList = () => {
	const {
		data,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		onSearch,
	} = useAccounts();

	const { value, onChange, patchValue } = useForm<any>({
		search: '',
		accountPlan: '',
	});

	const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const val = e.target.value;
		patchValue({ search: val });
	};

	useEffect(() => {
		onSearch(value);
	}, [onSearch, value]);

	return (
		<>
			<Header
				title="Accounts"
				className="pb-6"
				rightSide={
					<>
						<Link to="/accounts/new">
							<Button text="Add account" />
						</Link>
					</>
				}
			>
				<div className="w-full sm:w-3/4 pt-2 grid grid-cols-4 gap-4 items-end">
					<div className="col-span-2">
						<label htmlFor="search" className="sr-only">
							Search
						</label>
						<div className="relative">
							<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
								<svg
									className="h-5 w-5 text-gray-400"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fillRule="evenodd"
										d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
										clipRule="evenodd"
									/>
								</svg>
							</div>
							<input
								id="search"
								name="search"
								className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white shadow-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
								placeholder="Search"
								type="search"
								onChange={onChangeSearch}
							/>
						</div>
					</div>

					<Select
						name="accountPlan"
						label="Account plan"
						value={value.accountPlan}
						onChange={onChange('accountPlan')}
						className="col-span-2"
						options={[
							{ value: 'essentials', text: 'Essentials' },
							{ value: 'pro', text: 'Pro' },
							{ value: 'elite', text: 'Elite' },
						]}
						limit={1}
						clearable
						endOfList={false}
					/>
				</div>
			</Header>
			<List
				isFetching={isFetching}
				isFetchingNextPage={!!isFetchingNextPage}
				fetchNextPage={fetchNextPage}
				hasNextPage={hasNextPage}
			>
				{data?.pages?.map((page, i: number) => (
					<React.Fragment key={i}>
						{page?.data?.map(
							(
								row: Account & { $new?: boolean; subscription?: SubOptions },
								rowKey: number
							) => (
								<Link to={`/accounts/${row.id}`} key={row.id}>
									<ListItem index={rowKey} newItem={row.$new}>
										<div
											className="flex items-center justify-between space-x-4"
											data-cy={`account-${rowKey}`}
										>
											<div className="min-w-0">
												<div className="relative group flex items-center space-x-4">
													<div className="space-y-3">
														<div className="flex items-center space-x-3">
															<span className="block">
																<h2 className="text-sm font-medium leading-4">
																	<span className="absolute inset-0" />
																	{row.name}
																</h2>
															</span>
														</div>
														<div className="relative text-sm leading-4 text-gray-500 hover:text-gray-900 font-medium flex-col">
															<div>
																{row.email} | {formatPhone(row.phone)}
															</div>
														</div>
														<div className="relative text-sm leading-4 text-gray-500 hover:text-gray-900 font-medium flex-col">
															<div
																className="cursor-pointer"
																onClick={(e) => {
																	e.preventDefault();
																	Object.assign(document.createElement('a'), {
																		target: '_blank',
																		href: `${process.env.REACT_APP_CUSTOMER_URL}/dealers/${row.path}`,
																	}).click();
																}}
															>
																{row.path}
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="sm:hidden">
												<svg
													className="h-5 w-5 text-gray-400"
													viewBox="0 0 20 20"
													fill="currentColor"
												>
													<path
														fillRule="evenodd"
														d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
														clipRule="evenodd"
													/>
												</svg>
											</div>
											<div className="hidden sm:flex flex-col flex-shrink-0 items-end space-y-3">
												<div className="flex items-center space-x-4">
													<span className="relative text-sm leading-4 text-gray-500 hover:text-gray-900 font-medium flex flex-col items-end gap-2">
														<p>{row.type}</p>
														<p>
															Current subscription cost:{' '}
															<span className="text-gray-900 font-bold">
																${row.subscription?.cost || 0}
															</span>
														</p>
													</span>
												</div>
												<div className="text-sm leading-4 text-gray-500 group-hover:text-gray-900 font-medium truncate gap-1 flex">
													<span className="inline-flex items-center px-2.5 rounded-full text-xs font-medium bg-blue-500 text-white py-0.5">
														<UserIcon className="w-3 h-3 mr-1" />
														<div>{row.users || 0}</div>
													</span>
													<span className="inline-flex items-center px-2.5 rounded-full text-xs font-medium bg-purple-500 text-white py-0.5">
														<HomeIcon className="w-3 h-3 mr-1" />
														<div>{row.products || 0}</div>
													</span>
													<span className="inline-flex items-center px-2.5 rounded-full text-xs font-medium bg-purple-500 text-white py-0.5">
														<ChatAltIcon className="w-3 h-3 mr-1" />
														<div>{row.contactViews || 0}</div>
													</span>
												</div>
												<p className="flex text-gray-500 text-sm leading-4 space-x-2">
													Created{' '}
													{dayjs
														.utc(row.createDate)
														.local()
														.format('MM/DD/YYYY')}{' '}
													at{' '}
													{dayjs.utc(row.createDate).local().format('hh:mma')}
												</p>
											</div>
										</div>
									</ListItem>
								</Link>
							)
						)}
					</React.Fragment>
				))}
			</List>
		</>
	);
};

export default AccountList;
