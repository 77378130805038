import { ExclamationIcon, XIcon } from '@heroicons/react/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import Modal, { ModalSizes } from '../../../common/Modal';
import { OpenClosedStates } from '../../../common/OpenClosedStates';

type Props = {
	state: OpenClosedStates;
	setModalState: (val: OpenClosedStates) => void;
	locationsWithoutPhone?: any[];
};
const SMSAlertModal = ({
	state,
	setModalState,
	locationsWithoutPhone,
}: Props) => {
	return (
		<Modal state={state} size={ModalSizes.lg} noScroll>
			<div className="w-full h-full flex flex-col gap-4 justify-between items-between p-4 text-sm">
				<XIcon
					className="w-5 h-5 self-start -ml-4 -mt-4 cursor-pointer"
					onClick={() => setModalState(OpenClosedStates.Closed)}
				/>

				<div className="w-full flex flex-col gap-2 justify-center items-center bg-primary-500 p-4 rounded-md bg-opacity-50 text-sm">
					<ExclamationIcon className="w-7 h-7" />

					<p>
						It looks like you just added SMS Notifications to your subscription!
						We went ahead and turned this feature on for your current locations.
					</p>
					{locationsWithoutPhone?.length ? (
						<p>
							<span>These locations didn't have a phone number attached:</span>
							<ul className="flex flex-col gap-1 my-2 items-start justify-center w-full">
								{locationsWithoutPhone?.map((loc) => (
									<li className="ml-4 font-bold">{loc}</li>
								))}
							</ul>
							Click{' '}
							<Link
								className="font-bold text-primary-500 cursor-pointer z-100"
								to="/account-settings/locations"
							>
								here
							</Link>{' '}
							to update them with a phone number that can receive text
							notifications from your customers!
						</p>
					) : (
						<></>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default SMSAlertModal;
