import React from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '../../common/Tabs';

type Props = {
	manufacturer?: boolean;
	plan: string;
};

const AccountSettingsTabs: React.FC<Props> = ({ manufacturer, plan }) => {
	const { pathname } = useLocation();
	const tabs = [
		{
			name: 'Account settings',
			to: `/account-settings`,
			active: pathname === `/account-settings`,
		},
		{
			name: 'Locations',
			to: `/account-settings/locations`,
			active: pathname === `/account-settings/locations`,
		},
	];
	tabs.push({
		name: 'Integrations',
		to: `/account-settings/api-keys`,
		active: pathname === `/account-settings/api-keys`,
	});
	if (manufacturer) {
		tabs.push({
			name: 'Color palette',
			to: `/account-settings/colors`,
			active: pathname === `/account-settings/colors`,
		});
	}

	return <Tabs tabs={tabs} />;
};

export default AccountSettingsTabs;
