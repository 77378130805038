import React, { useContext, useEffect } from 'react';
import AdImageUploader from '../../common/AdImageUploader';
import Button, { ButtonColors } from '../../common/Button';
import { ToastContext, ToastTypes } from '../../common/contexts/toast.context';
import { useForm } from '../../common/hooks/useForm';
import { ProductImage } from '../products/Product.type';
import useDisplayAds from './hooks/useDisplayAds';

export type DisplayAdsForm = { small: ProductImage[]; large: ProductImage[] };

const DisplayAds = () => {
	const { createToast } = useContext(ToastContext);
	const { data, save } = useDisplayAds();
	const { value, onChange, registerSubmit, patchValue } =
		useForm<DisplayAdsForm>({ small: [], large: [] }, {});

	useEffect(() => {
		if (data) {
			patchValue(data);
		}
	}, [data, patchValue]);

	const onSuccess = () => {
		createToast({
			title: 'Success!',
			description: <>Successfully saved ads!</>,
			type: ToastTypes.Success,
			duration: 5000,
		});
	};

	return (
		<div className="px-6 pt-6 bg-gray-50 h-full">
			<div className="flex flex-col gap-6">
				<AdImageUploader
					value={value.small || []}
					onChange={onChange('small')}
					description="Desktop images"
				/>
				<AdImageUploader
					value={value.large || []}
					onChange={onChange('large')}
					description="Mobile images"
				/>
				<div className="flex flex-row justify-end">
					<Button
						color={ButtonColors.primary}
						text="Save"
						onClick={registerSubmit(save, { onSuccess })}
					/>
				</div>
			</div>
		</div>
	);
};

export default DisplayAds;
