import React, { useEffect } from 'react';
import { FormError } from '../types/ErrorResponse.type';

type Props = {
	className?: string;
	errors?: FormError;
	label: string;
	onChange: (val: boolean) => void;
	value: boolean;
	disabled?: boolean;
	tooltip?: JSX.Element;
};

export const Toggle: React.FC<Props> = ({
	className,
	errors,
	label,
	onChange,
	value,
	disabled,
	tooltip,
}) => {
	const toggle = () => {
		if (disabled) {
			return;
		}
		onChange(!value);
	};

	useEffect(() => {
		if (errors) {
			console.log('Should probably do something with these errors:', errors);
		}
	}, [errors]);

	return (
		<div className={`mt-6 flex items-center justify-between ${className}`}>
			<div className="flex items-center relative">
				<span
					onClick={toggle}
					role="checkbox"
					aria-checked="false"
					className={[
						'hastooltip relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring',
						value ? 'bg-primary-600' : 'bg-gray-200',
						disabled ? 'cursor-not-allowed' : '',
					].join(' ')}
				>
					<span
						aria-hidden="true"
						className={[
							'inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200',
							value ? 'translate-x-5' : 'translate-x-0',
						].join(' ')}
					/>
					{!!tooltip && (
						<div
							// role="tooltip"
							className="tooltip absolute w-72 -top-16 z-80 inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm  dark:bg-gray-700"
						>
							{tooltip}
						</div>
					)}
				</span>
				<label className="ml-2 block text-sm leading-5 text-gray-900">
					{label}
				</label>
			</div>
		</div>
	);
};
