import React from 'react';
import { useParams } from 'react-router-dom';

import Header from '../../../common/Header';
import LoadingPage from '../../../common/LoadingPage';
import useUser from '../hooks/useUser';
import UserGeneral from './UserGeneral';

type Props = {
	padding?: boolean;
	accountId?: number;
};

const UserContainer: React.FC<Props> = ({ padding = true, accountId }) => {
	const { userId } = useParams();
	const { isFetching, data, save } = useUser(userId, accountId);

	return isFetching ? (
		<LoadingPage />
	) : (
		<>
			<Header
				title={
					data?.id
						? `${data.firstName || ''} ${data.lastName || ''}`
						: 'New user'
				}
			/>
			<div
				className={['bg-gray-50 h-full pt-6 px-6', padding ? 'px-6' : ''].join(
					' '
				)}
			>
				<UserGeneral
					defaultValues={data}
					save={save}
				/>
			</div>
		</>
	);
};

export default UserContainer;
