import React from 'react';
import { FormError } from '../types/ErrorResponse.type';

type Props = {
	errors?: FormError;
};
export const FieldErrors: React.FC<Props> = ({ errors }) => {
	return (
		<>
			{errors && (
				<p className="mt-2 text-sm text-red-600" id="email-error">
					{errors.message}
				</p>
			)}
		</>
	);
};
