import React from 'react';
import { Checkbox } from '../../common/form/Checkbox';
import { Input } from '../../common/form/Input';
import { Toggle } from '../../common/form/Toggle';
import { formatCurrency } from '../../common/helpers/format';
import { SubOptions } from '../accounts/Account.type';
import { Option } from '../billing/subscription-checkout/SubscriptionCheckout';

type Props = {
	options: Option[];
	setAdditionalLocationsActive: (val: any) => void;
	patchValue: (val: any) => void;
	value: SubOptions;
	onChange: (val: any) => (val: any) => void;
	additionalLocationsActive?: boolean;
	totalPrice?: number;
	moveStep: (val: any) => void;
	loadingCheckout?: boolean;
	isDirty?: boolean;
	basicPackage?: boolean;
	setBasicPackage: () => void;
};

const SignUpPlan = ({
	options,
	setAdditionalLocationsActive,
	patchValue,
	value,
	onChange,
	additionalLocationsActive,
	totalPrice,
	moveStep,
	loadingCheckout,
	isDirty,
	basicPackage,
	setBasicPackage,
}: Props) => {
	let basicPackagePrice = 0;
	options
		.filter((opt) => opt.basic)
		.forEach((opt) => (basicPackagePrice += opt.price));
	return (
		<>
			<fieldset className="border-b border-gray-200 w-full flex flex-col">
				{/* desktop view */}
				<div className="divide-y divide-gray-200 hidden sm:block">
					{options.map((option, i) => (
						<div key={i}>
							<div className="relative flex justify-center items-center py-4">
								<div className="mr-3 flex h-5 items-center">
									<Checkbox
										name={option.val}
										onChange={(val) => {
											if (option.val === 'additionalLocations') {
												setAdditionalLocationsActive((prev: boolean) => !prev);
												if (value.additionalLocations || 0 > 0) {
													patchValue({ additionalLocations: 0 });
												} else {
													patchValue({ additionalLocations: 1 });
												}
											} else if (option.count) {
												if (value[option.val as keyof SubOptions] || 0 > 0) {
													patchValue({ [option.val as keyof SubOptions]: 0 });
												} else {
													onChange(option.val as keyof SubOptions)(
														option.count
													);
												}
											} else {
												onChange(option.val as keyof SubOptions)(val);
											}
										}}
										value={
											option.val === 'additionalLocations'
												? additionalLocationsActive
												: (value[
														option.val as keyof SubOptions
												  ] as unknown as boolean)
										}
									/>
								</div>
								<div className="min-w-0 flex-1 text-sm">
									<label htmlFor="comments" className="font-bold text-gray-700">
										<span className="flex h-full gap-2">
											<span>{option.title}</span>
											{/* <span
												className="text-xs underline text-primary-500 cursor-pointer"
												onClick={() => setLearnMore(option)}
											>
												Learn more
											</span> */}
										</span>
									</label>
									<p id="comments-description" className="text-gray-500">
										{option.descr}
									</p>
									{option.val === 'additionalLocations' &&
										additionalLocationsActive && (
											<div className="w-1/5">
												<Input
													name={option.val}
													label={''}
													value={value.additionalLocations}
													className="max-w-1/5"
													clickIcon
													type="number"
													onChange={onChange('additionalLocations')}
													min="1"
													step="1"
													iconRight
												/>
											</div>
										)}
								</div>
								<div className="self-center flex flex-col text-right">
									<span className="font-bold">
										${option.count ? option.price * option.count : option.price}
									</span>
									{!!option.priceSubtitle && (
										<span className="text-xs italic text-gray-400">
											{option.priceSubtitle}
										</span>
									)}
								</div>
							</div>
							{i === 4 && (
								<>
									<div className="rounded-md h-48 shadow-sm border border-gray-200 flex">
										<div className="h-48 bg-black w-1/2 rounded-md flex flex-col items-center justify-center gap-2">
											<span className="text-sm md:text-lg text-primary-500 font-bold">
												Not sure where to start?
											</span>
											<span className="text-xs text-center md:text-sm lg:text-lg text-white">
												Auto select our most popular features!
											</span>
											<div className="flex flex-col justify-center items-center">
												<span className="text-white font-bold flex items-start gap-2">
													<span>$</span>
													<span className="text-3xl md:text-5xl">
														{basicPackagePrice}
													</span>
												</span>
												<span className="text-white text-xs font-bold">
													PER MONTH
												</span>
											</div>
										</div>
										<div className="h-48 w-1/2 flex flex-col justify-start items-center p-4">
											<span className="self-end">
												<Checkbox
													onChange={() => setBasicPackage()}
													value={basicPackage}
												/>
											</span>
											<div className="flex flex-col items-center">
												<span className="text-primary-500">Included:</span>
												{options
													.filter((opt) => opt.basic)
													?.map((item, j) => (
														<span key={j} className="text-gray-400 text-center">
															{item.title}
														</span>
													))}
											</div>
										</div>
									</div>
								</>
							)}
						</div>
					))}
				</div>

				{/* mobile view */}
				<div className="sm:hidden w-full">
					{options.map((opt, i) => (
						<div key={i}>
							<div className="w-full px-4 mt-4 self-center border-t-gray-900 border-t">
								<div className="flex gap-2 items-center pt-4">
									<Toggle
										label=""
										onChange={(val) => {
											if (opt.val === 'additionalLocations') {
												setAdditionalLocationsActive((prev: boolean) => !prev);
												if ((value.additionalLocations || 0) > 0) {
													patchValue({ additionalLocations: 0 });
												} else {
													patchValue({ additionalLocations: 1 });
												}
											} else if (opt.count) {
												if (value[opt.val as keyof SubOptions] || 0 > 0) {
													patchValue({ [opt.val as keyof SubOptions]: 0 });
												} else {
													onChange(opt.val as keyof SubOptions)(opt.count);
												}
											} else {
												onChange(opt.val as keyof SubOptions)(val);
											}
										}}
										value={
											opt.val === 'additionalLocations'
												? ((value.additionalLocations || 0 > 0) as boolean)
												: (value[
														opt.val as keyof SubOptions
												  ] as unknown as boolean)
										}
									/>
									<div>
										<div className="flex flex-col gap-2">
											<p className="font-bold">{opt.title}</p>
											<p>
												{opt.subtitle}{' '}
												<span className="font-bold">{i < 2 ? 'Free' : ''}</span>
											</p>
											<span>{formatCurrency(opt.price)}</span>
											<p className="text-xs text-gray-400">{opt.descr}</p>
										</div>
										{opt.val === 'additionalLocations' && (
											<Input
												type="number"
												step="1"
												name=""
												label=""
												value={value.additionalLocations}
												onChange={onChange('additionalLocations')}
												min="1"
												className="w-1/2"
											/>
										)}
									</div>
								</div>
							</div>
							{i === 4 && (
								<div className="p-2 w-full border-t mt-1">
									<div className="w-full flex flex-col justify-center  items-center">
										<span className="text-sm md:text-lg text-primary-500 font-bold">
											Not sure where to start?
										</span>
										<span className="text-xs text-center md:text-sm lg:text-lg text-gray-900">
											Auto select our most popular features!
										</span>
									</div>
									<div className="h-36 w-full flex justify-center gap-4 items-center p-4">
										<span className="">
											<Checkbox
												onChange={() => setBasicPackage()}
												value={basicPackage}
											/>
										</span>
										<div className="flex flex-col items-center">
											<span className="text-primary-500">Included:</span>
											{options
												.filter((opt) => opt.basic)
												?.map((item, j) => (
													<span key={j} className="text-gray-400">{item.title}</span>
												))}
										</div>
									</div>
								</div>
							)}
						</div>
					))}
					<div className="flex gap-1 w-5/6 p-4 text-gray-500 text-sm">
						<p className="text-primary-500">+</p>
						<p>
							Also included in free plan is free brand association, listing
							cloning, social sharing, outbound links, and deal direct customer
							header.
						</p>
					</div>
				</div>
				{/* end mobile view */}

				<div className="bg-primary-400 rounded-md shadow-sm h-12 mt-8 text-black font-bold flex justify-between items-center p-4">
					<p>Total price:</p>
					<p>${totalPrice}.00</p>
				</div>

				<div
					data-cy="nextBtn"
					className={[
						'w-1/3 h-8 bg-black self-center mt-4 rounded-md text-white flex justify-center items-center text-sm font-bold cursor-pointer',
						loadingCheckout ? 'bg-gray-500 cursor-not-allowed' : '',
					].join(' ')}
					onClick={() => {
						moveStep(2);
					}}
				>
					Next
				</div>
			</fieldset>
		</>
	);
};

export default SignUpPlan;
