import React, { useState } from "react";
import Button, { ButtonColors } from "../../../common/Button";
import ConfirmModal from "../../../common/ConfirmModal";
import { OpenClosedStates } from "../../../common/OpenClosedStates";
import { User } from "../User.type";

type Props = {
  active: boolean;
  confirm: () => Promise<User | undefined>;
};

const UserToggleActive: React.FC<Props> = ({ active, confirm }) => {
  const [state, setState] = useState(OpenClosedStates.Closed);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openModal = () => {
    setState(OpenClosedStates.Open);
  };

  const closeModal = () => {
    setState(OpenClosedStates.Closed);
  };

  const onConfirm = async () => {
    setIsSubmitting(true);
    try {
      await confirm();
      setIsSubmitting(false);
      closeModal();
    } catch (err) {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Button
        text={active ? `Disable user` : `Enable user`}
        color={ButtonColors.plainWithBorder}
        onClick={openModal}
        icon={
          <path
            fillRule="evenodd"
            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
            clipRule="evenodd"
          />
        }
      />
      <ConfirmModal
        state={state}
        close={closeModal}
        description={`Are you sure you want to ${
          active ? "disable" : "enable"
        } this user?`}
        confirm={onConfirm}
        loading={isSubmitting}
      />
    </>
  );
};

export default UserToggleActive;
