import { Account, SubOptions } from '../pages/accounts/Account.type';
import * as ShedsAPI from '../common/helpers/ShedsAPI';
import { CompleteSetupForm } from '../common/CompleteSetup';

export const list = async (params: {
	search?: any;
	limit?: number;
	skip?: number;
}) => {
	return ShedsAPI.get('api', `/accounts`, {
		queryStringParameters: params,
	});
};

export const get = ({ accountId }: { accountId: number }): Promise<Account> => {
	return ShedsAPI.get('api', `/accounts/${accountId}`, {});
};

export const update = ({
	account,
}: {
	account: Partial<any>;
}): Promise<any> => {
	const { id, ...restOfAccount } = account;
	return ShedsAPI.put('api', `/accounts/${id}`, {
		body: restOfAccount,
	});
};

export const create = ({
	account,
}: {
	account: Partial<Account>;
}): Promise<Account> => {
	return ShedsAPI.post('api', `/accounts`, {
		body: account,
	});
};

export const save = ({
	account,
}: {
	account: Partial<Account>;
}): Promise<Account> => {
	if (account.id || account.id === 0) {
		return update({ account });
	} else {
		return create({ account });
	}
};

export const deleteAccount = (accountId: any): Promise<Account> => {
	return ShedsAPI.del('api', `/${accountId}/delete`, {});
};

export const updateSubscription = async ({
	accountId,
	subOptions,
	currSubscriber = false,
}: {
	accountId?: number;
	subOptions: Partial<SubOptions>;
	currSubscriber?: boolean;
}) => {
	if (currSubscriber) {
		const res = await ShedsAPI.post('api', `/updateSubscription/${accountId}`, {
			body: subOptions,
		});
		return res;
	} else {
		return ShedsAPI.post('api', `/subscribe/${accountId}`, {
			body: subOptions,
		});
	}
};

export const createSubscriptionAndSignUp = async ({
	email,
	firstName,
	lastName,
	password,
	subOptions,
	account,
}: {
	email?: string;
	password?: string;
	firstName?: string;
	lastName?: string;
	subOptions: Partial<SubOptions>;
	account: Partial<CompleteSetupForm>;
}) => {
	if (!email) {
		console.error('No username');
	}
	return ShedsAPI.post('api-noauth', `/subscribeAndSignUp/${email}`, {
		body: { subOptions, password, firstName, lastName, account },
	});
};

export const emailCheckExists = async (email?: string) => {
	return ShedsAPI.get('api-noauth', `/emailCheckExists/${email}`, {});
};
