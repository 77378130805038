import React, { useContext } from 'react';
import Button, { ButtonColors } from '../../common/Button';
import { ToastContext, ToastTypes } from '../../common/contexts/toast.context';
import { Input } from '../../common/form/Input';
import { useForm } from '../../common/hooks/useForm';
import Modal from '../../common/Modal';
import { OpenClosedStates } from '../../common/OpenClosedStates';
import { PasswordValidationMeter } from '../../common/PasswordValidationMeter';
import * as userService from '../../services/user.service';

type PasswordForm = {
	newPassword: string;
	newPasswordConfirm: string;
};

const validation = {
	newPassword: {
		required: {
			message: 'New password is required.',
		},
		minLength: {
			value: 8,
			message: 'Password must be at least 8 characters.',
		},
	},
	newPasswordConfirm: {
		required: {
			message: 'Please confirm your new password.',
		},
		minLength: {
			value: 8,
			message: 'Password must be at least 8 characters.',
		},
		function: {
			value: (values: Partial<PasswordForm>) => {
				const { newPassword, newPasswordConfirm } = values;
				if (!newPassword || !newPasswordConfirm) {
					return false;
				}
				if (newPassword !== newPasswordConfirm) {
					return 'Passwords must match.';
				}
				return false;
			},
		},
	},
};

type Props = {
	state: OpenClosedStates;
	setState: React.Dispatch<React.SetStateAction<OpenClosedStates>>;
};

const ChangePasswordModal: React.FC<Props> = ({ state, setState }) => {
	const { createToast } = useContext(ToastContext);
	const { value, registerSubmit, isDirty, errors, onChange, isSubmitting } =
		useForm<PasswordForm, 'SUCCESS'>(
			{ newPassword: '', newPasswordConfirm: '' },
			validation
		);

	const onSuccess = () => {
		setState(OpenClosedStates.Closed);
		createToast({
			title: 'Success!',
			description: <>Successfully changed password!</>,
			type: ToastTypes.Success,
			duration: 5000,
		});
	};

	const onFail = () => {
		createToast({
			title: 'Error!',
			description: <>Failed to change password!</>,
			type: ToastTypes.Fail,
			duration: 5000,
		});
	};

	const onSubmit = async (val: Partial<PasswordForm>) => {
		if (!val.newPassword) {
			return;
		}
		return userService.changePassword({
			newPassword: val.newPassword,
		});
	};

	return (
		<Modal state={state} minHeight={480}>
			<form>
				<div>
					<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 dark:bg-darkGray-500">
						<svg
							className="h-6 w-6 text-gray-600 dark:text-gray-400"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
							/>
						</svg>
					</div>
					<div className="mt-3 text-center sm:mt-5">
						<h3
							className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100"
							id="modal-headline"
						>
							Change password
						</h3>
						<div className="mt-2">
							<p className="text-sm leading-5 text-gray-500 dark:text-gray-400">
								Enter your new password.
							</p>
						</div>
						<div className="text-left pt-6">
							<Input
								label="New password *"
								value={value.newPassword}
								onChange={onChange('newPassword')}
								name="newPassword"
								className="w-full"
								errors={errors.fieldErrors?.newPassword}
								type="password"
							/>
							<Input
								label="Confirm new password *"
								value={value.newPasswordConfirm}
								onChange={onChange('newPasswordConfirm')}
								name="newPasswordConfirm"
								className="w-full"
								errors={errors.fieldErrors?.newPasswordConfirm}
								type="password"
							/>
							{value.newPassword && (
								<PasswordValidationMeter
									password={value.newPassword}
									errors={errors.fieldErrors?.newPassword}
								/>
							)}
						</div>
					</div>
				</div>
				<div className="mt-6 grid grid-cols-2 gap-3 grid-flow-row-dense">
					<Button
						text="Close"
						onClick={() => setState(OpenClosedStates.Closed)}
						fullWidth
						color={ButtonColors.plain}
						className="border border-gray-300 dark:border-darkGray-500"
					/>
					<Button
						text="Set password"
						onClick={registerSubmit(onSubmit, { onSuccess, onFail })}
						fullWidth
						loading={isSubmitting}
						disabled={!isDirty || isSubmitting}
					/>
				</div>
			</form>
		</Modal>
	);
};

export default ChangePasswordModal;
