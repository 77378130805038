import { useContext, useEffect, useState } from 'react';
import Button, { ButtonColors } from '../../common/Button';
import { MeContext } from '../../common/contexts/me.context';
import Header from '../../common/Header';
import AccountSettingsTabs from './AccountSettingsTabs';
import * as ShedsAPI from '../../common/helpers/ShedsAPI';
import { ToastContext, ToastTypes } from '../../common/contexts/toast.context';
import dayjs from 'dayjs';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ClipboardCopyIcon } from '@heroicons/react/outline';
import Modal from '../../common/Modal';
import { OpenClosedStates } from '../../common/OpenClosedStates';
import { XIcon } from '@heroicons/react/solid';
import { Input } from '../../common/form/Input';
import { Select } from '../../common/form/Select';
import useAccountSettings from './useAccountSettings';
import { useForm } from '../../common/hooks/useForm';
import { Account } from '../accounts/Account.type';
import IntegrationsShedApp from './IntegrationsShedApp';

const validation = {
	customerShedSuiteId: {
		required: { message: 'Customer Id is required.' },
		pattern: {
			value: /^\d+$/,
			message: 'Provide a valid Customer Id',
		},
	},
	apiKey: {
		required: { message: 'Api Key is required.' },
	},
};

type APIKey = {
	id: number;
	accountId: number;
	apiKey: string;
	secretKey?: string;
	secretKeyTeaser: string;
	descr: string;
	createdAt: string;
	location?: number;
	manufacturer?: number;
};

enum States {
	waiting = 'waiting',
	loading = 'loading',
	generating = 'generating',
	updating = 'updating',
	revoking = 'revoking',
}

type Params = {
	userData?: any;
	isFetchingUserData?: boolean;
	save?: (val: Partial<Account>) => Promise<Account | undefined>;
};

const AccountSettingsAPIKeys = ({
	userData,
	isFetchingUserData,
	save,
}: Params) => {
	const { me, updateMe } = useContext(MeContext);
	const { data } = useAccountSettings();
	const { createToast } = useContext(ToastContext);
	const [selectedAPIKey, setSelectedAPIKey] = useState<APIKey | null>(null);
	const [apiKeys, setApiKeys] = useState<APIKey[]>([]);
	const [currentState, setCurrentState] = useState(States.waiting);
	const hasApiAccess =
		me?.account?.type === 'SuperAdmin'
			? userData?.subscription?.options?.apiAccess
			: me?.account?.subscription?.options?.apiAccess;

	const { value, onChange, registerSubmit, errors, isSubmitting, patchValue } =
		useForm<Account>(
			{
				customerShedSuiteId: hasApiAccess
					? userData?.customerShedSuiteId
					: undefined,
				apiKey: hasApiAccess ? userData?.apiKey : undefined,
			},
			validation
		);

	const isAccountSettingsPage =
		window.location.href.includes('account-settings');

	const apiKeyPath =
	me?.account?.type ==='SuperAdmin' ? userData?.id : me?.account?.id

	useEffect(() => {
		if (hasApiAccess) {
			patchValue({
				customerShedSuiteId: userData?.customerShedSuiteId,
				apiKey: userData?.apiKey,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData]);

	useEffect(() => {
		let isDestroyed = false;
		const doIt = async () => {
			try {
				setCurrentState(States.loading);
				const res = await ShedsAPI.get(
					'api',
					`/${apiKeyPath}/api-key`,
					{}
				);
				if (!isDestroyed) {
					setApiKeys(res);
					setCurrentState(States.waiting);
				}
			} catch (err) {
				console.log({ err });
				setCurrentState(States.waiting);
			}
		};
		doIt();
		return () => {
			isDestroyed = true;
		};
	}, [me?.account?.id]);

	const generate = async () => {
		try {
			setCurrentState(States.generating);
			const res = await ShedsAPI.post('api', `/${apiKeyPath}/api-key`, {});
			setApiKeys((prev) => prev.concat(res));
			createToast({
				title: 'Success!',
				description: <>Successfully added api key!</>,
				type: ToastTypes.Success,
				duration: 5000,
			});
			setCurrentState(States.waiting);
		} catch (e: any) {
			let errorMsg = e?.response?.data?.message;
			createToast({
				title: 'Error!',
				description: errorMsg ? errorMsg : 'Failed to add api key!',
				type: ToastTypes.Fail,
				duration: 5000,
			});
			setCurrentState(States.waiting);
		}
	};
	const onSubmit = async () => {
		if (userData?.id === undefined || userData?.id === null) {
			return console.error('no Account Id present');
		}
		const acc = {
			...userData,
			apiKeyChange: true,
			apiKey: value?.apiKey,
			customerShedSuiteId: value?.customerShedSuiteId,
		};
		if (save) {
			await save(acc);
		}
		return;
	};

	const onSuccess = () => {
		updateMe('account', {
			...me.account,
			apiKey: value?.apiKey,
			customerShedSuiteId: value?.customerShedSuiteId,
		});
		createToast({
			title: 'Success!',
			description: <>Successfully added api key!</>,
			type: ToastTypes.Success,
			duration: 5000,
		});
	};

	const onFail = (e: any) => {
		let errorMsg = e?.response?.data?.message;
		createToast({
			title: 'Error!',
			description: errorMsg ? errorMsg : 'Failed to add api key!',
			type: ToastTypes.Fail,
			duration: 5000,
		});
	};

	const editDescrConfirm = async (apiKey: APIKey | null) => {
		if (!apiKey) {
			return;
		}
		setCurrentState(States.updating);
		try {
			await ShedsAPI.put('api', `/${apiKeyPath}/api-key/${apiKey.id}`, {
				body: {
					descr: apiKey.descr,
					location: apiKey.location,
					manufacturer: apiKey.manufacturer,
				},
			});
			setApiKeys((prev) =>
				prev.map((key) => {
					if (key.id === apiKey.id) {
						return {
							...key,
							descr: apiKey.descr || '',
							location: apiKey.location,
							manufacturer: apiKey.manufacturer,
						};
					}
					return key;
				})
			);
			setCurrentState(States.waiting);
			setSelectedAPIKey(null);
			createToast({
				title: 'Success!',
				description: <>Successfully updated api key!</>,
				type: ToastTypes.Success,
				duration: 5000,
			});
		} catch (err) {
			createToast({
				title: 'Error!',
				description: <>Failed to edit api key!</>,
				type: ToastTypes.Fail,
				duration: 5000,
			});
			setCurrentState(States.waiting);
		}
	};

	const revoke = async (apiKey: APIKey) => {
		try {
			setCurrentState(States.revoking);
			await ShedsAPI.del('api', `/${apiKeyPath}/api-key/${apiKey.id}`, {});
			setApiKeys((prev) => prev.filter((key) => key.apiKey !== apiKey.apiKey));
			createToast({
				title: 'Success!',
				description: <>Successfully revoked api key!</>,
				type: ToastTypes.Success,
				duration: 5000,
			});
			setCurrentState(States.waiting);
		} catch (err) {
			createToast({
				title: 'Error!',
				description: <>Failed to revoke api key!</>,
				type: ToastTypes.Fail,
				duration: 5000,
			});
			setCurrentState(States.waiting);
		}
	};

	const handleRevokeShedSuiteAPIKey = async () => {
		if (!userData?.id) {
			return console.error('no Account Id present');
		}
		const acc = {
			...userData,
			apiKeyChange: true,
			apiKey: null,
			customerShedSuiteId: null,
		};
		if (save) {
			await save(acc)
				.then(() => {
					createToast({
						title: 'Success!',
						description: <>Successfully revoked api key!</>,
						type: ToastTypes.Success,
						duration: 5000,
					});
				})
				.catch(() => {
					createToast({
						title: 'Error!',
						description: <>Failed to revoke api key!</>,
						type: ToastTypes.Fail,
						duration: 5000,
					});
				});
		}
		return;
	};

	return (
		<>
			<div>
				{isAccountSettingsPage && (
					<Header title="API Keys">
						<AccountSettingsTabs
							manufacturer={me.account.type === 'Manufacturer'}
							plan={me?.account?.subscription?.plan}
						/>
					</Header>
				)}
				<div className="px-6 pt-6 bg-gray-50">
					<h3 className="text-lg leading-6 font-medium text-gray-900">
						Internal
					</h3>
				</div>
				<div className="px-6 pt-6 bg-gray-50 h-full">
					<div className="bg-white overflow-hidden sm:rounded-lg sm:shadow">
						<div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
							<div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
								<div className="ml-4 mt-2">
									<h3 className="text-lg leading-6 font-medium text-gray-900">
										API Keys
									</h3>
									<p className="mt-1 text-sm text-gray-500">
										Follow the instructions from your management system to
										connect ShedsForSale to your account for automatic posting.
									</p>
								</div>
								<div className="ml-4 mt-2 flex-shrink-0">
									<Button
										type="button"
										onClick={generate}
										color={ButtonColors.primary}
										disabled={isFetchingUserData || !hasApiAccess}
										loading={currentState === States.generating}
										text="Generate new key"
									/>
								</div>
							</div>
						</div>

						<ul className="divide-y divide-gray-200">
							{apiKeys.map((apiKey, i) => (
								<li key={i}>
									<span className="block hover:bg-gray-50">
										<div className="px-4 py-4 sm:px-6">
											<div className="flex items-center justify-between">
												<div
													className="text-sm font-medium text-primary-600 truncate cursor-pointer"
													onClick={() => setSelectedAPIKey(apiKey)}
												>
													{apiKey.descr || 'No description (click to edit)'}
												</div>
												<div className="ml-2 flex-shrink-0 flex">
													<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
														Created:{' '}
														{dayjs(apiKey.createdAt).format('MMM DD, YYYY')}
													</span>
												</div>
											</div>
											<div className="mt-2 flex justify-between">
												<div className="flex flex-col">
													<div className="flex items-center text-sm text-gray-500">
														<span className="flex flex-row gap-2">
															Public key: {apiKey.apiKey}
															<CopyToClipboard
																text={apiKey.apiKey}
																onCopy={() => {
																	createToast({
																		title: 'Success!',
																		description: <>Key copied to clipboard!</>,
																		type: ToastTypes.Success,
																		duration: 5000,
																	});
																}}
															>
																<ClipboardCopyIcon className="h-5 w-5 cursor-pointer" />
															</CopyToClipboard>
														</span>
													</div>
													<div className="flex items-center text-sm text-gray-500">
														Secret key:{' '}
														{apiKey.secretKey ? (
															<span className="flex flex-row gap-2">
																{apiKey.secretKey}
																<CopyToClipboard
																	text={apiKey.secretKey}
																	onCopy={() => {
																		createToast({
																			title: 'Success!',
																			description: (
																				<>Secret key copied to clipboard!</>
																			),
																			type: ToastTypes.Success,
																			duration: 5000,
																		});
																	}}
																>
																	<ClipboardCopyIcon className="h-5 w-5 cursor-pointer" />
																</CopyToClipboard>
															</span>
														) : (
															<>{apiKey.secretKeyTeaser}</>
														)}
													</div>
												</div>
												<div className="ml-2 flex-shrink-0 flex">
													<button
														type="button"
														className="self-end h-7 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
														onClick={revoke.bind(null, apiKey)}
														disabled={currentState === States.revoking}
													>
														Revoke
													</button>
												</div>
											</div>
										</div>
									</span>
								</li>
							))}
						</ul>
					</div>
				</div>

				<>
					<div className="px-6 pt-6 bg-gray-50">
						<h3 className="text-lg leading-6 font-medium text-gray-900">
							External
						</h3>
					</div>

					<IntegrationsShedApp
						userData={userData}
						hasApiAccess={hasApiAccess}
						isFetchingUserData={isFetchingUserData}
						save={save}
					/>

					<div className="px-6 pt-6 bg-gray-50 h-full">
						<div className="bg-white overflow-hidden sm:rounded-lg sm:shadow">
							<div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
								<div className="-ml-4 -mt-2 flex-wrap sm:flex-nowrap">
									<div className="ml-4 mt-2">
										<h3 className="text-lg leading-6 font-medium text-gray-900">
											Shed Suite
										</h3>

										<form
											onSubmit={registerSubmit(onSubmit, {
												onSuccess,
												onFail,
											})}
										>
											<div className="grid grid-cols-1 sm:grid-cols-6 gap-4">
												<div className="flex flex-col col-span-2 sm:col-span-1">
													<Input
														name="customerId"
														value={value.customerShedSuiteId}
														onChange={onChange('customerShedSuiteId')}
														label="Customer Id*"
														disabled={isFetchingUserData || !hasApiAccess}
													/>
													<div className="text-sm h-5 text-red-500">
														{errors.fieldErrors?.customerShedSuiteId?.message}
													</div>
												</div>

												<div className="flex flex-col col-span-3 sm:col-span-2.5">
													<Input
														name="apiKey"
														value={value.apiKey}
														onChange={onChange('apiKey')}
														label="Api Key*"
														disabled={isFetchingUserData || !hasApiAccess}
													/>
													<div className="text-sm h-5 text-red-500">
														{errors.fieldErrors?.apiKey?.message}
													</div>
												</div>

												<div className="flex items-center sm:col-span-1 mt-2">
													<Button
														type="submit"
														color={ButtonColors.primary}
														text="Save"
														disabled={isSubmitting || !hasApiAccess}
													/>
													<div className="ml-2" />
													<Button
														onClick={handleRevokeShedSuiteAPIKey}
														color={ButtonColors.red}
														text="Remove"
														disabled={isSubmitting || !hasApiAccess}
													/>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			</div>
			<Modal
				state={selectedAPIKey ? OpenClosedStates.Open : OpenClosedStates.Closed}
				minHeight={420}
			>
				<div>
					<XIcon
						className="h-5 w-5 absolute right-2 top-2 cursor-pointer"
						onClick={() => setSelectedAPIKey(null)}
					/>
					<div className="w-full flex flex-col items-center">
						<h3 className="font-bold text-primary-500 text-xl">
							API key details
						</h3>
						<div className="text-left pt-6 w-full">
							<Input
								className="w-full"
								name="descr"
								label="Description"
								value={selectedAPIKey?.descr}
								onChange={(val: string) =>
									setSelectedAPIKey((prev) =>
										prev
											? {
													...prev,
													descr: val,
											  }
											: null
									)
								}
							/>
							<Select
								options={
									data?.account?.locations
										?.filter((location: any) => !!location.active)
										?.map((location: any) => ({
											text: `${location.city}, ${location.state} (${location.address})`,
											value: location.id,
										})) || []
								}
								label="Location"
								value={selectedAPIKey?.location}
								onChange={(location: number) =>
									setSelectedAPIKey((prev) =>
										prev
											? {
													...prev,
													location,
											  }
											: null
									)
								}
								name="location"
								className="col-span-6 sm:col-span-3"
								limit={1}
								hint="Limit this API key to a specific location"
							/>
							<Select
								options={me.account.manufacturers}
								label="Manufacturer"
								value={selectedAPIKey?.manufacturer}
								onChange={(manufacturer: number) =>
									setSelectedAPIKey((prev) =>
										prev
											? {
													...prev,
													manufacturer,
											  }
											: null
									)
								}
								name="manufacturer"
								className="col-span-6 sm:col-span-3"
								limit={1}
								hint="Limit this API key to a specific manufacturer"
							/>
						</div>
					</div>
					<div
						className={['grid-cols-2 mt-6 grid gap-3 grid-flow-row-dense'].join(
							' '
						)}
					>
						<Button
							text="Close"
							onClick={() => setSelectedAPIKey(null)}
							fullWidth
							color={ButtonColors.plain}
							className="border border-gray-300 dark:border-darkGray-500"
						/>
						<Button
							text="Save"
							onClick={() => editDescrConfirm(selectedAPIKey)}
							fullWidth
							loading={currentState === States.updating}
						/>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default AccountSettingsAPIKeys;
