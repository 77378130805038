// for revert

import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Container from './common/Container';
import { ToastProvider } from './common/contexts/toast.context';
import Toasts from './common/toasts/Toasts';
import AccountSettings from './pages/account-settings/AccountSettings';
import Accounts from './pages/accounts/Accounts';
import SubscriptionCheckout from './pages/billing/subscription-checkout/SubscriptionCheckout';
import Blog from './pages/blog/Blog';
import Contact from './pages/contact/Contact';
import Dashboard from './pages/dashboard/Dashboard';
import Dealers from './pages/dealers/Dealers';
import DisplayAds from './pages/display-ads/DisplayAds';
import LoggedOut from './pages/logged-out/LoggedOut';
import Manufacturers from './pages/manufacturers/Manufacturers';
import Products from './pages/products/Products';
import Profile from './pages/profile/Profile';
import Users from './pages/users/Users';
import { RM } from '@request-metrics/browser-agent';
import AdRequest from './pages/display-ads/AdRequest';
import NotFound from './pages/NotFound';
import { Auth } from 'aws-amplify';
import { AuthState } from '@aws-amplify/ui-components';
import './index.css';
import Loading from './common/Loading';
import DisplayAdsList from './pages/display-ads/DisplayAdsList';

RM.install({ token: 'e3eu9ru:t7ce8fx' });

const queryClient = new QueryClient();

function App() {
	const [authState, setAuthState] = useState<string | null | undefined>(null);
	const [user, setUser] = useState<object | undefined>();

	useEffect(() => {
		if (!authState) {
			Auth.currentAuthenticatedUser()
				.then((authData) => {
					setAuthState(AuthState.SignedIn);
					setUser(authData);
				})
				.catch((e) => {
					console.log(e);
					setAuthState(undefined);
				});
		}
	}, [authState, user]);

	return (
		<QueryClientProvider client={queryClient}>
			<ToastProvider>
				<Router>
					{authState === 'signedin' ? (
						<Container>
							<Routes>
								<Route path="/" element={<Dashboard />} />
								<Route
									path="/account-settings/*"
									element={<AccountSettings />}
								/>
								<Route path="/profile/*" element={<Profile />} />
								<Route path="/users/*" element={<Users />} />
								<Route path="/accounts/*" element={<Accounts />} />
								<Route path="/dealers/*" element={<Dealers />} />
								<Route path="/products/*" element={<Products />} />
								<Route path="/manufacturers/*" element={<Manufacturers />} />
								<Route path="/contact/*" element={<Contact />} />
								<Route path="/display-ads/*" element={<DisplayAds />} />
								<Route path="/blog/*" element={<Blog />} />
								<Route path="/billing/*" element={<SubscriptionCheckout />} />
								<Route
									path="/ad-requests/*"
									element={
										user?.['attributes' as keyof object]?.['email'] ===
										process.env.REACT_APP_ADMIN_EMAIL ? (
											<AdRequest />
										) : (
											<NotFound />
										)
									}
								/>
								<Route
									path="/display-ads-list"
									element={
										user?.['attributes' as keyof object]?.['email'] ===
										process.env.REACT_APP_ADMIN_EMAIL ? (
											<DisplayAdsList />
										) : (
											<NotFound />
										)
									}
								/>
							</Routes>
						</Container>
					) : authState === null ? (
						<Loading />
					) : (
						<LoggedOut onAuth={setUser} />
					)}
				</Router>
				<Toasts />
			</ToastProvider>
		</QueryClientProvider>
	);
}

export default App;
