import { useState } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import * as manufacturerService from '../../../services/manufacturer.service';
import { Colors } from '../detail/ManufacturerGeneral';

const getManufacturerColorQuery = () => {
	return async (context: QueryFunctionContext) => {
		const manufacturerId = context.queryKey[1] as string;
		if (manufacturerId === 'add') {
			return [];
		}
		return manufacturerService.listManufacturerColors(manufacturerId);
	};
};

const useManufacturerColors = (id?: number) => {
	const [queryKey, setQueryKey] = useState<[string, string | undefined]>([
		'manufacturerColors',
		String(id),
	]);
	const { data, isFetching, refetch, remove, isFetched } = useQuery<{
		data: Colors[];
		metaData: { skip: number; totalRecords: number };
	}>(queryKey, getManufacturerColorQuery(), {
		refetchOnWindowFocus: false,
	});

	const onChangeManufacturer = (id: string) => {
		setQueryKey(['manufacturerColors', id]);
	};

	return {
		isFetching,
		data,
		onChangeManufacturer,
		refetch,
		remove,
		isFetched,
	};
};

export default useManufacturerColors;
