import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { ErrorResponse } from '../../../common/types/ErrorResponse.type';
import { getDisplayAds, saveDisplayAds } from '../../../services/ad.service';
import { DisplayAdsForm } from '../DisplayAds';

const getDisplayAdsQuery = () => {
	return async (context: QueryFunctionContext) => {
		return getDisplayAds();
	};
};

const useDisplayAds = () => {
	const queryClient = useQueryClient();
	const { data, isFetching, refetch, remove, isFetched } = useQuery<
		DisplayAdsForm,
		ErrorResponse
	>(['displayAds'], getDisplayAdsQuery(), {
		refetchOnWindowFocus: false,
		retry: false,
	});

	const save = async (val: Partial<DisplayAdsForm>) => {
		const res = await saveDisplayAds(val);
		queryClient.setQueryData(['displayAds'], val);
		return res;
	};

	return {
		isFetching,
		data,
		save,
		refetch,
		remove,
		isFetched,
	};
};

export default useDisplayAds;
