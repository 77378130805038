import React, { ChangeEvent } from 'react';
import { FormError } from '../types/ErrorResponse.type';
import { FieldErrors } from './FieldErrors';

type Props = {
	className?: string;
	errors?: FormError;
	label?: string;
	name?: string;
	onChange?: (val: boolean) => void;
	value?: boolean;
	disabled?: boolean;
	hint?: string | JSX.Element;
};

export const Checkbox: React.FC<Props> = ({
	className,
	errors,
	label,
	name,
	onChange,
	value,
	disabled,
	hint,
}) => {
	const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
		if (onChange && !disabled) {
			onChange(e.target.checked);
		}
	};

	return (
		<div className="hastooltip">
			<div className={`flex items-center ${className}`}>
				<input
					id={name}
					type="checkbox"
					className={[
						'h-4 w-4 border-gray-300 rounded',
						disabled
							? 'text-gray-500 opacity-50'
							: 'text-primary-600 focus:ring-primary-500',
					].join(' ')}
					checked={value}
					onChange={onChangeValue}
					disabled={disabled}
				/>
				<label
					htmlFor={name}
					className={[
						'ml-2 block text-sm leading-5',
						errors ? 'text-red-600' : 'text-gray-900',
					].join(' ')}
				>
					{label}
				</label>
			</div>
			<FieldErrors errors={errors} />
			{!!hint && (
				<p className="mt-2 text-smtext-gray-500 tooltip" id={`${name}-hint`}>
					{hint}
				</p>
			)}
		</div>
	);
};
