import { Dialog, Transition } from '@headlessui/react';
import { BellIcon, HomeIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import UserCircleIcon from '@heroicons/react/solid/UserCircleIcon';
import { Auth } from 'aws-amplify';
import React, { Fragment, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { MeContext } from '../contexts/me.context';
import Notifications from '../notifications/Notifications';
import { OpenClosedStates } from '../OpenClosedStates';
import { menuItems } from './menuItems';

type Props = {
	sideNavState: OpenClosedStates;
	openMenu: () => void;
	closeMenu: () => void;
};

const TopNav: React.FC<Props> = ({ sideNavState, openMenu, closeMenu }) => {
	const { me } = useContext(MeContext);
	const [notificationsState, setNotificationsState] = useState(
		OpenClosedStates.Closed
	);

	const logout = () => {
		closeMenu();
		Auth.signOut();
		window.location.href = '/';
	};

	const toggleNotifications = () => {
		setNotificationsState((prev) =>
			prev === OpenClosedStates.Closed
				? OpenClosedStates.Open
				: OpenClosedStates.Closed
		);
	};

	return (
		<header className="flex-shrink-0 relative h-16 bg-white flex items-center lg:hidden">
			<div className="absolute inset-y-0 left-0 md:static md:flex-shrink-0">
				<Link
					to="/"
					className="flex items-center justify-center h-16 w-16 bg-primary-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 md:w-20"
					onClick={closeMenu}
				>
					<HomeIcon className="h-8 w-8 text-white" />
				</Link>
			</div>
			<div className="absolute inset-y-0 right-0 pr-4 flex items-center sm:pr-6 gap-1">
				<span className="inline-block relative">
					<button
						type="button"
						className="-mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
						onClick={toggleNotifications}
					>
						<span className="sr-only">Notifications</span>
						<BellIcon className="block h-6 w-6" aria-hidden="true" />
					</button>
				</span>
				<span className="inline-block relative">
					<button
						type="button"
						className="-mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
						onClick={openMenu}
					>
						<span className="sr-only">Open main menu</span>
						<MenuIcon className="block h-6 w-6" aria-hidden="true" />
					</button>
				</span>
			</div>
			<Transition.Root
				show={sideNavState === OpenClosedStates.Open}
				as={Fragment}
			>
				<Dialog
					as="div"
					static
					className="fixed inset-0 z-40 lg:hidden"
					open={sideNavState === OpenClosedStates.Open}
					onClose={closeMenu}
				>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="hidden sm:block sm:fixed sm:inset-0 sm:bg-gray-600 sm:bg-opacity-75" />
					</Transition.Child>

					<Transition.Child
						as={Fragment}
						enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
						enterFrom="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
						enterTo="transform opacity-100 scale-100  sm:translate-x-0 sm:scale-100 sm:opacity-100"
						leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
						leaveFrom="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
						leaveTo="transform opacity-0 scale-110  sm:translate-x-full sm:scale-100 sm:opacity-100"
					>
						<nav
							className="fixed z-40 inset-0 h-full w-full bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:max-w-sm sm:w-full sm:shadow-lg overflow-y-auto"
							aria-label="Global"
						>
							<div className="h-16 flex items-center justify-between px-4 sm:px-6">
								<Link to="/" className="focus:outline-none" onClick={closeMenu}>
									<HomeIcon className="h-8 w-8 text-primary-500" />
								</Link>
								<button
									type="button"
									className="-mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
									onClick={closeMenu}
								>
									<span className="sr-only">Close main menu</span>
									<XIcon className="block h-6 w-6" aria-hidden="true" />
								</button>
							</div>
							<div className="max-w-8xl mx-auto py-3 px-2 sm:px-4">
								{menuItems.map((item) => (
									<Fragment key={item.title}>
										<Link
											to={item.path}
											className={[
												'block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100',
												(!item.restrict ||
													item.restrict?.includes(me.account?.type)) &&
												(!item.restrictRoles ||
													item.restrictRoles?.includes(me.role))
													? 'block'
													: 'hidden',
											].join(' ')}
											onClick={closeMenu}
										>
											{item.title}
										</Link>
									</Fragment>
								))}
							</div>
							<div className="border-t border-gray-200 pt-4 pb-3">
								<div className="max-w-8xl mx-auto px-4 flex items-center sm:px-6">
									<div className="flex-shrink-0">
										<UserCircleIcon className="h-10 w-10 text-gray-400" />
									</div>
									<div className="ml-3 min-w-0 flex-1">
										<div className="text-base font-medium text-gray-800 truncate">
											Signed in as
										</div>
										<div className="text-sm font-medium text-gray-500 truncate">
											{me.email}
										</div>
									</div>
								</div>
								<div className="mt-3 max-w-8xl mx-auto px-2 space-y-1 sm:px-4">
									<Link
										to="/profile"
										className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50"
										role="menuitem"
										onClick={closeMenu}
									>
										Profile
									</Link>
									{me.role !== 'user' && (
										<>
											<Link
												to="/billing"
												className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50"
												role="menuitem"
												onClick={closeMenu}
											>
												Billing
											</Link>
											<Link
												to="/account-settings"
												className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50"
												role="menuitem"
												onClick={closeMenu}
											>
												Account settings
											</Link>
										</>
									)}
									<div
										className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50"
										role="menuitem"
										onClick={logout}
									>
										Sign out
									</div>
								</div>
							</div>
						</nav>
					</Transition.Child>
				</Dialog>
			</Transition.Root>
			<Transition.Root
				show={notificationsState === OpenClosedStates.Open}
				as={Fragment}
			>
				<Dialog
					as="div"
					static
					className="fixed inset-0 z-40 lg:hidden"
					open={notificationsState === OpenClosedStates.Open}
					onClose={closeMenu}
				>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="hidden sm:block sm:fixed sm:inset-0 sm:bg-gray-600 sm:bg-opacity-75" />
					</Transition.Child>

					<Transition.Child
						as={Fragment}
						enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
						enterFrom="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
						enterTo="transform opacity-100 scale-100  sm:translate-x-0 sm:scale-100 sm:opacity-100"
						leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
						leaveFrom="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
						leaveTo="transform opacity-0 scale-110  sm:translate-x-full sm:scale-100 sm:opacity-100"
					>
						<nav
							className="fixed z-40 inset-0 h-full w-full bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:max-w-sm sm:w-full sm:shadow-lg"
							aria-label="Global"
						>
							<div className="h-16 flex items-center justify-between px-4 sm:px-6">
								<Link
									to="/"
									className="focus:outline-none"
									onClick={toggleNotifications}
								>
									<HomeIcon className="h-8 w-8 text-primary-500" />
								</Link>
								<button
									type="button"
									className="-mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
									onClick={toggleNotifications}
								>
									<span className="sr-only">Close notifications</span>
									<XIcon className="block h-6 w-6" aria-hidden="true" />
								</button>
							</div>
							<Notifications />
						</nav>
					</Transition.Child>
				</Dialog>
			</Transition.Root>
		</header>
	);
};

export default TopNav;
